export const Types = {
  GET_ADMINS: 'GET_ADMINS',
  GET_ADMINS_SUCCESS: 'GET_ADMINS_SUCCESS',
  GET_ADMINS_ERROR: 'GET_ADMINS_ERROR',
  POST_ADMIN: 'POST_ADMIN',
  POST_ADMIN_SUCCESS: 'POST_ADMIN_SUCCESS',
  POST_ADMIN_ERROR: 'POST_ADMIN_ERROR',
  GET_ADMIN_BY_ID: 'GET_ADMIN_BY_ID',
  GET_ADMIN_BY_ID_SUCCESS: 'GET_ADMIN_BY_ID_SUCCESS',
  GET_ADMIN_BY_ID_ERROR: 'GET_ADMIN_BY_ID_ERROR',
  PUT_ADMIN: 'PUT_ADMIN',
  PUT_ADMIN_SUCCESS: 'PUT_ADMIN_SUCCESS',
  PUT_ADMIN_ERROR: 'PUT_ADMIN_ERROR',
  ADMIN_CLEAN: 'ADMIN_CLEAN',
};

const INIT_STATE = {
  data: null,
  admin: null,
  loading: false,
  error: null,
  postSuccess: false,

  amount: 10,
};

export default function Enterprise(state = INIT_STATE, action) {
  switch (action.type) {
    case Types.GET_ADMINS:
      return {
        ...state,
        data: null,
        loading: true,
        error: null,
      };
    case Types.GET_ADMINS_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        amount: action.payload.total,
        loading: false,
        error: null,
      };
    case Types.GET_ADMINS_ERROR:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.payload,
      };
    case Types.POST_ADMIN:
      return {
        ...state,
        postSuccess: false,
        loading: true,
        error: null,
      };
    case Types.POST_ADMIN_SUCCESS:
      return {
        ...state,
        postSuccess: true,
        loading: false,
        error: null,
      };
    case Types.POST_ADMIN_ERROR:
      return {
        ...state,
        postSuccess: false,
        loading: false,
        error: action.payload,
      };
    case Types.GET_ADMIN_BY_ID:
      return {
        ...state,
        admin: null,
        loading: true,
        error: false,
      };
    case Types.GET_ADMIN_BY_ID_SUCCESS:
      return {
        ...state,
        admin: action.payload,
        loading: false,
        error: false,
      };
    case Types.GET_ADMIN_BY_ID_ERROR:
      return {
        ...state,
        admin: null,
        loading: false,
        error: action.payload,
      };
    case Types.PUT_ADMIN:
      return {
        ...state,
        postSuccess: false,
        loading: true,
        error: null,
      };
    case Types.PUT_ADMIN_SUCCESS:
      return {
        ...state,
        postSuccess: true,
        loading: false,
        error: null,
      };
    case Types.PUT_ADMIN_ERROR:
      return {
        ...state,
        postSuccess: false,
        loading: false,
        error: action.payload,
      };
    case Types.ADMIN_CLEAN:
      return {
        ...state,
        postSuccess: false,
        loading: false,
        error: false,
      };
    default:
      return state;
  }
}

export const Creators = {
  getAdmins: payload => ({
    type: Types.GET_ADMINS,
    payload,
  }),
  getAdminsSuccess: payload => ({
    type: Types.GET_ADMINS_SUCCESS,
    payload,
  }),
  getAdminsError: payload => ({
    type: Types.GET_ADMINS_ERROR,
    payload,
  }),
  postAdmin: payload => ({
    type: Types.POST_ADMIN,
    payload,
  }),
  postAdminSuccess: payload => ({
    type: Types.POST_ADMIN_SUCCESS,
    payload,
  }),
  postAdminError: payload => ({
    type: Types.POST_ADMIN_ERROR,
    payload,
  }),
  getAdminById: payload => ({
    type: Types.GET_ADMIN_BY_ID,
    payload,
  }),
  getAdminByIdSuccess: payload => ({
    type: Types.GET_ADMIN_BY_ID_SUCCESS,
    payload,
  }),
  getAdminByIdError: payload => ({
    type: Types.GET_ADMIN_BY_ID_ERROR,
    payload,
  }),
  putAdmin: payload => ({
    type: Types.PUT_ADMIN,
    payload,
  }),
  putAdminSuccess: payload => ({
    type: Types.PUT_ADMIN_SUCCESS,
    payload,
  }),
  putAdminError: payload => ({
    type: Types.PUT_ADMIN_ERROR,
    payload,
  }),
  adminClean: () => ({
    type: Types.ADMIN_CLEAN,
  }),
};
