import { combineReducers } from 'redux';
import auth from './Auth';
import settings from './Setting';
import enterprise from './Enterprise';
import registrant from './Registrant';
import admin from './Admin';
import participant from './Participant';
import notaFiscal from './NotaFiscal';
import ceg from './Ceg';
import rdms from './Rdm';
import rec from './Rec';
import user from './User';
import source from './Source';
import ibge from './Ibge';
import technology from './Tecnology';
import cep from './Cep';
import file from './File';
import adminDashboard from './AdminDashboard';
import clientDashboard from './ClientDashboard';
import generation from './Generation';
import recoverPassword from './RecoverPassword';
import country from './Country';
import apiIntegration from './ApiIntegration';
import tutorial from './Tutorial';
import evident from './Evident';
import config from './Config';
import retirement from './Retirement';
import beneficiary from './Beneficiary';
import redemption from './Redemption';
import transfer from './Transfer';
import notaFiscalRedemption from './NotaFiscalRedemption';
import beneficiaryConsumption from './BeneficiaryConsumption';
import participantRegistrant from './ParticipantRegistrant';
import participantEnterprise from './ParticipantEnterprise';
import redemptionAutomation from './RedemptionAutomation';
import serpro from './Serpro';

const rootReducer = combineReducers({
  auth,
  settings,
  enterprise,
  registrant,
  admin,
  participant,
  notaFiscal,
  ceg,
  rdms,
  rec,
  user,
  source,
  ibge,
  technology,
  cep,
  file,
  adminDashboard,
  clientDashboard,
  generation,
  recoverPassword,
  apiIntegration,
  tutorial,
  evident,
  config,
  country,
  retirement,
  beneficiary,
  redemption,
  transfer,
  notaFiscalRedemption,
  beneficiaryConsumption,
  participantRegistrant,
  participantEnterprise,
  redemptionAutomation,
  serpro
});

export default rootReducer;
