export const Types = {
  GET_LIST_CONFIG_REQUEST: 'GET_LIST_CONFIG_REQUEST',
  GET_LIST_CONFIG_SUCCESS: 'GET_LIST_CONFIG_SUCCESS',
  GET_LIST_CONFIG_FAIL: 'GET_LIST_CONFIG_FAIL',

  GET_ID_CONFIG_REQUEST: 'GET_ID_CONFIG_REQUEST',
  GET_ID_CONFIG_SUCCESS: 'GET_ID_CONFIG_SUCCESS',
  GET_ID_CONFIG_FAIL: 'GET_ID_CONFIG_FAIL',

  UPDATE_CONFIG_REQUEST: 'UPDATE_CONFIG_REQUEST',
  UPDATE_CONFIG_SUCCESS: 'UPDATE_CONFIG_SUCCESS',
  UPDATE_CONFIG_FAIL: 'UPDATE_CONFIG_FAIL',
  UPDATE_CONFIG_CLEAN: 'UPDATE_CONFIG_CLEAN',
};

const INIT_STATE = {
  amount: 10,

  data: null,
  loading: false,
  error: false,

  idLoading: false,
  idData: null,
  idError: false,

  updateLoading: false,
  updateSuccess: false,
  updateError: false,
};

export default function Config(state = INIT_STATE, action) {
  switch (action.type) {
    case Types.GET_LIST_CONFIG_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case Types.GET_LIST_CONFIG_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload.data || action.payload,
        amount: action.payload.total,
      };
    case Types.GET_LIST_CONFIG_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case Types.GET_ID_CONFIG_REQUEST:
      return {
        ...state,
        idLoading: true,
        idError: false,
      };
    case Types.GET_ID_CONFIG_SUCCESS:
      return {
        ...state,
        idLoading: false,
        idError: false,
        idData: action.payload,
      };
    case Types.GET_ID_CONFIG_FAIL:
      return {
        ...state,
        idLoading: false,
        idError: true,
      };

    case Types.UPDATE_CONFIG_REQUEST:
      return {
        ...state,
        updateLoading: true,
        updateError: false,
        updateSuccess: false,
      };
    case Types.UPDATE_CONFIG_SUCCESS:
      return {
        ...state,
        updateLoading: false,
        updateError: false,
        updateSuccess: true,
      };
    case Types.UPDATE_CONFIG_FAIL:
      return {
        ...state,
        updateLoading: false,
        updateError: true,
        updateSuccess: false,
      };
    case Types.UPDATE_CONFIG_CLEAN:
      return {
        ...state,
        updateLoading: false,
        updateError: false,
        updateSuccess: false,
      };

    default:
      return state;
  }
}

export const Creators = {
  // GET
  getListConfigRequest: (payload) => ({
    type: Types.GET_LIST_CONFIG_REQUEST,
    payload,
  }),
  getListConfigSuccess: (payload) => ({
    type: Types.GET_LIST_CONFIG_SUCCESS,
    payload,
  }),
  getListConfigFail: () => ({
    type: Types.GET_LIST_CONFIG_FAIL,
  }),

  // ID
  getIdConfigRequest: (payload) => ({
    type: Types.GET_ID_CONFIG_REQUEST,
    payload,
  }),
  getIdConfigSuccess: (payload) => ({
    type: Types.GET_ID_CONFIG_SUCCESS,
    payload,
  }),
  getIdConfigFail: () => ({
    type: Types.GET_ID_CONFIG_FAIL,
  }),

  // UPDATE
  updateConfigRequest: (payload) => ({
    type: Types.UPDATE_CONFIG_REQUEST,
    payload,
  }),
  updateConfigSuccess: (payload) => ({
    type: Types.UPDATE_CONFIG_SUCCESS,
    payload,
  }),
  updateConfigFail: () => ({
    type: Types.UPDATE_CONFIG_FAIL,
  }),
  updateConfigClean: () => ({
    type: Types.UPDATE_CONFIG_CLEAN,
  }),
};
