import { all, call, put, takeLatest, fork } from 'redux-saga/effects';
import {
  Creators as TutorialActions,
  Types as TutorialTypes,
} from 'store/ducks/Tutorial';
import api from '../../util/Api';
import { Creators as NotaFiscalActions } from '../ducks/NotaFiscal';

function* getList({ payload }) {
  try {
    const { status, data } = yield call(api.get, '/tutorials', {
      params: payload,
    });
    if (status === 200) {
      yield put(TutorialActions.getListTutorialSuccess(data));
    } else {
      yield put(TutorialActions.getListTutorialFail());
    }
  } catch (err) {
    yield put(TutorialActions.getListTutorialFail());
  }
}

function* deleteTutorial({ payload }) {
  try {
    const { status } = yield call(api.delete, `/tutorials/${payload}`);
    if (status === 200) {
      yield put(TutorialActions.deleteTutorialSuccess());
    } else {
      yield put(TutorialActions.deleteTutorialFail());
    }
  } catch (err) {
    yield put(TutorialActions.deleteTutorialFail());
  }
}

function* getIdTutorial({ payload }) {
  try {
    const { status, data } = yield call(api.get, `/tutorials/${payload}`);
    if (status === 200) {
      yield put(TutorialActions.getIdTutorialSuccess(data));
    } else {
      yield put(TutorialActions.getIdTutorialFail());
    }
  } catch (err) {
    yield put(TutorialActions.getIdTutorialFail());
  }
}

function* createTutorial({ payload }) {
  const { id, data } = payload;

  try {
    const { status } = yield call(api.post, '/tutorials', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (status === 200) {
      yield put(TutorialActions.createTutorialSuccess());
    } else {
      yield put(TutorialActions.createTutorialFail());
    }
  } catch (e) {
    yield put(TutorialActions.createTutorialFail());
  }
}

function* updateTutorial({ payload }) {
  const { id, data } = payload;

  try {
    const { status } = yield call(api.put, `/tutorials/${id}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (status === 200) {
      yield put(TutorialActions.updateTutorialSuccess());
    } else {
      yield put(TutorialActions.updateTutorialFail());
    }
  } catch (e) {
    yield put(TutorialActions.updateTutorialFail());
  }
}

function* getListWatcher() {
  yield takeLatest(TutorialTypes.GET_LIST_TUTORIAL_REQUEST, getList);
}

function* deleteTutorialWatcher() {
  yield takeLatest(TutorialTypes.DELETE_TUTORIAL_REQUEST, deleteTutorial);
}

function* getIdTutorialWatcher() {
  yield takeLatest(TutorialTypes.GET_ID_TUTORIAL_REQUEST, getIdTutorial);
}

function* createTutorialWatcher() {
  yield takeLatest(TutorialTypes.CREATE_TUTORIAL_REQUEST, createTutorial);
}

function* updateTutorialWatcher() {
  yield takeLatest(TutorialTypes.UPDATE_TUTORIAL_REQUEST, updateTutorial);
}

export default function* rootSaga() {
  yield all([
    fork(getListWatcher),
    fork(deleteTutorialWatcher),
    fork(getIdTutorialWatcher),
    fork(createTutorialWatcher),
    fork(updateTutorialWatcher),
  ]);
}
