export const Types = {
  GET_BENEFICIARY_CONSUMPTIONS: 'GET_BENEFICIARY_CONSUMPTIONS',
  GET_BENEFICIARY_CONSUMPTIONS_SUCCESS: 'GET_BENEFICIARY_CONSUMPTION_SUCCESS',
  GET_BENEFICIARY_CONSUMPTIONS_ERROR: 'GET_BENEFICIARY_CONSUMPTIONS_ERROR',

  POST_BENEFICIARY_CONSUMPTION: 'POST_BENEFICIARY_CONSUMPTION',
  POST_BENEFICIARY_CONSUMPTION_SUCCESS: 'POST_BENEFICIARY_CONSUMPTION_SUCCESS',
  POST_BENEFICIARY_CONSUMPTION_ERROR: 'POST_BENEFICIARY_CONSUMPTION_ERROR',

  GET_BENEFICIARY_CONSUMPTION_BY_ID: 'GET_BENEFICIARY_CONSUMPTION_BY_ID',
  GET_BENEFICIARY_CONSUMPTION_BY_ID_SUCCESS: 'GET_BENEFICIARY_CONSUMPTION_BY_ID_SUCCESS',
  GET_BENEFICIARY_CONSUMPTION_BY_ID_ERROR: 'GET_BENEFICIARY_CONSUMPTION_BY_ID_ERROR',

  PUT_BENEFICIARY_CONSUMPTION: 'PUT_BENEFICIARY_CONSUMPTION',
  PUT_BENEFICIARY_CONSUMPTION_SUCCESS: 'PUT_BENEFICIARY_CONSUMPTION_SUCCESS',
  PUT_BENEFICIARY_CONSUMPTION_ERROR: 'PUT_BENEFICIARY_CONSUMPTION_ERROR',

  DELETE_BENEFICIARY_CONSUMPTION: 'DELETE_BENEFICIARY_CONSUMPTION',
  DELETE_BENEFICIARY_CONSUMPTION_SUCCESS: 'DELETE_BENEFICIARY_CONSUMPTION_SUCCESS',
  DELETE_BENEFICIARY_CONSUMPTION_ERROR: 'DELETE_BENEFICIARY_CONSUMPTION_ERROR',

  BENEFICIARY_CONSUMPTION_CLEAN: 'BENEFICIARY_CONSUMPTION_CLEAN',
};

const INIT_STATE = {
  data: null,
  beneficiaryConsumption: null,
  loading: false,

  error: null,
  postSuccess: false,
  deleteSuccess: false,

  amount: 10,
};

export default function BeneficiaryConsumption(state = INIT_STATE, action) {
  switch (action.type) {
    case Types.GET_BENEFICIARY_CONSUMPTIONS:
      return {
        ...state,
        data: null,
        loading: true,
        error: null,
      };
    case Types.GET_BENEFICIARY_CONSUMPTIONS_SUCCESS:
      return {
        ...state,
        data: action.payload.data || action.payload,
        amount: action.payload.total,
        loading: false,
        error: null,
      };
    case Types.GET_BENEFICIARY_CONSUMPTIONS_ERROR:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.payload,
      };

    case Types.POST_BENEFICIARY_CONSUMPTION:
      return {
        ...state,
        postSuccess: false,
        loading: true,
        error: null,
      };
    case Types.POST_BENEFICIARY_CONSUMPTION_SUCCESS:
      return {
        ...state,
        postSuccess: true,
        loading: false,
        error: null,
      };
    case Types.POST_BENEFICIARY_CONSUMPTION_ERROR:
      return {
        ...state,
        postSuccess: false,
        loading: false,
        error: action.payload?.message,
      };

    case Types.GET_BENEFICIARY_CONSUMPTION_BY_ID:
      return {
        ...state,
        beneficiary: null,
        loading: true,
        error: false,
      };
    case Types.GET_BENEFICIARY_CONSUMPTION_BY_ID_SUCCESS:
      return {
        ...state,
        beneficiary: action.payload,
        loading: false,
        error: false,
      };
    case Types.GET_BENEFICIARY_CONSUMPTION_BY_ID_ERROR:
      return {
        ...state,
        beneficiary: null,
        loading: false,
        error: action.payload,
      };

    case Types.PUT_BENEFICIARY_CONSUMPTION:
      return {
        ...state,
        postSuccess: false,
        loading: true,
        error: null,
      };
    case Types.PUT_BENEFICIARY_CONSUMPTION_SUCCESS:
      return {
        ...state,
        postSuccess: true,
        loading: false,
        error: null,
      };
    case Types.PUT_BENEFICIARY_CONSUMPTION_ERROR:
      return {
        ...state,
        postSuccess: false,
        loading: false,
        error: action.payload,
      };

    case Types.DELETE_BENEFICIARY_CONSUMPTION:
      return {
        ...state,
        deleteSuccess: false,
        loading: true,
        error: null,
      };
    case Types.DELETE_BENEFICIARY_CONSUMPTION_SUCCESS:
      return {
        ...state,
        deleteSuccess: true,
        loading: false,
        error: null,
      };
    case Types.DELETE_BENEFICIARY_CONSUMPTION_ERROR:
      return {
        ...state,
        deleteSuccess: false,
        loading: false,
        error: action.payload,
      };
    case Types.BENEFICIARY_CONSUMPTION_CLEAN:
      return {
        ...state,
        beneficiary: null,
        postSuccess: false,
        deleteSuccess: false,
        loading: false,
        error: false,
      };

    default:
      return state;
  }
}

export const Creators = {
  getBeneficiaryConsumptions: payload => ({
    type: Types.GET_BENEFICIARY_CONSUMPTIONS,
    payload,
  }),
  getBeneficiaryConsumptionsSuccess: payload => ({
    type: Types.GET_BENEFICIARY_CONSUMPTIONS_SUCCESS,
    payload,
  }),
  getBeneficiaryConsumptionsError: payload => ({
    type: Types.GET_BENEFICIARY_CONSUMPTIONS_ERROR,
    payload,
  }),

  postBeneficiaryConsumption: payload => ({
    type: Types.POST_BENEFICIARY_CONSUMPTION,
    payload,
  }),
  postBeneficiaryConsumptionSuccess: payload => ({
    type: Types.POST_BENEFICIARY_CONSUMPTION_SUCCESS,
    payload,
  }),
  postBeneficiaryConsumptionError: payload => ({
    type: Types.POST_BENEFICIARY_CONSUMPTION_ERROR,
    payload,
  }),

  getBeneficiaryConsumptionById: payload => ({
    type: Types.GET_BENEFICIARY_CONSUMPTION_BY_ID,
    payload,
  }),
  getBeneficiaryConsumptionByIdSuccess: payload => ({
    type: Types.GET_BENEFICIARY_CONSUMPTION_BY_ID_SUCCESS,
    payload,
  }),
  getBeneficiaryConsumptionByIdError: payload => ({
    type: Types.GET_BENEFICIARY_CONSUMPTION_BY_ID_ERROR,
    payload,
  }),

  putBeneficiaryConsumption: payload => ({
    type: Types.PUT_BENEFICIARY_CONSUMPTION,
    payload,
  }),
  putBeneficiaryConsumptionSuccess: payload => ({
    type: Types.PUT_BENEFICIARY_CONSUMPTION_SUCCESS,
    payload,
  }),
  putBeneficiaryConsumptionError: payload => ({
    type: Types.PUT_BENEFICIARY_CONSUMPTION_ERROR,
    payload,
  }),

  deleteBeneficiaryConsumption: payload => ({
    type: Types.DELETE_BENEFICIARY_CONSUMPTION,
    payload,
  }),
  deleteBeneficiaryConsumptionSuccess: payload => ({
    type: Types.DELETE_BENEFICIARY_CONSUMPTION_SUCCESS,
    payload,
  }),
  deleteBeneficiaryConsumptionError: payload => ({
    type: Types.DELETE_BENEFICIARY_CONSUMPTION_ERROR,
    payload,
  }),

  beneficiaryConsumptionClean: () => ({
    type: Types.BENEFICIARY_CONSUMPTION_CLEAN,
  }),
};
