import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import {
  Types as BeneficiaryConsumptionTypes,
  Creators as BeneficiaryConsumptionActions,
} from '../ducks/BeneficiaryConsumption';
import api from '../../util/Api';

function* getBeneficiaryConsumptions({ payload }) {
  try {
    const { status, data } = yield call(api.get, '/beneficiary_consumptions', {
      params: payload,
    });
    if (status === 200) {
      yield put(BeneficiaryConsumptionActions.getBeneficiaryConsumptionsSuccess(data));
    }
  } catch (error) {
    yield put(BeneficiaryConsumptionActions.getBeneficiaryConsumptionsError('Erro ao listar Consumo de Beneficiário'));
  }
}

function* postBeneficiaryConsumption({ payload }) {
  try {
    const { status, data } = yield call(api.post, '/beneficiaries', payload.data);

      if (status === 200) {
        yield put(BeneficiaryConsumptionActions.postBeneficiaryConsumptionSuccess());
      }
  } catch (error) {
    yield put(BeneficiaryConsumptionActions.postBeneficiaryConsumptionError(error?.response?.data));
  }
}

function* getBeneficiaryConsumptionById({ payload }) {
  const { id, params, edit } = payload;
  try {
    const { status, data } = yield call(api.get, `/beneficiaries/${id}`, {
      params,
    });
    if (status === 200) {
      yield put(BeneficiaryConsumptionActions.getBeneficiaryConsumptionByIdSuccess(data));
    }
  } catch (error) {
    yield put(BeneficiaryConsumptionActions.getBeneficiaryConsumptionByIdError('Beneficiário não encontrado'));
  }
}

function* putBeneficiaryConsumption({ payload }) {
  const { id, data: payloadData } = payload;
  try {
    const { status } = yield call(api.put, `/beneficiaries/${id}`, payloadData);
    if (status === 200) {
        yield put(BeneficiaryConsumptionActions.putBeneficiaryConsumptionSuccess());
    }
  } catch (error) {
    yield put(BeneficiaryConsumptionActions.putBeneficiaryConsumptionError('Erro ao editar Beneficiário'));
  }
}

function* deleteBeneficiaryConsumption({ payload }) {
  try {
    const { status } = yield call(api.delete, `/beneficiary_consumptions/${payload}`);
    if (status === 200) {
      yield put(BeneficiaryConsumptionActions.deleteBeneficiaryConsumptionSuccess());
    }
  } catch (error) {
    yield put(BeneficiaryConsumptionActions.deleteBeneficiaryConsumptionError('Erro ao excluir Beneficiário'));
  }
}

function* getBeneficiariesWatcher() {
  yield takeLatest(BeneficiaryConsumptionTypes.GET_BENEFICIARY_CONSUMPTIONS, getBeneficiaryConsumptions);
}

function* postBeneficiaryConsumptionWatcher() {
  yield takeLatest(BeneficiaryConsumptionTypes.POST_BENEFICIARY_CONSUMPTION, postBeneficiaryConsumption);
}

function* getBeneficiaryConsumptionByIdWatcher() {
  yield takeLatest(BeneficiaryConsumptionTypes.GET_BENEFICIARY_CONSUMPTION_BY_ID, getBeneficiaryConsumptionById);
}

function* putBeneficiaryConsumptionWatcher() {
  yield takeLatest(BeneficiaryConsumptionTypes.PUT_BENEFICIARY_CONSUMPTION, putBeneficiaryConsumption);
}

function* deleteBeneficiaryConsumptionWatcher() {
  yield takeLatest(BeneficiaryConsumptionTypes.DELETE_BENEFICIARY_CONSUMPTION, deleteBeneficiaryConsumption);
}

export default function* rootSaga() {
  yield all([
    fork(getBeneficiariesWatcher),
    fork(postBeneficiaryConsumptionWatcher),
    fork(getBeneficiaryConsumptionByIdWatcher),
    fork(putBeneficiaryConsumptionWatcher),
    fork(deleteBeneficiaryConsumptionWatcher)
  ]);
}
