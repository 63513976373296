export const Types = {
  GET_NOTAS: 'GET_NOTAS',
  GET_NOTAS_SUCCESS: 'GET_NOTAS_SUCCESS',
  GET_NOTAS_ERROR: 'GET_NOTAS_ERROR',

  PUT_NOTAS: 'PUT_NOTAS',
  PUT_NOTAS_SUCCESS: 'PUT_NOTAS_SUCCESS',
  PUT_NOTAS_ERROR: 'PUT_NOTAS_ERROR',
  PUT_NOTAS_RESET: 'PUT_NOTAS_RESET',

  CLONE_NOTAS: 'CLONE_NOTAS',
  CLONE_NOTAS_SUCCESS: 'CLONE_NOTAS_SUCCESS',
  CLONE_NOTAS_ERROR: 'CLONE_NOTAS_ERROR',
  CLONE_NOTAS_RESET: 'CLONE_NOTAS_RESET',

  PAID_NOTAS: 'PAID_NOTAS',
  PAID_NOTAS_SUCCESS: 'PAID_NOTAS_SUCCESS',
  PAID_NOTAS_ERROR: 'PAID_NOTAS_ERROR',
  PAID_NOTAS_RESET: 'PAID_NOTAS_RESET',

  PAID_NOTAS_BY_NUMBER: 'PAID_NOTAS_BY_NUMBER',
  PAID_NOTAS_BY_NUMBER_SUCCESS: 'PAID_NOTAS_BY_NUMBER_SUCCESS',
  PAID_NOTAS_BY_NUMBER_ERROR: 'PAID_NOTAS_BY_NUMBER_ERROR',
  PAID_NOTAS_BY_NUMBER_RESET: 'PAID_NOTAS_BY_NUMBER_RESET',

  GET_NOTA_BY_ID: 'GET_NOTA_BY_ID',
  GET_NOTA_BY_ID_SUCCESS: 'GET_NOTA_BY_ID_SUCCESS',
  GET_NOTA_BY_ID_ERROR: 'GET_NOTA_BY_ID_ERROR',
  GET_NOTA_BY_ID_RESET: 'GET_NOTA_BY_ID_RESET',

  RECEIPT_UPLOAD: 'RECEIPT_UPLOAD',
  RECEIPT_UPLOAD_SUCCESS: 'RECEIPT_UPLOAD_SUCCESS',
  RECEIPT_UPLOAD_ERROR: 'RECEIPT_UPLOAD_ERROR',
  RECEIPT_UPLOAD_RESET: 'RECEIPT_UPLOAD_RESET',
};

const INIT_STATE = {
  loading: false,
  error: false,
  data: [],

  amount: 10,

  putLoading: false,
  putSuccess: false,
  putError: false,

  cloneLoading: false,
  cloneSuccess: false,
  cloneError: false,

  paidLoading: false,
  paidSuccess: false,
  paidError: false,

  paidByNumberLoading: false,
  paidByNumberSuccess: false,
  paidByNumberError: false,

  notaData: null,
  notaLoading: false,
  notaError: false,

  receiptSuccess: false,
  receiptLoading: false,
  receiptError: false,
};

export default function NotaFiscal(state = INIT_STATE, action) {
  switch (action.type) {
    case Types.GET_NOTAS:
      return {
        ...state,
        loading: true,
        error: false,
        data: [],
      };
    case Types.GET_NOTAS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload.data,
        amount: action.payload.total,
      };
    case Types.GET_NOTAS_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        data: [],
      };

    case Types.PUT_NOTAS:
      return {
        ...state,
        putLoading: true,
        putSuccess: false,
        putError: false,
      };
    case Types.PUT_NOTAS_SUCCESS:
      return {
        ...state,
        putLoading: false,
        putSuccess: true,
        putError: false,
      };
    case Types.PUT_NOTAS_ERROR:
      return {
        ...state,
        putLoading: false,
        putSuccess: false,
        putError: true,
      };
    case Types.PUT_NOTAS_RESET:
      return {
        ...state,
        putLoading: false,
        putSuccess: false,
        putError: false,
      };

    case Types.CLONE_NOTAS:
      return {
        ...state,
        cloneLoading: true,
        cloneSuccess: false,
        cloneError: false,
      };
    case Types.CLONE_NOTAS_SUCCESS:
      return {
        ...state,
        cloneLoading: false,
        cloneSuccess: true,
        cloneError: false,
      };
    case Types.CLONE_NOTAS_ERROR:
      return {
        ...state,
        cloneLoading: false,
        cloneSuccess: false,
        cloneError: true,
      };
    case Types.CLONE_NOTAS_RESET:
      return {
        ...state,
        cloneLoading: false,
        cloneSuccess: false,
        cloneError: false,
      };

    case Types.PAID_NOTAS:
      return {
        ...state,
        paidLoading: true,
        paidSuccess: false,
        paidError: false,
      };
    case Types.PAID_NOTAS_SUCCESS:
      return {
        ...state,
        paidLoading: false,
        paidSuccess: true,
        paidError: false,
      };
    case Types.PAID_NOTAS_ERROR:
      return {
        ...state,
        paidLoading: false,
        paidSuccess: false,
        paidError: true,
      };
    case Types.PAID_NOTAS_RESET:
      return {
        ...state,
        paidLoading: false,
        paidSuccess: false,
        paidError: false,
      };
    case Types.PAID_NOTAS_BY_NUMBER:
      return {
        ...state,
        paidByNumberLoading: true,
        paidByNumberSuccess: false,
        paidByNumberError: false,
      };
    case Types.PAID_NOTAS_BY_NUMBER_SUCCESS:
      return {
        ...state,
        paidByNumberLoading: false,
        paidByNumberSuccess: true,
        paidByNumberError: false,
      };
    case Types.PAID_NOTAS_BY_NUMBER_ERROR:
      return {
        ...state,
        paidByNumberLoading: false,
        paidByNumberSuccess: false,
        paidByNumberError: true,
      };
    case Types.PAID_NOTAS_BY_NUMBER_RESET:
      return {
        ...state,
        paidByNumberLoading: false,
        paidByNumberSuccess: false,
        paidByNumberError: false,
      };
    case Types.GET_NOTA_BY_ID:
      return {
        ...state,
        notaData: null,
        notaLoading: true,
        notaError: false,
      };
    case Types.GET_NOTA_BY_ID_SUCCESS:
      return {
        ...state,
        notaData: action.payload,
        notaLoading: false,
        notaError: false,
      };
    case Types.GET_NOTA_BY_ID_ERROR:
      return {
        ...state,
        notaData: null,
        notaLoading: false,
        notaError: action.payload,
      };
    case Types.GET_NOTA_BY_ID_RESET:
      return {
        ...state,
        notaData: null,
        notaLoading: false,
        notaError: false,
      };
    case Types.RECEIPT_UPLOAD:
      return {
        ...state,
        receiptSuccess: false,
        receiptLoading: true,
        receiptError: false,
      };
    case Types.RECEIPT_UPLOAD_SUCCESS:
      return {
        ...state,
        receiptSuccess: true,
        receiptLoading: false,
        receiptError: false,
      };
    case Types.RECEIPT_UPLOAD_ERROR:
      return {
        ...state,
        receiptSuccess: false,
        receiptLoading: false,
        receiptError: action.payload,
      };
    case Types.RECEIPT_UPLOAD_RESET:
      return {
        ...state,
        receiptSuccess: false,
        receiptLoading: false,
        receiptError: false,
      };
    default:
      return state;
  }
}

export const Creators = {
  getNotas: (payload) => ({
    type: Types.GET_NOTAS,
    payload,
  }),
  getNotasSuccess: (payload) => ({
    type: Types.GET_NOTAS_SUCCESS,
    payload,
  }),
  getNotasError: () => ({
    type: Types.GET_NOTAS_ERROR,
  }),

  putNotas: (payload) => ({
    type: Types.PUT_NOTAS,
    payload,
  }),
  putNotasSuccess: () => ({
    type: Types.PUT_NOTAS_SUCCESS,
  }),
  putNotasError: () => ({
    type: Types.PUT_NOTAS_ERROR,
  }),
  putNotasReset: () => ({
    type: Types.PUT_NOTAS_RESET,
  }),

  cloneNotas: (payload) => ({
    type: Types.CLONE_NOTAS,
    payload,
  }),
  cloneNotasSuccess: () => ({
    type: Types.CLONE_NOTAS_SUCCESS,
  }),
  cloneNotasError: () => ({
    type: Types.CLONE_NOTAS_ERROR,
  }),
  cloneNotasReset: () => ({
    type: Types.CLONE_NOTAS_RESET,
  }),
  paidNF: (payload) => ({
    type: Types.PAID_NOTAS,
    payload,
  }),
  paidNFSuccess: () => ({
    type: Types.PAID_NOTAS_SUCCESS,
  }),
  paidNFError: () => ({
    type: Types.PAID_NOTAS_ERROR,
  }),
  paidNFReset: () => ({
    type: Types.PAID_NOTAS_RESET,
  }),
  paidNFByNumber: (payload) => ({
    type: Types.PAID_NOTAS_BY_NUMBER,
    payload,
  }),
  paidNFByNumberSuccess: (payload) => ({
    type: Types.PAID_NOTAS_BY_NUMBER_SUCCESS,
    payload,
  }),
  paidNFByNumberError: () => ({
    type: Types.PAID_NOTAS_BY_NUMBER_ERROR,
  }),
  paidNFByNumberReset: () => ({
    type: Types.PAID_NOTAS_BY_NUMBER_RESET,
  }),
  getNotaById: (payload) => ({
    type: Types.GET_NOTA_BY_ID,
    payload,
  }),
  getNotaByIdSuccess: (payload) => ({
    type: Types.GET_NOTA_BY_ID_SUCCESS,
    payload,
  }),
  getNotaByIdError: (payload) => ({
    type: Types.GET_NOTA_BY_ID_ERROR,
    payload,
  }),
  getNotaByIdReset: () => ({
    type: Types.GET_NOTA_BY_ID_RESET,
  }),
  receiptUpload: (payload) => ({
    type: Types.RECEIPT_UPLOAD,
    payload,
  }),
  receiptUploadSuccess: () => ({
    type: Types.RECEIPT_UPLOAD_SUCCESS,
  }),
  receiptUploadError: (payload) => ({
    type: Types.RECEIPT_UPLOAD_ERROR,
    payload,
  }),
  receiptUploadReset: () => ({
    type: Types.RECEIPT_UPLOAD_RESET,
  }),
};
