import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import { Types as IbgeTypes, Creators as IbgeActions } from '../ducks/Ibge';

function* getEstados() {
  try {
    const { status, data } = yield call(
      axios.get,
      'https://servicodados.ibge.gov.br/api/v1/localidades/estados'
    );
    if (status === 200) {
      const estados = data?.map(i => ({ value: i.sigla, label: i.sigla }));
      const sortEstados = estados.sort((a, b) => {
        if (a.value < b.value) return -1;
        if (a.value > b.value) return 1;
        return 0;
      });
      yield put(IbgeActions.getEstadosSuccess(sortEstados));
    } else {
      yield put(IbgeActions.getEstadosError());
    }
  } catch (err) {
    yield put(IbgeActions.getEstadosError());
  }
}

function* getCidades({ payload }) {
  try {
    const { status, data } = yield call(
      axios.get,
      `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${payload.uf}/municipios`
    );
    if (status === 200) {
      const cidades = data?.map(i => ({ value: i.nome, label: i.nome }));
      yield put(IbgeActions.getCidadesSuccess(cidades));
    } else {
      yield put(IbgeActions.getCidadesError());
    }
  } catch (err) {
    yield put(IbgeActions.getCidadesError());
  }
}

function* getEstadosWatcher() {
  yield takeLatest(IbgeTypes.GET_ESTADOS, getEstados);
}
function* getCidadesWatcher() {
  yield takeLatest(IbgeTypes.GET_CIDADES, getCidades);
}

export default function* rootSaga() {
  yield all([fork(getEstadosWatcher), fork(getCidadesWatcher)]);
}
