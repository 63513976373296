import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
  Creators as GenerationActions,
  Types as GenerationTypes,
} from 'store/ducks/Generation';
import api from '../../util/Api';

function* listGeneration({ payload }) {
  try {
    const { status, data } = yield call(api.get, '/generation', {
      params: payload,
    });
    if (status === 200) {
      yield put(GenerationActions.getGenerationSuccess(data));
    } else {
      yield put(GenerationActions.getGenerationError());
    }
  } catch (err) {
    yield put(GenerationActions.getGenerationError());
  }
}

function* listGenerationWatcher() {
  yield takeLatest(GenerationTypes.GET_GENERATION, listGeneration);
}

export default function* rootSaga() {
  yield all([fork(listGenerationWatcher)]);
}
