import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import {
  Types as EvidentTypes,
  Creators as EvidentActions,
} from '../ducks/Evident';
import api from '../../util/Api';

function* getOrganizations() {
  try {
    const { status, data } = yield call(api.get, '/evident/organizations');
    if (status === 200) {
      yield put(EvidentActions.getOrganizationsSuccess(data));
    } else {
      yield put(EvidentActions.getOrganizationsError());
    }
  } catch (e) {
    yield put(EvidentActions.getOrganizationsError());
  }
}

function* getOrganizationsWatcher() {
  yield takeLatest(EvidentTypes.GET_ORGANIZATIONS, getOrganizations);
}

export default function* rootSaga() {
  yield all([fork(getOrganizationsWatcher)]);
}
