export const Types = {
  GET_BENEFICIARIES: 'GET_BENEFICIARIES',
  GET_BENEFICIARIES_SUCCESS: 'GET_BENEFICIARIES_SUCCESS',
  GET_BENEFICIARIES_ERROR: 'GET_BENEFICIARIES_ERROR',
  POST_BENEFICIARY: 'POST_BENEFICIARY',
  POST_BENEFICIARY_SUCCESS: 'POST_BENEFICIARY_SUCCESS',
  POST_BENEFICIARY_ERROR: 'POST_BENEFICIARY_ERROR',
  GET_BENEFICIARY_BY_ID: 'GET_BENEFICIARY_BY_ID',
  GET_BENEFICIARY_BY_ID_SUCCESS: 'GET_BENEFICIARY_BY_ID_SUCCESS',
  GET_BENEFICIARY_BY_ID_ERROR: 'GET_BENEFICIARY_BY_ID_ERROR',
  PUT_BENEFICIARY: 'PUT_BENEFICIARY',
  PUT_BENEFICIARY_SUCCESS: 'PUT_BENEFICIARY_SUCCESS',
  PUT_BENEFICIARY_ERROR: 'PUT_BENEFICIARY_ERROR',
  DELETE_BENEFICIARY: 'DELETE_BENEFICIARY',
  DELETE_BENEFICIARY_SUCCESS: 'DELETE_BENEFICIARY_SUCCESS',
  DELETE_BENEFICIARY_ERROR: 'DELETE_BENEFICIARY_ERROR',
  APPROVE_BENEFICIARY: 'APPROVE_BENEFICIARY',
  APPROVE_BENEFICIARY_SUCCESS: 'APPROVE_BENEFICIARY_SUCCESS',
  APPROVE_BENEFICIARY_ERROR: 'APPROVE_BENEFICIARY_ERROR',
  APPROVE_BENEFICIARY_RESET: 'APPROVE_BENEFICIARY_RESET',
  REFUSE_BENEFICIARY: 'REFUSE_BENEFICIARY',
  REFUSE_BENEFICIARY_SUCCESS: 'REFUSE_BENEFICIARY_SUCCESS',
  REFUSE_BENEFICIARY_ERROR: 'REFUSE_BENEFICIARY_ERROR',
  REFUSE_BENEFICIARY_RESET: 'REFUSE_BENEFICIARY_RESET',
  BENEFICIARY_CLEAN: 'BENEFICIARY_CLEAN',

  EVIDENT_BENEFICIARY_SYNC: 'EVIDENT_BENEFICIARY_SYNC',
  EVIDENT_BENEFICIARY_SYNC_SUCCESS: 'EVIDENT_BENEFICIARY_SYNC_SUCCESS',
  EVIDENT_BENEFICIARY_SYNC_ERROR: 'EVIDENT_BENEFICIARY_SYNC_ERROR',
  EVIDENT_BENEFICIARY_SYNC_RESET: 'EVIDENT_BENEFICIARY_SYNC_RESET',
};

const INIT_STATE = {
  data: null,
  beneficiary: null,
  loading: false,
  error: null,
  postSuccess: false,
  deleteSuccess: false,

  approveLoading: false,
  approveSuccess: false,
  approveError: false,

  refuseLoading: false,
  refuseSuccess: false,
  refuseError: false,

  evidentBeneficiarySyncLoading: false,
  evidentBeneficiarySyncSuccess: false,
  evidentBeneficiarySyncError: false,
  evidentBeneficiarySyncReset: false,

  amount: 10,
};

const BENEFICIARY_ERROR = {
  CEG_NOT_FOUND:
    'CEG não cadastrado na plataforma. Contate o administrador do sistema',
  CEG_HAS_ALREADY_BEEN_USED: 'CEG já cadastrado em outro empreendimento.',
};

export default function Beneficiary(state = INIT_STATE, action) {
  switch (action.type) {
    case Types.GET_BENEFICIARIES:
      return {
        ...state,
        data: null,
        loading: true,
        error: null,
      };
    case Types.GET_BENEFICIARIES_SUCCESS:
      return {
        ...state,
        data: action.payload.data || action.payload,
        amount: action.payload.total,
        loading: false,
        error: null,
      };
    case Types.GET_BENEFICIARIES_ERROR:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.payload,
      };
    case Types.POST_BENEFICIARY:
      return {
        ...state,
        postSuccess: false,
        loading: true,
        error: null,
      };
    case Types.POST_BENEFICIARY_SUCCESS:
      return {
        ...state,
        postSuccess: true,
        loading: false,
        error: null,
      };
    case Types.POST_BENEFICIARY_ERROR:
      return {
        ...state,
        postSuccess: false,
        loading: false,
        error:
          BENEFICIARY_ERROR?.[action.payload?.message] ||
          action.payload?.message,
      };
    case Types.GET_BENEFICIARY_BY_ID:
      return {
        ...state,
        beneficiary: null,
        loading: true,
        error: false,
      };
    case Types.GET_BENEFICIARY_BY_ID_SUCCESS:
      return {
        ...state,
        beneficiary: action.payload,
        loading: false,
        error: false,
      };
    case Types.GET_BENEFICIARY_BY_ID_ERROR:
      return {
        ...state,
        beneficiary: null,
        loading: false,
        error: action.payload,
      };

    case Types.PUT_BENEFICIARY:
      return {
        ...state,
        postSuccess: false,
        loading: true,
        error: null,
      };
    case Types.PUT_BENEFICIARY_SUCCESS:
      return {
        ...state,
        postSuccess: true,
        loading: false,
        error: null,
      };
    case Types.PUT_BENEFICIARY_ERROR:
      return {
        ...state,
        postSuccess: false,
        loading: false,
        error: action.payload,
      };
    case Types.DELETE_BENEFICIARY:
      return {
        ...state,
        deleteSuccess: false,
        loading: true,
        error: null,
      };
    case Types.DELETE_BENEFICIARY_SUCCESS:
      return {
        ...state,
        deleteSuccess: true,
        loading: false,
        error: null,
      };
    case Types.DELETE_BENEFICIARY_ERROR:
      return {
        ...state,
        deleteSuccess: false,
        loading: false,
        error: action.payload,
      };
    case Types.BENEFICIARY_CLEAN:
      return {
        ...state,
        beneficiary: null,
        postSuccess: false,
        deleteSuccess: false,
        loading: false,
        error: false,
      };

    case Types.APPROVE_BENEFICIARY:
      return {
        ...state,
        approveLoading: true,
        approveSuccess: false,
        approveError: false,
      };
    case Types.APPROVE_BENEFICIARY_SUCCESS:
      return {
        ...state,
        approveLoading: false,
        approveSuccess: true,
        approveError: false,
      };
    case Types.APPROVE_BENEFICIARY_ERROR:
      return {
        ...state,
        approveLoading: false,
        approveSuccess: false,
        approveError: action.payload,
      };
    case Types.APPROVE_BENEFICIARY_RESET:
      return {
        ...state,
        approveLoading: false,
        approveSuccess: false,
        approveError: false,
      };

    case Types.REFUSE_BENEFICIARY:
      return {
        ...state,
        refuseLoading: true,
        refuseSuccess: false,
        refuseError: false,
      };
    case Types.REFUSE_BENEFICIARY_SUCCESS:
      return {
        ...state,
        refuseLoading: false,
        refuseSuccess: true,
        refuseError: false,
      };
    case Types.REFUSE_BENEFICIARY_ERROR:
      return {
        ...state,
        refuseLoading: false,
        refuseSuccess: false,
        refuseError: true,
      };
    case Types.REFUSE_BENEFICIARY_RESET:
      return {
        ...state,
        refuseLoading: false,
        refuseSuccess: false,
        refuseError: false,
      };

    case Types.EVIDENT_BENEFICIARY_SYNC:
      return {
        ...state,
        evidentBeneficiarySyncLoading: true,
        evidentBeneficiarySyncSuccess: false,
        evidentBeneficiarySyncError: false,
      };
    case Types.EVIDENT_BENEFICIARY_SYNC_SUCCESS:
      return {
        ...state,
        evidentBeneficiarySyncLoading: false,
        evidentBeneficiarySyncSuccess: true,
        evidentBeneficiarySyncError: false,
      };
    case Types.EVIDENT_BENEFICIARY_SYNC_ERROR:
      return {
        ...state,
        evidentBeneficiarySyncLoading: false,
        evidentBeneficiarySyncSuccess: false,
        evidentBeneficiarySyncError: true,
      };
    case Types.EVIDENT_BENEFICIARY_SYNC_RESET:
      return {
        ...state,
        evidentBeneficiarySyncLoading: false,
        evidentBeneficiarySyncSuccess: false,
        evidentBeneficiarySyncError: false,
      };

    default:
      return state;
  }
}

export const Creators = {
  getBeneficiaries: payload => ({
    type: Types.GET_BENEFICIARIES,
    payload,
  }),
  getBeneficiariesSuccess: payload => ({
    type: Types.GET_BENEFICIARIES_SUCCESS,
    payload,
  }),
  getBeneficiariesError: payload => ({
    type: Types.GET_BENEFICIARIES_ERROR,
    payload,
  }),
  postBeneficiary: payload => ({
    type: Types.POST_BENEFICIARY,
    payload,
  }),
  postBeneficiarySuccess: payload => ({
    type: Types.POST_BENEFICIARY_SUCCESS,
    payload,
  }),
  postBeneficiaryError: payload => ({
    type: Types.POST_BENEFICIARY_ERROR,
    payload,
  }),
  getBeneficiaryById: payload => ({
    type: Types.GET_BENEFICIARY_BY_ID,
    payload,
  }),
  getBeneficiaryByIdSuccess: payload => ({
    type: Types.GET_BENEFICIARY_BY_ID_SUCCESS,
    payload,
  }),
  getBeneficiaryByIdError: payload => ({
    type: Types.GET_BENEFICIARY_BY_ID_ERROR,
    payload,
  }),
  putBeneficiary: payload => ({
    type: Types.PUT_BENEFICIARY,
    payload,
  }),
  putBeneficiarySuccess: payload => ({
    type: Types.PUT_BENEFICIARY_SUCCESS,
    payload,
  }),
  putBeneficiaryError: payload => ({
    type: Types.PUT_BENEFICIARY_ERROR,
    payload,
  }),
  deleteBeneficiary: payload => ({
    type: Types.DELETE_BENEFICIARY,
    payload,
  }),
  deleteBeneficiarySuccess: payload => ({
    type: Types.DELETE_BENEFICIARY_SUCCESS,
    payload,
  }),
  deleteBeneficiaryError: payload => ({
    type: Types.DELETE_BENEFICIARY_ERROR,
    payload,
  }),
  BeneficiaryClean: () => ({
    type: Types.BENEFICIARY_CLEAN,
  }),
  approveBeneficiary: payload => ({
    type: Types.APPROVE_BENEFICIARY,
    payload,
  }),
  approveBeneficiarySuccess: () => ({
    type: Types.APPROVE_BENEFICIARY_SUCCESS,
  }),
  approveBeneficiaryError: payload => ({
    type: Types.APPROVE_BENEFICIARY_ERROR,
    payload,
  }),
  approveBeneficiaryReset: () => ({
    type: Types.APPROVE_BENEFICIARY_RESET,
  }),
  refuseBeneficiary: payload => ({
    type: Types.REFUSE_BENEFICIARY,
    payload,
  }),
  refuseBeneficiarySuccess: () => ({
    type: Types.REFUSE_BENEFICIARY_SUCCESS,
  }),
  refuseBeneficiaryError: () => ({
    type: Types.REFUSE_BENEFICIARY_ERROR,
  }),
  refuseBeneficiaryReset: () => ({
    type: Types.REFUSE_BENEFICIARY_RESET,
  }),

  evidentBeneficiarySync: (payload) => ({
    type: Types.EVIDENT_BENEFICIARY_SYNC,
    payload,
  }),
  evidentBeneficiarySyncSuccess: () => ({
    type: Types.EVIDENT_BENEFICIARY_SYNC_SUCCESS,
  }),
  evidentBeneficiarySyncError: () => ({
    type: Types.EVIDENT_BENEFICIARY_SYNC_ERROR,
  }),
  apiSyncBeneficiaryReset: () => ({
    type: Types.EVIDENT_BENEFICIARY_SYNC_RESET,
  }),
};
