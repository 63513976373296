export const Types = {
  GET_REDEMPTIONS: 'GET_REDEMPTIONS',
  GET_REDEMPTIONS_SUCCESS: 'GET_REDEMPTIONS_SUCCESS',
  GET_REDEMPTIONS_ERROR: 'GET_REDEMPTIONS_ERROR',
  POST_REDEMPTION: 'POST_REDEMPTION',
  POST_REDEMPTION_SUCCESS: 'POST_REDEMPTION_SUCCESS',
  POST_REDEMPTION_ERROR: 'POST_REDEMPTION_ERROR',
  GET_REDEMPTION_BY_ID: 'GET_REDEMPTION_BY_ID',
  GET_REDEMPTION_BY_ID_SUCCESS: 'GET_REDEMPTION_BY_ID_SUCCESS',
  GET_REDEMPTION_BY_ID_ERROR: 'GET_REDEMPTION_BY_ID_ERROR',
  PUT_REDEMPTION: 'PUT_REDEMPTION',
  PUT_REDEMPTION_SUCCESS: 'PUT_REDEMPTION_SUCCESS',
  PUT_REDEMPTION_ERROR: 'PUT_REDEMPTION_ERROR',
  DELETE_REDEMPTION: 'DELETE_REDEMPTION',
  DELETE_REDEMPTION_SUCCESS: 'DELETE_REDEMPTION_SUCCESS',
  DELETE_REDEMPTION_ERROR: 'DELETE_REDEMPTION_ERROR',
  APPROVE_REDEMPTION: 'APPROVE_REDEMPTION',
  APPROVE_REDEMPTION_SUCCESS: 'APPROVE_REDEMPTION_SUCCESS',
  APPROVE_REDEMPTION_ERROR: 'APPROVE_REDEMPTION_ERROR',
  APPROVE_REDEMPTION_RESET: 'APPROVE_REDEMPTION_RESET',
  REFUSE_REDEMPTION: 'REFUSE_REDEMPTION',
  REFUSE_REDEMPTION_SUCCESS: 'REFUSE_REDEMPTION_SUCCESS',
  REFUSE_REDEMPTION_ERROR: 'REFUSE_REDEMPTION_ERROR',
  REFUSE_REDEMPTION_RESET: 'REFUSE_REDEMPTION_RESET',
  REDEMPTION_CLEAN: 'REDEMPTION_CLEAN',
  GET_IREC_ITEMS: 'GET_IREC_ITEMS',
  GET_IREC_ITEMS_SUCCESS: 'GET_IREC_ITEMS_SUCCESS',
  GET_IREC_ITEMS_ERROR: 'GET_IREC_ITEMS_ERROR',
  GET_IREC_ITEMS_RESET: 'GET_IREC_ITEMS_RESET',

};

const INIT_STATE = {
  data: null,
  redemption: null,
  loading: false,
  error: null,
  postSuccess: false,
  deleteSuccess: false,

  approveLoading: false,
  approveSuccess: false,
  approveError: false,

  itemsLoading: false,
  itemsSuccess: false,
  itemsError: false,

  refuseLoading: false,
  refuseSuccess: false,
  refuseError: false,

  amount: 10,
};

const REDEMPTION_ERROR = {
  CEG_NOT_FOUND:
    'CEG não cadastrado na plataforma. Contate o administrador do sistema',
  CEG_HAS_ALREADY_BEEN_USED: 'CEG já cadastrado em outro empreendimento.',
};

export default function Redemption(state = INIT_STATE, action) {
  switch (action.type) {
    case Types.GET_REDEMPTIONS:
      return {
        ...state,
        data: null,
        loading: true,
        error: null,
      };
    case Types.GET_REDEMPTIONS_SUCCESS:
      return {
        ...state,
        data: action.payload.data || action.payload,
        amount: action.payload.total,
        loading: false,
        error: null,
      };
    case Types.GET_REDEMPTIONS_ERROR:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.payload,
      };
    case Types.POST_REDEMPTION:
      return {
        ...state,
        postSuccess: false,
        loading: true,
        error: null,
      };
    case Types.POST_REDEMPTION_SUCCESS:
      return {
        ...state,
        postSuccess: true,
        loading: false,
        error: null,
      };
    case Types.POST_REDEMPTION_ERROR:
      return {
        ...state,
        postSuccess: false,
        loading: false,
        error:
          REDEMPTION_ERROR?.[action.payload?.message] ||
          action.payload?.message,
      };
    case Types.GET_REDEMPTION_BY_ID:
      return {
        ...state,
        redemption: null,
        loading: true,
        error: false,
      };
    case Types.GET_REDEMPTION_BY_ID_SUCCESS:
      return {
        ...state,
        redemption: action.payload,
        loading: false,
        error: false,
      };
    case Types.GET_REDEMPTION_BY_ID_ERROR:
      return {
        ...state,
        redemption: null,
        loading: false,
        error: action.payload,
      };
    case Types.PUT_REDEMPTION:
      return {
        ...state,
        postSuccess: false,
        loading: true,
        error: null,
      };
    case Types.PUT_REDEMPTION_SUCCESS:
      return {
        ...state,
        postSuccess: true,
        loading: false,
        error: null,
      };
    case Types.PUT_REDEMPTION_ERROR:
      return {
        ...state,
        postSuccess: false,
        loading: false,
        error: action.payload,
      };
    case Types.DELETE_REDEMPTION:
      return {
        ...state,
        deleteSuccess: false,
        loading: true,
        error: null,
      };
    case Types.DELETE_REDEMPTION_SUCCESS:
      return {
        ...state,
        deleteSuccess: true,
        loading: false,
        error: null,
      };
    case Types.DELETE_REDEMPTION_ERROR:
      return {
        ...state,
        deleteSuccess: false,
        loading: false,
        error: action.payload,
      };
    case Types.REDEMPTION_CLEAN:
      return {
        ...state,
        redemption: null,
        postSuccess: false,
        deleteSuccess: false,
        loading: false,
        error: false,
      };

    case Types.APPROVE_REDEMPTION:
      return {
        ...state,
        approveLoading: true,
        approveSuccess: false,
        approveError: false,
      };
    case Types.APPROVE_REDEMPTION_SUCCESS:
      return {
        ...state,
        approveLoading: false,
        approveSuccess: true,
        approveError: false,
      };
    case Types.APPROVE_REDEMPTION_ERROR:
      return {
        ...state,
        approveLoading: false,
        approveSuccess: false,
        approveError: action.payload,
      };
    case Types.APPROVE_REDEMPTION_RESET:
      return {
        ...state,
        approveLoading: false,
        approveSuccess: false,
        approveError: false,
      };

    case Types.REFUSE_REDEMPTION:
      return {
        ...state,
        refuseLoading: true,
        refuseSuccess: false,
        refuseError: false,
      };
    case Types.REFUSE_REDEMPTION_SUCCESS:
      return {
        ...state,
        refuseLoading: false,
        refuseSuccess: true,
        refuseError: false,
      };
    case Types.REFUSE_REDEMPTION_ERROR:
      return {
        ...state,
        refuseLoading: false,
        refuseSuccess: false,
        refuseError: true,
      };
    case Types.REFUSE_REDEMPTION_RESET:
      return {
        ...state,
        refuseLoading: false,
        refuseSuccess: false,
        refuseError: false,
      };

    case Types.GET_IREC_ITEMS:
      return {
        ...state,
        data: null,
        itemsLoading: true,
        itemsSuccess: false,
        itemsError: false,
      };
    case Types.GET_IREC_ITEMS_SUCCESS:
      return {
        ...state,
        data: action.payload.data || action.payload,
        amount: action.payload.total,
        itemsLoading: false,
        itemsSuccess: true,
        itemsError: false,
      };
    case Types.GET_IREC_ITEMS_ERROR:
      return {
        ...state,
        data: null,
        error: action.payload,
        itemsLoading: false,
        itemsSuccess: false,
        itemsError: true,
      };
    case Types.GET_IREC_ITEMS_RESET:
      return {
        ...state,
        itemsLoading: false,
        itemsSuccess: false,
        itemsError: false,
      };
    default:
      return state;
  }
}

export const Creators = {
  getRedemptions: payload => ({
    type: Types.GET_REDEMPTIONS,
    payload,
  }),
  getRedemptionsSuccess: payload => ({
    type: Types.GET_REDEMPTIONS_SUCCESS,
    payload,
  }),
  getRedemptionsError: payload => ({
    type: Types.GET_REDEMPTIONS_ERROR,
    payload,
  }),
  postRedemption: payload => ({
    type: Types.POST_REDEMPTION,
    payload,
  }),
  postRedemptionSuccess: payload => ({
    type: Types.POST_REDEMPTION_SUCCESS,
    payload,
  }),
  postRedemptionError: payload => ({
    type: Types.POST_REDEMPTION_ERROR,
    payload,
  }),
  getRedemptionById: payload => ({
    type: Types.GET_REDEMPTION_BY_ID,
    payload,
  }),
  getRedemptionByIdSuccess: payload => ({
    type: Types.GET_REDEMPTION_BY_ID_SUCCESS,
    payload,
  }),
  getRedemptionByIdError: payload => ({
    type: Types.GET_REDEMPTION_BY_ID_ERROR,
    payload,
  }),
  putRedemption: payload => ({
    type: Types.PUT_REDEMPTION,
    payload,
  }),
  putRedemptionSuccess: payload => ({
    type: Types.PUT_REDEMPTION_SUCCESS,
    payload,
  }),
  putRedemptionError: payload => ({
    type: Types.PUT_REDEMPTION_ERROR,
    payload,
  }),
  deleteRedemption: payload => ({
    type: Types.DELETE_REDEMPTION,
    payload,
  }),
  deleteRedemptionSuccess: payload => ({
    type: Types.DELETE_REDEMPTION_SUCCESS,
    payload,
  }),
  deleteRedemptionError: payload => ({
    type: Types.DELETE_REDEMPTION_ERROR,
    payload,
  }),
  RedemptionClean: () => ({
    type: Types.REDEMPTION_CLEAN,
  }),
  approveRedemption: payload => ({
    type: Types.APPROVE_REDEMPTION,
    payload,
  }),
  approveRedemptionSuccess: () => ({
    type: Types.APPROVE_REDEMPTION_SUCCESS,
  }),
  approveRedemptionError: payload => ({
    type: Types.APPROVE_REDEMPTION_ERROR,
    payload,
  }),
  approveRedemptionReset: () => ({
    type: Types.APPROVE_REDEMPTION_RESET,
  }),
  refuseRedemption: payload => ({
    type: Types.REFUSE_REDEMPTION,
    payload,
  }),
  refuseRedemptionSuccess: () => ({
    type: Types.REFUSE_REDEMPTION_SUCCESS,
  }),
  refuseRedemptionError: () => ({
    type: Types.REFUSE_REDEMPTION_ERROR,
  }),
  refuseRedemptionReset: () => ({
    type: Types.REFUSE_REDEMPTION_RESET,
  }),
  getIrecItems: payload => ({
    type: Types.GET_IREC_ITEMS,
    payload,
  }),
  getIrecItemsSuccess: payload => ({
    type: Types.GET_IREC_ITEMS_SUCCESS,
    payload,
  }),
  getIrecItemsError: payload => ({
    type: Types.GET_IREC_ITEMS_ERROR,
    payload,
  }),
  getIrecItemsReset: () => ({
    type: Types.GET_IREC_ITEMS_RESET
  }),
};

