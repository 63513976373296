export const Types = {
  GET_CEG_REQUEST: 'GET_CEG_REQUEST',
  GET_CEG_SUCCESS: 'GET_CEG_SUCCESS',
  GET_CEG_FAIL: 'GET_CEG_FAIL',
  GET_CEG_LIST: 'GET_CEG_LIST',
  GET_CEG_LIST_SUCCESS: 'GET_CEG_LIST_SUCCESS',
  GET_CEG_LIST_ERROR: 'GET_CEG_LIST_ERROR',

  DELETE_CEG_REQUEST: 'DELETE_CEG_REQUEST',
  DELETE_CEG_SUCCESS: 'DELETE_CEG_SUCCESS',
  DELETE_CEG_FAIL: 'DELETE_CEG_FAIL',
  DELETE_CEG_CLEAN: 'DELETE_CEG_CLEAN',

  GET_ID_CEG_REQUEST: 'GET_ID_CEG_REQUEST',
  GET_ID_CEG_SUCCESS: 'GET_ID_CEG_SUCCESS',
  GET_ID_CEG_FAIL: 'GET_ID_CEG_FAIL',

  GET_ID_CEG_DETAILS_REQUEST: 'GET_ID_CEG_DETAILS_REQUEST',
  GET_ID_CEG_DETAILS_SUCCESS: 'GET_ID_CEG_DETAILS_SUCCESS',
  GET_ID_CEG_DETAILS_FAIL: 'GET_ID_CEG_DETAILS_FAIL',

  CREATE_CEG_REQUEST: 'CREATE_CEG_REQUEST',
  CREATE_CEG_SUCCESS: 'CREATE_CEG_SUCCESS',
  CREATE_CEG_FAIL: 'CREATE_CEG_FAIL',
  CREATE_CEG_CLEAN: 'CREATE_CEG_CLEAN',

  UPDATE_CEG_REQUEST: 'UPDATE_CEG_REQUEST',
  UPDATE_CEG_SUCCESS: 'UPDATE_CEG_SUCCESS',
  UPDATE_CEG_FAIL: 'UPDATE_CEG_FAIL',
  UPDATE_CEG_CLEAN: 'UPDATE_CEG_CLEAN',
};

const INIT_STATE = {
  amount: 10,

  loading: false,
  error: false,
  data: null,

  cegList: [],
  cegListLoading: false,
  cegListError: false,

  deleteLoading: false,
  deleteSuccess: false,
  deleteError: false,

  idLoading: false,
  idData: null,
  idError: false,

  idDetailsLoading: false,
  idDetailsData: null,
  idDetailsError: false,

  createLoading: false,
  createSuccess: false,
  createError: false,

  updateLoading: false,
  updateSuccess: false,
  updateError: false,
};

export default function Ceg(state = INIT_STATE, action) {
  switch (action.type) {
    case Types.GET_CEG_REQUEST:
      return {
        loading: true,
        error: false,
      };
    case Types.GET_CEG_SUCCESS:
      return {
        loading: false,
        error: false,
        data: action.payload.data,
        amount: action.payload.total,
      };
    case Types.GET_CEG_FAIL:
      return {
        loading: false,
        error: true,
      };
    case Types.GET_CEG_LIST:
      return {
        ...state,
        cegList: [],
        cegListLoading: true,
        cegListError: false,
      };
    case Types.GET_CEG_LIST_SUCCESS:
      return {
        ...state,
        cegList: action.payload,
        cegListLoading: false,
        cegListError: false,
      };
    case Types.GET_CEG_LIST_ERROR:
      return {
        ...state,
        cegList: [],
        cegListLoading: false,
        cegListError: true,
      };

    case Types.DELETE_CEG_REQUEST:
      return {
        ...state,
        deleteLoading: true,
        deleteError: false,
        deleteSuccess: false,
      };
    case Types.DELETE_CEG_SUCCESS:
      return {
        ...state,
        deleteLoading: false,
        deleteError: false,
        deleteSuccess: true,
      };
    case Types.DELETE_CEG_FAIL:
      return {
        ...state,
        deleteLoading: false,
        deleteError: true,
        deleteSuccess: false,
      };
    case Types.DELETE_CEG_CLEAN:
      return {
        ...state,
        deleteLoading: false,
        deleteError: false,
        deleteSuccess: false,
      };

    case Types.GET_ID_CEG_REQUEST:
      return {
        ...state,
        idLoading: true,
        idError: false,
      };
    case Types.GET_ID_CEG_SUCCESS:
      return {
        ...state,
        idLoading: false,
        idError: false,
        idData: action.payload,
      };
    case Types.GET_ID_CEG_FAIL:
      return {
        ...state,
        idLoading: false,
        idError: true,
      };

    case Types.GET_ID_CEG_DETAILS_REQUEST:
      return {
        ...state,
        idDetailsLoading: true,
        idDetailsError: false,
      };
    case Types.GET_ID_CEG_DETAILS_SUCCESS:
      return {
        ...state,
        idDetailsLoading: false,
        idDetailsError: false,
        idDetailsData: action.payload,
      };
    case Types.GET_ID_CEG_DETAILS_FAIL:
      return {
        ...state,
        idDetailsLoading: false,
        idDetailsError: true,
      };

    case Types.CREATE_CEG_REQUEST:
      return {
        ...state,
        createLoading: true,
        createError: false,
        createSuccess: false,
      };
    case Types.CREATE_CEG_SUCCESS:
      return {
        ...state,
        createLoading: false,
        createError: false,
        createSuccess: true,
      };
    case Types.CREATE_CEG_FAIL:
      return {
        ...state,
        createLoading: false,
        createError: true,
        createSuccess: false,
      };
    case Types.CREATE_CEG_CLEAN:
      return {
        ...state,
        createLoading: false,
        createError: false,
        createSuccess: false,
      };

    case Types.UPDATE_CEG_REQUEST:
      return {
        ...state,
        updateLoading: true,
        updateError: false,
        updateSuccess: false,
      };
    case Types.UPDATE_CEG_SUCCESS:
      return {
        ...state,
        updateLoading: false,
        updateError: false,
        updateSuccess: true,
      };
    case Types.UPDATE_CEG_FAIL:
      return {
        ...state,
        updateLoading: false,
        updateError: true,
        updateSuccess: false,
      };
    case Types.UPDATE_CEG_CLEAN:
      return {
        ...state,
        updateLoading: false,
        updateError: false,
        updateSuccess: false,
      };

    default:
      return state;
  }
}

export const Creators = {
  getCegRequest: (payload) => ({
    type: Types.GET_CEG_REQUEST,
    payload,
  }),
  getCegSuccess: (payload) => ({
    type: Types.GET_CEG_SUCCESS,
    payload,
  }),
  getCegFail: () => ({
    type: Types.GET_CEG_FAIL,
  }),
  getCegList: () => ({
    type: Types.GET_CEG_LIST,
  }),
  getCegListSuccess: (payload) => ({
    type: Types.GET_CEG_LIST_SUCCESS,
    payload,
  }),
  getCegListError: () => ({
    type: Types.GET_CEG_LIST_ERROR,
  }),

  // DELETE
  deleteCegRequest: (payload) => ({
    type: Types.DELETE_CEG_REQUEST,
    payload,
  }),
  deleteCegSuccess: () => ({
    type: Types.DELETE_CEG_SUCCESS,
  }),
  deleteCegFail: () => ({
    type: Types.DELETE_CEG_FAIL,
  }),
  deleteCegClean: () => ({
    type: Types.DELETE_CEG_CLEAN,
  }),

  // ID
  getIdCegRequest: (payload) => ({
    type: Types.GET_ID_CEG_REQUEST,
    payload,
  }),
  getIdCegSuccess: (payload) => ({
    type: Types.GET_ID_CEG_SUCCESS,
    payload,
  }),
  getIdCegFail: () => ({
    type: Types.GET_ID_CEG_FAIL,
  }),

  // ID DETAILS
  getIdCegDetailsRequest: (payload) => ({
    type: Types.GET_ID_CEG_DETAILS_REQUEST,
    payload,
  }),
  getIdCegDetailsSuccess: (payload) => ({
    type: Types.GET_ID_CEG_DETAILS_SUCCESS,
    payload,
  }),
  getIdCegDetailsFail: () => ({
    type: Types.GET_ID_CEG_DETAILS_FAIL,
  }),

  // CREATE
  createCegRequest: (payload) => ({
    type: Types.CREATE_CEG_REQUEST,
    payload,
  }),
  createCegSuccess: () => ({
    type: Types.CREATE_CEG_SUCCESS,
  }),
  createCegFail: () => ({
    type: Types.CREATE_CEG_FAIL,
  }),
  createCegClean: () => ({
    type: Types.CREATE_CEG_CLEAN,
  }),

  // UPDATE
  updateCegRequest: (payload) => ({
    type: Types.UPDATE_CEG_REQUEST,
    payload,
  }),
  updateCegSuccess: (payload) => ({
    type: Types.UPDATE_CEG_SUCCESS,
    payload,
  }),
  updateCegFail: () => ({
    type: Types.UPDATE_CEG_FAIL,
  }),
  updateCegClean: () => ({
    type: Types.UPDATE_CEG_CLEAN,
  }),
};
