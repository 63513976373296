import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import {
  Types as SerproTypes,
  Creators as SerproActions,
} from '../ducks/Serpro';
import api from '../../util/Api';

function* getCnpj({ payload }) {
  try {
    const { status, data } = yield call(api.get, `/cnpj/${payload}`);
    if (status === 200) {
      yield put(SerproActions.getCnpjSuccess(data));
    } else {
      yield put(SerproActions.getCnpjError());
    }
  } catch (e) {
    yield put(SerproActions.getCnpjError());
  }
}

function* getCnpjWatcher() {
  yield takeLatest(SerproTypes.GET_CNPJ, getCnpj);
}

export default function* rootSaga() {
  yield all([fork(getCnpjWatcher)]);
}
