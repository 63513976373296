export const Types = {
  GET_LIST_API_INTEGRATION_REQUEST: 'GET_LIST_API_INTEGRATION_REQUEST',
  GET_LIST_API_INTEGRATION_SUCCESS: 'GET_LIST_API_INTEGRATION_SUCCESS',
  GET_LIST_API_INTEGRATION_FAIL: 'GET_LIST_API_INTEGRATION_FAIL',

  SYNC_API_INTEGRATION_REQUEST: 'SYNC_API_INTEGRATION_REQUEST',
  SYNC_API_INTEGRATION_SUCCESS: 'SYNC_API_INTEGRATION_SUCCESS',
  SYNC_API_INTEGRATION_FAIL: 'SYNC_API_INTEGRATION_FAIL',
  SYNC_API_INTEGRATION_CLEAN: 'SYNC_API_INTEGRATION_CLEAN',

  DELETE_API_INTEGRATION_REQUEST: 'DELETE_API_INTEGRATION_REQUEST',
  DELETE_API_INTEGRATION_SUCCESS: 'DELETE_API_INTEGRATION_SUCCESS',
  DELETE_API_INTEGRATION_FAIL: 'DELETE_API_INTEGRATION_FAIL',
  DELETE_API_INTEGRATION_CLEAN: 'DELETE_API_INTEGRATION_CLEAN',

  GET_ID_API_INTEGRATION_REQUEST: 'GET_ID_API_INTEGRATION_REQUEST',
  GET_ID_API_INTEGRATION_SUCCESS: 'GET_ID_API_INTEGRATION_SUCCESS',
  GET_ID_API_INTEGRATION_FAIL: 'GET_ID_API_INTEGRATION_FAIL',
  GET_ID_API_INTEGRATION_CLEAN: 'GET_ID_API_INTEGRATION_CLEAN',

  CREATE_API_INTEGRATION_REQUEST: 'CREATE_API_INTEGRATION_REQUEST',
  CREATE_API_INTEGRATION_SUCCESS: 'CREATE_API_INTEGRATION_SUCCESS',
  CREATE_API_INTEGRATION_FAIL: 'CREATE_API_INTEGRATION_FAIL',
  CREATE_API_INTEGRATION_CLEAN: 'CREATE_API_INTEGRATION_CLEAN',

  UPDATE_API_INTEGRATION_REQUEST: 'UPDATE_API_INTEGRATION_REQUEST',
  UPDATE_API_INTEGRATION_SUCCESS: 'UPDATE_API_INTEGRATION_SUCCESS',
  UPDATE_API_INTEGRATION_FAIL: 'UPDATE_API_INTEGRATION_FAIL',
  UPDATE_API_INTEGRATION_CLEAN: 'UPDATE_API_INTEGRATION_CLEAN',
};

const INIT_STATE = {
  amount: 10,

  data: null,
  loading: false,
  error: false,

  deleteLoading: false,
  deleteSuccess: false,
  deleteError: false,

  idLoading: false,
  idData: null,
  idError: false,

  createLoading: false,
  createSuccess: false,
  createError: false,

  updateLoading: false,
  updateSuccess: false,
  updateError: false,

  syncLoading: false,
  syncSuccess: false,
  syncError: false,
  syncSuccessMsg: null,
};

export default function ApiIntegration(state = INIT_STATE, action) {
  switch (action.type) {
    case Types.GET_LIST_API_INTEGRATION_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case Types.GET_LIST_API_INTEGRATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload.data || action.payload,
        amount: action.payload.total,
      };
    case Types.GET_LIST_API_INTEGRATION_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case Types.SYNC_API_INTEGRATION_REQUEST:
      return {
        ...state,
        syncLoading: true,
        syncError: false,
        syncSuccess: false,
      };
    case Types.SYNC_API_INTEGRATION_SUCCESS:
      return {
        ...state,
        syncLoading: false,
        syncError: false,
        syncSuccessMsg: action?.payload?.message,
        syncSuccess: true,
      };
    case Types.SYNC_API_INTEGRATION_FAIL:
      return {
        ...state,
        syncLoading: false,
        syncError: true,
        syncSuccess: false,
      };
    case Types.SYNC_API_INTEGRATION_CLEAN:
      return {
        ...state,
        syncLoading: false,
        syncError: false,
        syncSuccess: false,
        syncSuccessMsg: null,
      };

    case Types.DELETE_API_INTEGRATION_REQUEST:
      return {
        ...state,
        deleteLoading: true,
        deleteError: false,
        deleteSuccess: false,
      };
    case Types.DELETE_API_INTEGRATION_SUCCESS:
      return {
        ...state,
        deleteLoading: false,
        deleteError: false,
        deleteSuccess: true,
      };
    case Types.DELETE_API_INTEGRATION_FAIL:
      return {
        ...state,
        deleteLoading: false,
        deleteError: true,
        deleteSuccess: false,
      };
    case Types.DELETE_API_INTEGRATION_CLEAN:
      return {
        ...state,
        deleteLoading: false,
        deleteError: false,
        deleteSuccess: false,
      };

    case Types.GET_ID_API_INTEGRATION_REQUEST:
      return {
        ...state,
        idLoading: true,
        idError: false,
      };
    case Types.GET_ID_API_INTEGRATION_SUCCESS:
      return {
        ...state,
        idLoading: false,
        idError: false,
        idData: action.payload,
      };
    case Types.GET_ID_API_INTEGRATION_FAIL:
      return {
        ...state,
        idLoading: false,
        idError: true,
      };
    case Types.GET_ID_API_INTEGRATION_CLEAN:
      return {
        ...state,
        idLoading: false,
        idError: false,
        idData: null
      };

    case Types.CREATE_API_INTEGRATION_REQUEST:
      return {
        ...state,
        createLoading: true,
        createError: false,
        createSuccess: false,
      };
    case Types.CREATE_API_INTEGRATION_SUCCESS:
      return {
        ...state,
        createLoading: false,
        createError: false,
        createSuccess: true,
      };
    case Types.CREATE_API_INTEGRATION_FAIL:
      return {
        ...state,
        createLoading: false,
        createError: true,
        createSuccess: false,
      };
    case Types.CREATE_API_INTEGRATION_CLEAN:
      return {
        ...state,
        createLoading: false,
        createError: false,
        createSuccess: false,
      };

    case Types.UPDATE_API_INTEGRATION_REQUEST:
      return {
        ...state,
        updateLoading: true,
        updateError: false,
        updateSuccess: false,
      };
    case Types.UPDATE_API_INTEGRATION_SUCCESS:
      return {
        ...state,
        updateLoading: false,
        updateError: false,
        updateSuccess: true,
      };
    case Types.UPDATE_API_INTEGRATION_FAIL:
      return {
        ...state,
        updateLoading: false,
        updateError: true,
        updateSuccess: false,
      };
    case Types.UPDATE_API_INTEGRATION_CLEAN:
      return {
        ...state,
        updateLoading: false,
        updateError: false,
        updateSuccess: false,
      };

    default:
      return state;
  }
}

export const Creators = {
  // GET
  getListApiIntegrationRequest: (payload) => ({
    type: Types.GET_LIST_API_INTEGRATION_REQUEST,
    payload,
  }),
  getListApiIntegrationSuccess: (payload) => ({
    type: Types.GET_LIST_API_INTEGRATION_SUCCESS,
    payload,
  }),
  getListApiIntegrationFail: () => ({
    type: Types.GET_LIST_API_INTEGRATION_FAIL,
  }),

  // GET
  syncApiIntegrationRequest: (payload) => ({
    type: Types.SYNC_API_INTEGRATION_REQUEST,
    payload,
  }),
  syncApiIntegrationSuccess: (payload) => ({
    type: Types.SYNC_API_INTEGRATION_SUCCESS,
    payload,
  }),
  /* syncApiIntegrationSuccess: () => ({
    type: Types.SYNC_API_INTEGRATION_SUCCESS,
  }), */
  syncApiIntegrationFail: () => ({
    type: Types.SYNC_API_INTEGRATION_FAIL,
  }),
  syncApiIntegrationClean: () => ({
    type: Types.SYNC_API_INTEGRATION_CLEAN,
  }),

  // DELETE
  deleteApiIntegrationRequest: (payload) => ({
    type: Types.DELETE_API_INTEGRATION_REQUEST,
    payload,
  }),
  deleteApiIntegrationSuccess: () => ({
    type: Types.DELETE_API_INTEGRATION_SUCCESS,
  }),
  deleteApiIntegrationFail: () => ({
    type: Types.DELETE_API_INTEGRATION_FAIL,
  }),
  deleteApiIntegrationClean: () => ({
    type: Types.DELETE_API_INTEGRATION_CLEAN,
  }),

  // ID
  getIdApiIntegrationRequest: (payload) => ({
    type: Types.GET_ID_API_INTEGRATION_REQUEST,
    payload,
  }),
  getIdApiIntegrationSuccess: (payload) => ({
    type: Types.GET_ID_API_INTEGRATION_SUCCESS,
    payload,
  }),
  getIdApiIntegrationFail: () => ({
    type: Types.GET_ID_API_INTEGRATION_FAIL,
  }),
  getIdApiIntegrationClean: () => ({
    type: Types.GET_ID_API_INTEGRATION_CLEAN,
  }),

  // CREATE
  createApiIntegrationRequest: (payload) => ({
    type: Types.CREATE_API_INTEGRATION_REQUEST,
    payload,
  }),
  createApiIntegrationSuccess: () => ({
    type: Types.CREATE_API_INTEGRATION_SUCCESS,
  }),
  createApiIntegrationFail: () => ({
    type: Types.CREATE_API_INTEGRATION_FAIL,
  }),
  createApiIntegrationClean: () => ({
    type: Types.CREATE_API_INTEGRATION_CLEAN,
  }),

  // UPDATE
  updateApiIntegrationRequest: (payload) => ({
    type: Types.UPDATE_API_INTEGRATION_REQUEST,
    payload,
  }),
  updateApiIntegrationSuccess: (payload) => ({
    type: Types.UPDATE_API_INTEGRATION_SUCCESS,
    payload,
  }),
  updateApiIntegrationFail: () => ({
    type: Types.UPDATE_API_INTEGRATION_FAIL,
  }),
  updateApiIntegrationClean: () => ({
    type: Types.UPDATE_API_INTEGRATION_CLEAN,
  }),
};
