import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { Creators as CegActions, Types as CegTypes } from '../ducks/Ceg';
import api from '../../util/Api';

function* getCeg({ payload }) {
  try {
    const { status, data } = yield call(api.get, '/ceg', {
      params: payload,
    });
    if (status === 200) {
      yield put(CegActions.getCegSuccess(data));
    } else {
      yield put(CegActions.getCegFail());
    }
  } catch (err) {
    yield put(CegActions.getCegFail());
  }
}

function* getListCeg() {
  try {
    const { status, data } = yield call(api.get, '/ceg/list');
    if (status === 200) {
      yield put(CegActions.getCegListSuccess(data));
    } else {
      yield put(CegActions.getCegListError());
    }
  } catch (err) {
    yield put(CegActions.getCegListError());
  }
}

function* deleteCeg({ payload }) {
  try {
    const { status } = yield call(api.delete, `/ceg/${payload}`);
    if (status === 200) {
      yield put(CegActions.deleteCegSuccess());
    } else {
      yield put(CegActions.deleteCegFail());
    }
  } catch (err) {
    yield put(CegActions.deleteCegFail());
  }
}

function* getIdCeg({ payload }) {
  try {
    const { status, data } = yield call(api.get, `/ceg/${payload}`);
    if (status === 200) {
      yield put(CegActions.getIdCegSuccess(data));
    } else {
      yield put(CegActions.getIdCegFail());
    }
  } catch (err) {
    yield put(CegActions.getIdCegFail());
  }
}

function* getIdCegDetails({ payload }) {
  try {
    const { status, data } = yield call(api.get, `/ceg/details/${payload}`);
    if (status === 200) {
      yield put(CegActions.getIdCegDetailsSuccess(data));
    } else {
      yield put(CegActions.getIdCegDetailsFail());
    }
  } catch (err) {
    yield put(CegActions.getIdCegDetailsFail());
  }
}

function* createCeg({ payload }) {
  try {
    const { status } = yield call(api.post, '/ceg', payload);
    if (status === 200) {
      yield put(CegActions.createCegSuccess());
    } else {
      yield put(CegActions.createCegFail());
    }
  } catch (e) {
    yield put(CegActions.createCegFail());
  }
}

function* updateCeg({ payload }) {
  try {
    const { status } = yield call(api.put, `/ceg/${payload.id}`, payload.data);
    if (status === 200) {
      yield put(CegActions.updateCegSuccess());
    } else {
      yield put(CegActions.updateCegFail());
    }
  } catch (e) {
    yield put(CegActions.updateCegFail());
  }
}

function* getCegWatcher() {
  yield takeLatest(CegTypes.GET_CEG_REQUEST, getCeg);
}

function* getCegListWatcher() {
  yield takeLatest(CegTypes.GET_CEG_LIST, getListCeg);
}

function* getIdCegWatcher() {
  yield takeLatest(CegTypes.GET_ID_CEG_REQUEST, getIdCeg);
}

function* getIdCegDetailsWatcher() {
  yield takeLatest(CegTypes.GET_ID_CEG_DETAILS_REQUEST, getIdCegDetails);
}

function* createCegWatcher() {
  yield takeLatest(CegTypes.CREATE_CEG_REQUEST, createCeg);
}

function* updateCegWatcher() {
  yield takeLatest(CegTypes.UPDATE_CEG_REQUEST, updateCeg);
}

function* deleteCegWatcher() {
  yield takeLatest(CegTypes.DELETE_CEG_REQUEST, deleteCeg);
}

export default function* rootSaga() {
  yield all([
    fork(getCegWatcher),
    fork(getCegListWatcher),
    fork(getIdCegWatcher),
    fork(getIdCegDetailsWatcher),
    fork(createCegWatcher),
    fork(updateCegWatcher),
    fork(deleteCegWatcher),
  ]);
}
