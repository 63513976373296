import React, { useEffect, useState } from 'react';
import URLSearchParams from 'url-search-params';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';
import 'assets/vendors/style';
import RTL from 'util/RTL';
import AppLocale from '../lngProvider';
import { Creators as SettingActions } from '../store/ducks/Setting';
import AppLayout from './AppLayout';
import { Creators as AuthActions } from '../store/ducks/Auth';
import { getToken } from '../util';
import AuthRoute from './AuthRoute';
import LoadingPage from './LoadingPage';

const RestrictedRoute = ({ component: Component, token, ...rest }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [hasToken, setHasToken] = useState(false);
  const { isLogged, typeUser } = useSelector(({ auth }) => auth);

  useEffect(() => {
    const accessToken = getToken();
    const refreshToken = localStorage.getItem('TOTUM.REFRESH_TOKEN');
    const storageTypeUser = sessionStorage.getItem('TOTUM.TYPE_USER');

    if (!typeUser || (typeUser !== storageTypeUser && refreshToken)) {
      dispatch(AuthActions.login({ refreshToken }));
    }

    if (!accessToken) {
      if (!refreshToken) {
        history.push('/signin');
      } else {
        dispatch(AuthActions.login({ refreshToken }));
      }
    } else {
      setTimeout(() => setHasToken(true), 1000);
    }
  }, [location, isLogged, typeUser]);

  if (hasToken) {
    return <Route {...rest} component={props => <Component {...props} />} />;
  }
  return <LoadingPage />;
};

const App = props => {
  const dispatch = useDispatch();
  const { locale } = useSelector(({ settings }) => settings);
  const { initURL } = useSelector(({ auth }) => auth);
  const typeUser = sessionStorage.getItem('TOTUM.TYPE_USER');
  const { match } = props;

  useEffect(() => {
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
    if (initURL === '') {
      dispatch(AuthActions.setInitUrl(props.history.location.pathname));
    }
    const params = new URLSearchParams(props.location.search);
    if (params.has('theme-name')) {
      dispatch(SettingActions.setThemeColor(params.get('theme-name')));
    }
    if (params.has('dark-theme')) {
      dispatch(SettingActions.setDarkTheme());
    }
  }, [
    dispatch,
    initURL,
    props.history.location.pathname,
    props.location.search,
  ]);

  const currentAppLocale = AppLocale[locale.locale];
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <RTL>
          <div className="app-main">
            <Switch>
              {typeUser === 'admin' && (
                <RestrictedRoute
                  path={`${match.url}backoffice`}
                  component={AppLayout}
                />
              )}
              {(typeUser === 'client' ||
                typeUser === 'client_admin' ||
                typeUser === 'client_financial' ||
                typeUser === 'client_admin_participant') && (
                <RestrictedRoute
                  path={`${match.url}users`}
                  component={AppLayout}
                />
              )}
              <AuthRoute />
            </Switch>
          </div>
        </RTL>
      </IntlProvider>
    </MuiPickersUtilsProvider>
  );
};

export default App;
