import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
  Creators as RecoverPasswordActions,
  Types as RecoverPasswordTypes,
} from 'store/ducks/RecoverPassword';
import api from '../../util/Api';

function* sendEmail({ payload }) {
  try {
    const { status, data } = yield call(api.post, '/recoverPassword', payload);
    if (status === 200) {
      yield put(RecoverPasswordActions.sendEmailSuccess(data));
    }
  } catch (error) {
    yield put(RecoverPasswordActions.sendEmailError(error.response.data));
  }
}

function* sendEmailWatcher() {
  yield takeLatest(RecoverPasswordTypes.SEND_EMAIL, sendEmail);
}

function* newPassword({ payload }) {
  try {
    const { status, data } = yield call(
      api.post,
      '/recoverPassword/newPassword',
      payload
    );
    if (status === 200) {
      yield put(RecoverPasswordActions.newPasswordSuccess(data));
    }
  } catch (error) {
    yield put(RecoverPasswordActions.newPasswordError(error.response.data));
  }
}

function* newPasswordWatcher() {
  yield takeLatest(RecoverPasswordTypes.NEW_PASSWORD, newPassword);
}

export default function* rootSaga() {
  yield all([fork(sendEmailWatcher), fork(newPasswordWatcher)]);
}
