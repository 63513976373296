import { DARK_INDIGO } from '../../constants/ThemeColors';

export const Types = {
  CHANGE_DIRECTION: 'CHANGE_DIRECTION',
  CHANGE_NAVIGATION_STYLE: 'CHANGE_NAVIGATION_STYLE',
  DARK_THEME: 'DARK_THEME',
  DRAWER_TYPE: 'DRAWER_TYPE',
  FIXED_DRAWER: 'FIXED_DRAWER',
  HORIZONTAL_MENU_POSITION: 'HORIZONTAL_MENU_POSITION',
  INSIDE_THE_HEADER: 'INSIDE_THE_HEADER',
  SWITCH_LANGUAGE: 'SWITCH_LANGUAGE',
  THEME_COLOR: 'THEME_COLOR',
  VERTICAL_NAVIGATION: 'VERTICAL_NAVIGATION',
  WINDOW_WIDTH: 'WINDOW_WIDTH',
  TOGGLE_COLLAPSED_NAV: 'TOGGLE_COLLAPSED_NAV',
};

const rltLocale = ['ar'];
const initialSettings = {
  drawerType: 'collapsed_drawer',
  themeColor: DARK_INDIGO,
  darkTheme: false,
  width: window.innerWidth,
  isDirectionRTL: false,
  navigationStyle: 'vertical_navigation',
  horizontalNavPosition: 'below_the_header',
  locale: {
    languageId: 'portuguese',
    locale: 'pt',
    name: 'Portugues',
    icon: 'br',
  },
  navCollapsed: true,
};

export default function Settings(state = initialSettings, action) {
  switch (action.type) {
    case Types.DRAWER_TYPE:
      return {
        ...state,
        drawerType: action.drawerType,
      };
    case Types.WINDOW_WIDTH:
      return {
        ...state,
        width: action.width,
      };
    case Types.THEME_COLOR:
      return {
        ...state,
        darkTheme: false,
        themeColor: action.color,
      };
    case Types.DARK_THEME:
      return {
        ...state,
        darkTheme: !state.darkTheme,
      };
    case Types.SWITCH_LANGUAGE:
      return {
        ...state,
        locale: action.payload,
        isDirectionRTL: rltLocale.includes(action.payload.locale),
      };
    case Types.CHANGE_DIRECTION:
      return {
        ...state,
        isDirectionRTL: !state.isDirectionRTL,
      };
    case Types.CHANGE_NAVIGATION_STYLE:
      return {
        ...state,
        navigationStyle: action.payload,
      };
    case Types.HORIZONTAL_MENU_POSITION:
      return {
        ...state,
        horizontalNavPosition: action.payload,
      };
    case Types.TOGGLE_COLLAPSED_NAV:
      return {
        ...state,
        navCollapsed: action?.payload?.nav,
        drawerType: action?.payload?.draw,
      };
    default:
      return state;
  }
}

export const Creators = {
  toggleCollapsedNav: isNavCollapsed => ({
    type: Types.TOGGLE_COLLAPSED_NAV,
    payload: isNavCollapsed,
  }),
  setDrawerType: drawerType => ({
    type: Types.DRAWER_TYPE,
    drawerType,
  }),
  updateWindowWidth: width => ({
    type: Types.WINDOW_WIDTH,
    width,
  }),
  setThemeColor: color => ({
    type: Types.THEME_COLOR,
    color,
  }),
  setDarkTheme: () => ({
    type: Types.DARK_THEME,
  }),
  changeDirection: () => ({
    type: Types.CHANGE_DIRECTION,
  }),
  changeNavigationStyle: layoutType => ({
    type: Types.CHANGE_NAVIGATION_STYLE,
    payload: layoutType,
  }),
  setHorizontalMenuPosition: navigationPosition => ({
    type: Types.HORIZONTAL_MENU_POSITION,
    payload: navigationPosition,
  }),
  switchLanguage: locale => ({
    type: Types.SWITCH_LANGUAGE,
    payload: locale,
  }),
};
