import React from 'react';
import CustomScrollbars from 'util/CustomScrollbars';
import Navigation from '../../components/Navigation';

const SideBarContent = ({ typeUser, registrantId }) => {
  const type = typeUser === 'admin' ? 'backoffice' : 'users';
  const navigationMenusAdmin = [
    {
      name: ' ',
      type: 'section',
      children: [
        {
          name: 'sidebar.dashboard',
          icon: 'view-dashboard',
          type: 'item',
          link: `/${type}/dashboard`,
        },
        {
          name: 'sidebar.admins',
          icon: 'account',
          type: 'item',
          link: `/${type}/admin/list`,
        },
        {
          name: 'sidebar.users',
          icon: 'account-o',
          type: 'item',
          link: `/${type}/user/list`,
        },
        {
          name: 'sidebar.ccee',
          icon: 'upload',
          type: 'collapse',
          children: [
            {
              name: 'sidebar.ccee.import',
              type: 'item',
              link: `/${type}/ccee/import`,
            },
            {
              name: 'sidebar.ccee.recs',
              type: 'item',
              link: `/${type}/ccee/recs`,
            },
            {
              name: 'sidebar.ccee.permonth',
              type: 'item',
              link: `/${type}/ccee/month`,
            },
          ],
        },
        {
          name: 'sidebar.apiIntegration',
          icon: 'download',
          type: 'item',
          link: `/${type}/apiintegration/list`,
        },
        {
          name: 'sidebar.reports.clearing',
          icon: 'file',
          type: 'collapse',
          children: [
            {
              name: 'sidebar.reports.normalEmissionAdjustment',
              type: 'item',
              link: `/${type}/report/normal_emission_adjustment`,
            },
            {
              name: 'sidebar.reports.selfconsumptionEmissionAdjustment',
              type: 'item',
              link: `/${type}/report/self_consumption_emission_adjustment`,
            },
            {
              name: 'sidebar.reports.rightIrec',
              type: 'item',
              link: `/${type}/report/right_irec`,
            },
          ],
        },
        {
          name: 'sidebar.reports',
          icon: 'file',
          type: 'collapse',
          children: [
            {
              name: 'sidebar.reports.financial',
              type: 'item',
              link: `/${type}/report/financial`,
            },
            {
              name: 'sidebar.reports.nf',
              type: 'item',
              link: `/${type}/report/nf`,
            },
            {
              name: 'sidebar.reports.emission',
              type: 'item',
              link: `/${type}/report/emission`,
            },
            {
              name: 'sidebar.reports.registrant',
              type: 'item',
              link: `/${type}/report/enterprise`,
            },
            {
              name: 'sidebar.reports.registrantContact',
              type: 'item',
              link: `/${type}/report/registrant_contact`,
            },
            {
              name: 'sidebar.reports.emissionRevision',
              type: 'item',
              link: `/${type}/report/emission_revision`,
            },
            {
              name: 'sidebar.reports.user',
              type: 'item',
              link: `/${type}/report/user`,
            },
            {
              name: 'sidebar.reports.enterprise',
              type: 'item',
              link: `/${type}/report/business`,
            },
            {
              name: 'sidebar.reports.consolidation',
              type: 'item',
              link: `/${type}/report/consolidation`,
            },
            {
              name: 'sidebar.reports.redemptions',
              type: 'item',
              link: `/${type}/report/redemption`,
            },
            {
              name: 'sidebar.reports.certificateHistory',
              type: 'item',
              link: `/${type}/report/certificatehistory`,
            },
          ],
        },
        {
          name: 'sidebar.emissionsRequests',
          icon: 'thumb-up-down',
          type: 'item',
          link: `/${type}/rec/request`,
        },
        {
          name: 'sidebar.emissionsApproved',
          icon: 'thumb-up',
          type: 'item',
          link: `/${type}/rec/approved`,
        },
        {
          name: 'sidebar.financial',
          icon: 'money',
          type: 'item',
          link: `/${type}/nf/list`,
        },
        {
          name: 'sidebar.financialRedemption',
          icon: 'money',
          type: 'item',
          link: `/${type}/nfredemption/list`,
        },
        {
          name: 'sidebar.mixResidual',
          icon: 'assignment-o',
          type: 'item',
          link: `/${type}/rmd/list`,
        },
        {
          name: 'sidebar.cegCodes',
          icon: 'more',
          type: 'item',
          link: `/${type}/cegcodes/list`,
        },
        {
          name: 'sidebar.registrants',
          icon: 'accounts',
          type: 'item',
          link: `/${type}/registrants/list`,
        },
        {
          name: 'sidebar.enterprises',
          icon: 'city',
          type: 'item',
          link: `/${type}/enterprises/list`,
        },
        {
          name: 'sidebar.participants',
          icon: 'accounts-outline',
          type: 'item',
          link: `/${type}/participant/list`,
        },
        {
          name: 'sidebar.energyFont',
          icon: 'input-power',
          type: 'item',
          link: `/${type}/powerSupply/list`,
        },
        {
          name: 'sidebar.tecGeneration',
          icon: 'memory',
          type: 'item',
          link: `/${type}/generationTechnology/list`,
        },
        {
          name: 'sidebar.historyOfImports',
          icon: 'time-restore',
          type: 'item',
          link: `/${type}/imports_history`,
        },
        {
          name: 'sidebar.registerRequests',
          icon: 'assignment',
          type: 'item',
          link: `/${type}/register_requests`,
        },
        {
          name: 'sidebar.configs',
          icon: 'settings',
          type: 'item',
          link: `/${type}/configs/list`,
        },
        {
          name: 'sidebar.tutorials',
          icon: 'help',
          type: 'item',
          link: `/${type}/tutorials/list`,
        },
      ],
    },
  ];
  const navigationMenusCustomer = [
    {
      name: ' ',
      type: 'section',
      children: [
        {
          name: 'sidebar.dashboard',
          icon: 'view-dashboard',
          type: 'item',
          link: `/${type}/dashboard`,
        },
        {
          name: 'sidebar.enterprises',
          icon: 'city',
          type: 'item',
          link: `/${type}/enterprises/list`,
        },
        {
          name: 'sidebar.financial',
          icon: 'money',
          type: 'item',
          link: `/${type}/nf/list`,
        },
        {
          name: 'sidebar.registeredData',
          icon: 'account-box',
          type: 'item',
          link: `/${type}/registrant`,
        },
        {
          name: 'sidebar.users',
          icon: 'account-o',
          type: 'item',
          link: `/${type}/users/list`,
        },
        {
          name: 'sidebar.emissionRecs',
          icon: 'file-text',
          type: 'item',
          link: `/${type}/rec`,
        },
        {
          name: 'sidebar.registerEnterprise',
          icon: 'collection-plus',
          type: 'item',
          link: `/${type}/register_enterprise`,
        },
        {
          name: 'sidebar.connectionWithParticipant',
          icon: 'file',
          type: 'collapse',
          children: [
            {
              name: 'sidebar.connectionWithParticipant.config',
              type: 'item',
              link: `/${type}/connection_with_participant/register?id=${registrantId}`,
            },
            {
              name: 'sidebar.connectionWithParticipant.list',
              type: 'item',
              link: `/${type}/connection_with_participant/list`,
            },
          ],
        },
        {
          name: 'sidebar.reports',
          icon: 'file',
          type: 'collapse',
          children: [
            {
              name: 'sidebar.reports.emission',
              type: 'item',
              link: `/${type}/report/emission`,
            },
            {
              name: 'sidebar.reports.certificateHistory',
              type: 'item',
              link: `/${type}/report/certificatehistory`,
            },
            {
              name: 'sidebar.reports.nf',
              type: 'item',
              link: `/${type}/report/nf`
            },
          ],
        },
        {
          name: 'sidebar.tutorials',
          icon: 'help',
          type: 'item',
          link: `/${type}/tutorials/list`,
        },
      ],
    },
  ];

  const navigationMenusParticipant = [
    {
      name: ' ',
      type: 'section',
      children: [
        {
          name: 'sidebar.financial',
          icon: 'money',
          type: 'item',
          link: `/${type}/nf/participant/list`,
        },
        {
          name: 'sidebar.retirement',
          icon: 'account-box',
          type: 'item',
          link: `/${type}/retirement/list`,
        },
        {
          name: 'sidebar.beneficiary',
          icon: 'city',
          type: 'item',
          link: `/${type}/beneficiary/list`,
        },
        {
          name: 'sidebar.redemption',
          icon: 'city',
          type: 'item',
          link: `/${type}/redemption/list`,
        },
        {
          name: 'sidebar.transfer',
          icon: 'city',
          type: 'item',
          link: `/${type}/transfer/list`,
        },
        {
          name: 'sidebar.certificates',
          icon: 'city',
          type: 'item',
          link: `/${type}/certificates/list`,
        },
        {
          name: 'sidebar.beneficiaryConsumption',
          icon: 'city',
          type: 'collapse',
          children: [
            {
              name: 'sidebar.beneficiaryConsumption.import',
              type: 'item',
              link: `/${type}/beneficiary_consumption/import`,
            },
            {
              name: 'sidebar.beneficiaryConsumption.list',
              type: 'item',
              link: `/${type}/beneficiary_consumption/list`,
            },
          ],
        },
        {
          name: 'sidebar.connectionWithRegistrant',
          icon: 'city',
          type: 'item',
          link: `/${type}/connection_with_registrant/list`,
        },
        {
          name: 'sidebar.automation',
          icon: 'city',
          type: 'item',
          link: `/${type}/automation/list`,
        },
      ],
    },
  ];

  if(typeUser === 'admin'){
    return (
      <CustomScrollbars className=" scrollbar">
        <Navigation
          menuItems={navigationMenusAdmin}
        />
      </CustomScrollbars>

    );
  };

  if(typeUser === 'client' || typeUser === 'client_admin' || typeUser === 'client_financial') {
    return (
      <CustomScrollbars className=" scrollbar">
        <Navigation
          menuItems={navigationMenusCustomer}
        />
      </CustomScrollbars>

    );
  };

  if(typeUser === 'client_admin_participant'){
    return (
      <CustomScrollbars className=" scrollbar">
        <Navigation
          menuItems={navigationMenusParticipant}
        />
      </CustomScrollbars>

    );
  }
};

export default SideBarContent;
