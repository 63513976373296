import moment from 'moment';

const getToken = () => sessionStorage.getItem('TOTUM.ACCESS_TOKEN');

const removeTokens = () => {
  sessionStorage.removeItem('TOTUM.ACCESS_TOKEN');
  localStorage.removeItem('TOTUM.REFRESH_TOKEN');
};

const removeTypeUser = () => {
  sessionStorage.removeItem('TOTUM.TYPE_USER');
};

const removeRegistrantId = () => {
  sessionStorage.removeItem('TOTUM.REGISTRANT_ID');
};

function useQuery(useLocation) {
  return new URLSearchParams(useLocation().search);
}

const formatNumber = (value, digits) => {
  if (value) {
    let numberString = Number(value).toFixed(digits || 6);
    numberString = numberString.toString().replace('.', ',');

    const decimal = numberString.split(',');

    const formattedValue = `${decimal[0].split(/(?=(?:...)*$)/).join('.')}${
      decimal?.[1] ? `,${decimal[1]}` : ''
    }`;

    return formattedValue;
  }
  return 0;
};

const formatNumberFloor = (value) => {
  if (value) {
    let numberString = Number(value).toFixed(6);
    numberString = numberString.toString().replace('.', ',');

    const decimal = numberString.split(',');

    if (decimal[1] > 0) {
      decimal[1] -= 1;
    }

    const formattedValue = `${decimal[0].split(/(?=(?:...)*$)/).join('.')}${
      decimal?.[1] ? `,${decimal[1]}` : ''
    }`;

    return formattedValue;
  }
  return 0;
};

const checkDate = (e) => {
  const today = moment();
  return (
    moment(e, 'DD/MM/YYYY').isValid() &&
    !moment(e, 'DD/MM/YYYY').isAfter(today, 'DD/MM/YYYY')
  );
};

const handleText = (text, limit) => {
  if (text) {
    if (text?.length > limit) {
      const newText = text?.slice(0, limit);
      return `${newText?.charAt(0).toUpperCase() + newText?.slice(1)}...`;
    }
    return text?.charAt(0).toUpperCase() + text?.slice(1);
  }
  return null;
};

const replaceNumber = (search) => {
  if (!search) {
    return null;
  }
  const newSearch = search.replace('.', '');
  return Number(newSearch.replace(',', '.'));
};

const replaceDate = ({ search, searchFormat, bdFormat }) => {
  if (!search) {
    return null;
  }
  if (moment(search, searchFormat).isValid()) {
    return moment(search, searchFormat).format(bdFormat);
  }
  return search;
};

const replaceStatus = ({ search, options }) => {
  if (!search) {
    return null;
  }
  if (options[search.toLowerCase()]) {
    return options[search.toLowerCase()];
  }
  return null;
};

export {
  getToken,
  removeTokens,
  removeTypeUser,
  useQuery,
  formatNumber,
  checkDate,
  handleText,
  replaceNumber,
  replaceDate,
  replaceStatus,
  formatNumberFloor,
};
