export const Types = {
  GET_RETIREMENTS: 'GET_RETIREMENTS',
  GET_RETIREMENTS_SUCCESS: 'GET_RETIREMENTS_SUCCESS',
  GET_RETIREMENTS_ERROR: 'GET_RETIREMENTS_ERROR',
  POST_RETIREMENT: 'POST_RETIREMENT',
  POST_RETIREMENT_SUCCESS: 'POST_RETIREMENT_SUCCESS',
  POST_RETIREMENT_ERROR: 'POST_RETIREMENT_ERROR',
  GET_RETIREMENT_BY_ID: 'GET_RETIREMENT_BY_ID',
  GET_RETIREMENT_BY_ID_SUCCESS: 'GET_RETIREMENT_BY_ID_SUCCESS',
  GET_RETIREMENT_BY_ID_ERROR: 'GET_RETIREMENT_BY_ID_ERROR',
  PUT_RETIREMENT: 'PUT_RETIREMENT',
  PUT_RETIREMENT_SUCCESS: 'PUT_RETIREMENT_SUCCESS',
  PUT_RETIREMENT_ERROR: 'PUT_RETIREMENT_ERROR',
  DELETE_RETIREMENT: 'DELETE_RETIREMENT',
  DELETE_RETIREMENT_SUCCESS: 'DELETE_RETIREMENT_SUCCESS',
  DELETE_RETIREMENT_ERROR: 'DELETE_RETIREMENT_ERROR',
  APPROVE_RETIREMENT: 'APPROVE_RETIREMENT',
  APPROVE_RETIREMENT_SUCCESS: 'APPROVE_RETIREMENT_SUCCESS',
  APPROVE_RETIREMENT_ERROR: 'APPROVE_RETIREMENT_ERROR',
  APPROVE_RETIREMENT_RESET: 'APPROVE_RETIREMENT_RESET',
  REFUSE_RETIREMENT: 'REFUSE_RETIREMENT',
  REFUSE_RETIREMENT_SUCCESS: 'REFUSE_RETIREMENT_SUCCESS',
  REFUSE_RETIREMENT_ERROR: 'REFUSE_RETIREMENT_ERROR',
  REFUSE_RETIREMENT_RESET: 'REFUSE_RETIREMENT_RESET',
  RETIREMENT_CLEAN: 'RETIREMENT_CLEAN',

  EVIDENT_RETIREMENT_SYNC: 'EVIDENT_RETIREMENT_SYNC',
  EVIDENT_RETIREMENT_SYNC_SUCCESS: 'EVIDENT_RETIREMENT_SYNC_SUCCESS',
  EVIDENT_RETIREMENT_SYNC_ERROR: 'EVIDENT_RETIREMENT_SYNC_ERROR',
  EVIDENT_RETIREMENT_SYNC_RESET: 'EVIDENT_RETIREMENT_SYNC_RESET',
};

const INIT_STATE = {
  data: null,
  retirement: null,
  loading: false,
  error: null,
  postSuccess: false,
  deleteSuccess: false,

  approveLoading: false,
  approveSuccess: false,
  approveError: false,

  refuseLoading: false,
  refuseSuccess: false,
  refuseError: false,

  evidentRetirementSyncLoading: false,
  evidentRetirementSyncSuccess: false,
  evidentRetirementSyncError: false,
  evidentRetirementSyncReset: false,

  amount: 10,
};

const RETIREMENT_ERROR = {
  CEG_NOT_FOUND:
      'CEG não cadastrado na plataforma. Contate o administrador do sistema',
  CEG_HAS_ALREADY_BEEN_USED: 'CEG já cadastrado em outro empreendimento.',
};

export default function Beneficiary(state = INIT_STATE, action) {
  switch (action.type) {
    case Types.GET_RETIREMENTS:
      return {
        ...state,
        data: null,
        loading: true,
        error: null,
      };
    case Types.GET_RETIREMENTS_SUCCESS:
      return {
        ...state,
        data: action.payload.data || action.payload,
        amount: action.payload.total,
        loading: false,
        error: null,
      };
    case Types.GET_RETIREMENTS_ERROR:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.payload,
      };
    case Types.POST_RETIREMENT:
      return {
        ...state,
        postSuccess: false,
        loading: true,
        error: null,
      };
    case Types.POST_RETIREMENT_SUCCESS:
      return {
        ...state,
        postSuccess: true,
        loading: false,
        error: null,
      };
    case Types.POST_RETIREMENT_ERROR:
      return {
        ...state,
        postSuccess: false,
        loading: false,
        error:
            RETIREMENT_ERROR?.[action.payload?.message] ||
            action.payload?.message,
      };
    case Types.GET_RETIREMENT_BY_ID:
      return {
        ...state,
        retirement: null,
        loading: true,
        error: false,
      };
    case Types.GET_RETIREMENT_BY_ID_SUCCESS:
      return {
        ...state,
        retirement: action.payload,
        loading: false,
        error: false,
      };
    case Types.GET_RETIREMENT_BY_ID_ERROR:
      return {
        ...state,
        retirement: null,
        loading: false,
        error: action.payload,
      };
    case Types.PUT_RETIREMENT:
      return {
        ...state,
        postSuccess: false,
        loading: true,
        error: null,
      };
    case Types.PUT_RETIREMENT_SUCCESS:
      return {
        ...state,
        postSuccess: true,
        loading: false,
        error: null,
      };
    case Types.PUT_RETIREMENT_ERROR:
      return {
        ...state,
        postSuccess: false,
        loading: false,
        error: action.payload,
      };
    case Types.DELETE_RETIREMENT:
      return {
        ...state,
        deleteSuccess: false,
        loading: true,
        error: null,
      };
    case Types.DELETE_RETIREMENT_SUCCESS:
      return {
        ...state,
        deleteSuccess: true,
        loading: false,
        error: null,
      };
    case Types.DELETE_RETIREMENT_ERROR:
      return {
        ...state,
        deleteSuccess: false,
        loading: false,
        error: action.payload,
      };
    case Types.RETIREMENT_CLEAN:
      return {
        ...state,
        retirement: null,
        postSuccess: false,
        deleteSuccess: false,
        loading: false,
        error: false,
      };

    case Types.APPROVE_RETIREMENT:
      return {
        ...state,
        approveLoading: true,
        approveSuccess: false,
        approveError: false,
      };
    case Types.APPROVE_RETIREMENT_SUCCESS:
      return {
        ...state,
        approveLoading: false,
        approveSuccess: true,
        approveError: false,
      };
    case Types.APPROVE_RETIREMENT_ERROR:
      return {
        ...state,
        approveLoading: false,
        approveSuccess: false,
        approveError: action.payload,
      };
    case Types.APPROVE_RETIREMENT_RESET:
      return {
        ...state,
        approveLoading: false,
        approveSuccess: false,
        approveError: false,
      };

    case Types.REFUSE_RETIREMENT:
      return {
        ...state,
        refuseLoading: true,
        refuseSuccess: false,
        refuseError: false,
      };
    case Types.REFUSE_RETIREMENT_SUCCESS:
      return {
        ...state,
        refuseLoading: false,
        refuseSuccess: true,
        refuseError: false,
      };
    case Types.REFUSE_RETIREMENT_ERROR:
      return {
        ...state,
        refuseLoading: false,
        refuseSuccess: false,
        refuseError: true,
      };
    case Types.REFUSE_RETIREMENT_RESET:
      return {
        ...state,
        refuseLoading: false,
        refuseSuccess: false,
        refuseError: false,
      };

    case Types.EVIDENT_RETIREMENT_SYNC:
      return {
        ...state,
        evidentRetirementSyncLoading: true,
        evidentRetirementSyncSuccess: false,
        evidentRetirementSyncError: false,
      };
    case Types.EVIDENT_RETIREMENT_SYNC_SUCCESS:
      return {
        ...state,
        evidentRetirementSyncLoading: false,
        evidentRetirementSyncSuccess: true,
        evidentRetirementSyncError: false,
      };
    case Types.EVIDENT_RETIREMENT_SYNC_ERROR:
      return {
        ...state,
        evidentRetirementSyncLoading: false,
        evidentRetirementSyncSuccess: false,
        evidentRetirementSyncError: true,
      };
    case Types.EVIDENT_RETIREMENT_SYNC_RESET:
      return {
        ...state,
        evidentRetirementSyncLoading: false,
        evidentRetirementSyncSuccess: false,
        evidentRetirementSyncError: false,
      };

    default:
      return state;
  }
}

export const Creators = {
  getRetirements: payload => ({
    type: Types.GET_RETIREMENTS,
    payload,
  }),
  getRetirementsSuccess: payload => ({
    type: Types.GET_RETIREMENTS_SUCCESS,
    payload,
  }),
  getRetirementsError: payload => ({
    type: Types.GET_RETIREMENTS_ERROR,
    payload,
  }),
  postRetirement: payload => ({
    type: Types.POST_RETIREMENT,
    payload,
  }),
  postRetirementSuccess: payload => ({
    type: Types.POST_RETIREMENT_SUCCESS,
    payload,
  }),
  postRetirementError: payload => ({
    type: Types.POST_RETIREMENT_ERROR,
    payload,
  }),
  getRetirementById: payload => ({
    type: Types.GET_RETIREMENT_BY_ID,
    payload,
  }),
  getRetirementByIdSuccess: payload => ({
    type: Types.GET_RETIREMENT_BY_ID_SUCCESS,
    payload,
  }),
  getRetirementByIdError: payload => ({
    type: Types.GET_RETIREMENT_BY_ID_ERROR,
    payload,
  }),
  putRetirement: payload => ({
    type: Types.PUT_RETIREMENT,
    payload,
  }),
  putRetirementSuccess: payload => ({
    type: Types.PUT_RETIREMENT_SUCCESS,
    payload,
  }),
  putRetirementError: payload => ({
    type: Types.PUT_RETIREMENT_ERROR,
    payload,
  }),
  deleteRetirement: payload => ({
    type: Types.DELETE_RETIREMENT,
    payload,
  }),
  deleteRetirementSuccess: payload => ({
    type: Types.DELETE_RETIREMENT_SUCCESS,
    payload,
  }),
  deleteRetirementError: payload => ({
    type: Types.DELETE_RETIREMENT_ERROR,
    payload,
  }),
  RetirementClean: () => ({
    type: Types.RETIREMENT_CLEAN,
  }),
  approveRetirement: payload => ({
    type: Types.APPROVE_RETIREMENT,
    payload,
  }),
  approveRetirementSuccess: () => ({
    type: Types.APPROVE_RETIREMENT_SUCCESS,
  }),
  approveRetirementError: payload => ({
    type: Types.APPROVE_RETIREMENT_ERROR,
    payload,
  }),
  approveRetirementReset: () => ({
    type: Types.APPROVE_RETIREMENT_RESET,
  }),
  refuseRetirement: payload => ({
    type: Types.REFUSE_RETIREMENT,
    payload,
  }),
  refuseRetirementSuccess: () => ({
    type: Types.REFUSE_RETIREMENT_SUCCESS,
  }),
  refuseRetirementError: () => ({
    type: Types.REFUSE_RETIREMENT_ERROR,
  }),
  refuseRetirementReset: () => ({
    type: Types.REFUSE_RETIREMENT_RESET,
  }),

  evidentRetirementSync: (payload) => ({
    type: Types.EVIDENT_RETIREMENT_SYNC,
    payload,
  }),
  evidentRetirementSyncSuccess: () => ({
    type: Types.EVIDENT_RETIREMENT_SYNC_SUCCESS,
  }),
  evidentRetirementSyncError: () => ({
    type: Types.EVIDENT_RETIREMENT_SYNC_ERROR,
  }),
  evidentRetirementSyncReset: () => ({
    type: Types.EVIDENT_RETIREMENT_SYNC_RESET,
  }),
};
