import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import {
  Types as NotaFiscalRedemptionTypes,
  Creators as NotaFiscalRedemptionActions,
} from '../ducks/NotaFiscalRedemption';
import api from '../../util/Api';

function* getNotasRedemption({ payload }) {
  try {
    const { status, data } = yield call(api.get, '/invoicesredemptions', {
      params: payload,
    });
    if (status === 200) {
      yield put(NotaFiscalRedemptionActions.getNotasRedemptionSuccess(data));
    } else {
      yield put(NotaFiscalRedemptionActions.getNotasRedemptionError());
    }
  } catch (e) {
    yield put(NotaFiscalRedemptionActions.getNotasRedemptionError());
  }
}

function* putNotasRedemption({ payload }) {
  const { id, data } = payload;
  try {
    const { status } = yield call(api.put, `/invoicesredemptions/${id}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (status === 200) {
      yield put(NotaFiscalRedemptionActions.putNotasRedemptionSuccess());
    } else {
      yield put(NotaFiscalRedemptionActions.putNotasRedemptionError());
    }
  } catch (e) {
    yield put(NotaFiscalRedemptionActions.putNotasRedemptionError());
  }
}

function* cloneNotasRedemption({ payload }) {
  const { id } = payload;
  try {
    const { status } = yield call(api.post, `/invoicesredemptions/clone/${id}`, payload);
    if (status === 200) {
      yield put(NotaFiscalRedemptionActions.cloneNotasRedemptionSuccess());
    } else {
      yield put(NotaFiscalRedemptionActions.cloneNotasRedemptionError());
    }
  } catch (e) {
    yield put(NotaFiscalRedemptionActions.cloneNotasRedemptionError());
  }
}

function* paidNotasRedemption({ payload }) {
  try {
    const { status } = yield call(api.post, `/invoicesredemptions/toPay/${payload}`);
    if (status === 200) {
      yield put(NotaFiscalRedemptionActions.paidNFRedemptionSuccess(status));
    } else {
      yield put(NotaFiscalRedemptionActions.paidNFRedemptionError());
    }
  } catch (e) {
    yield put(NotaFiscalRedemptionActions.paidNFRedemptionError());
  }
}

function* paidNotasRedemptionByNumber({ payload }) {
  try {
    const { status } = yield call(
      api.post,
      `/invoicesredemptions/toPayByNumber/${payload}`
    );
    if (status === 200) {
      yield put(NotaFiscalRedemptionActions.paidNFRedemptionByNumberSuccess());
    } else {
      yield put(NotaFiscalRedemptionActions.paidNFRedemptionByNumberError());
    }
  } catch (e) {
    yield put(NotaFiscalRedemptionActions.paidNFRedemptionByNumberError());
  }
}

function* getNotaRedemptionById({ payload }) {
  const { id } = payload;
  try {
    const { status, data } = yield call(api.get, `/invoicesredemptions/${id}`);
    if (status === 200) {
      yield put(NotaFiscalRedemptionActions.getNotaRedemptionByIdSuccess(data));
    } else {
      yield put(NotaFiscalRedemptionActions.getNotaRedemptionByIdError());
    }
  } catch {
    yield put(NotaFiscalRedemptionActions.getNotaRedemptionByIdError());
  }
}

function* receiptUploadRedemption({ payload }) {
  const { id, data } = payload;
  try {
    const { status } = yield call(api.post, `/invoicesredemptions/receipts/${id}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (status === 200) {
      yield put(NotaFiscalRedemptionActions.receiptUploadRedemptionSuccess());
    } else {
      yield put(NotaFiscalRedemptionActions.receiptUploadRedemptionError());
    }
  } catch (error) {
    yield put(NotaFiscalRedemptionActions.receiptUploadRedemptionError(error.response));
  }
}

function* getNotasRedemptionWatcher() {
  yield takeLatest(NotaFiscalRedemptionTypes.GET_NOTAS_REDEMPTION, getNotasRedemption);
}

function* putNotasRedemptionWatcher() {
  yield takeLatest(NotaFiscalRedemptionTypes.PUT_NOTAS_REDEMPTION, putNotasRedemption);
}

function* cloneNotasRedemptionWatcher() {
  yield takeLatest(NotaFiscalRedemptionTypes.CLONE_NOTAS_REDEMPTION, cloneNotasRedemption);
}

function* paidNotasRedemptionWatcher() {
  yield takeLatest(NotaFiscalRedemptionTypes.PAID_NOTAS_REDEMPTION, paidNotasRedemption);
}

function* paidNotasRedemptionByNumberWatcher() {
  yield takeLatest(NotaFiscalRedemptionTypes.PAID_NOTAS_REDEMPTION_BY_NUMBER, paidNotasRedemptionByNumber);
}

function* getNotaRedemptionByIdWatcher() {
  yield takeLatest(NotaFiscalRedemptionTypes.GET_NOTA_REDEMPTION_BY_ID, getNotaRedemptionById);
}

function* receiptUploadRedemptionWatcher() {
  yield takeLatest(NotaFiscalRedemptionTypes.RECEIPT_UPLOAD_REDEMPTION, receiptUploadRedemption);
}

export default function* rootSaga() {
  yield all([
    fork(getNotasRedemptionWatcher),
    fork(putNotasRedemptionWatcher),
    fork(cloneNotasRedemptionWatcher),
    fork(paidNotasRedemptionWatcher),
    fork(paidNotasRedemptionByNumberWatcher),
    fork(getNotaRedemptionByIdWatcher),
    fork(receiptUploadRedemptionWatcher),
  ]);
}
