import { all, call, put, takeLatest, fork } from 'redux-saga/effects';
import {
  Creators as RedemptionActions,
  Types as RedemptionTypes,
} from 'store/ducks/Redemption';
import api from '../../util/Api';

function* getRedemptions({ payload }) {
  try {
    const { status, data } = yield call(api.get, '/redemptions', {
      params: payload,
    });
    if (status === 200) {
      yield put(RedemptionActions.getRedemptionsSuccess(data));
    }
  } catch (error) {
    yield put(
        RedemptionActions.getRedemptionsError('Erro ao listar aposentadorias')
    );
  }
}

function* postRedemption({ payload }) {
  try {
    const { status, data } = yield call(api.post, '/redemptions', payload.data);

    if (status === 200) {
      yield put(RedemptionActions.postRedemptionSuccess());
    }
  } catch (error) {
    yield put(RedemptionActions.postRedemptionError(error?.response?.data));
  }
}

function* getRedemptionById({ payload }) {
  const { id, params, edit } = payload;
  try {
    const { status, data } = yield call(api.get, `/redemptions/${id}`, {
      params,
    });
    if (status === 200) {
      yield put(RedemptionActions.getRedemptionByIdSuccess(data));
    }
  } catch (error) {
    yield put(
        RedemptionActions.getRedemptionByIdError('Aposentadorís não encontrado')
    );
  }
}

function* putRedemption({ payload }) {
  const { id, data: payloadData } = payload;
  try {
    const { status } = yield call(api.put, `/redemptions/${id}`, payloadData);
    if (status === 200) {
      yield put(RedemptionActions.putRedemptionSuccess());
    }
  } catch (error) {
    yield put(
        RedemptionActions.putRedemptionError('Erro ao editar Aposentadoría')
    );
  }
}

function* deleteRedemption({ payload }) {
  try {
    const { status } = yield call(api.delete, `/redemptions/${payload}`);
    if (status === 200) {
      yield put(RedemptionActions.deleteRedemptionSuccess());
    }
  } catch (error) {
    yield put(
        RedemptionActions.deleteRedemptionError('Erro ao excluir Aposentadoría')
    );
  }
}

function* getIrecItems({ payload }) {
  try {
    // const { code } = payload;
    const { status, data } = yield call(api.get, `/redemptions/get-items`, {
      params: payload,
    });
    if (status === 200) {
      yield put(RedemptionActions.getIrecItemsSuccess(data));
    }
  } catch (error) {
    yield put(
        RedemptionActions.getIrecItemsError('Erro ao listar items do IREC')
    );
  }
}

// function* getIrecItems({ payload }) {
//   const { code, params } = payload;
//   try {
//     const { status, data } = yield call(api.get, `/redemptions/get-items/${code}`, {
//       params: payload,
//     });
//     // const { status, data } = yield call(api.get, '/redemptions/get-items', {
//     //   params: payload,
//     // });
//     if (status === 200) {
//       yield put(RedemptionActions.getIrecItemsSuccess(data));
//     }
//   } catch (error) {
//     yield put(
//         RedemptionActions.getIrecItemsError('Erro ao listar items do IREC')
//     );
//   }
// }

function* getRedemptionsWatcher() {
  yield takeLatest(RedemptionTypes.GET_REDEMPTIONS, getRedemptions);
}

function* postRedemptionWatcher() {
  yield takeLatest(RedemptionTypes.POST_REDEMPTION, postRedemption);
}

function* getRedemptionByIdWatcher() {
  yield takeLatest(RedemptionTypes.GET_REDEMPTION_BY_ID, getRedemptionById);
}

function* putRedemptionWatcher() {
  yield takeLatest(RedemptionTypes.PUT_REDEMPTION, putRedemption);
}

function* deleteRedemptionWatcher() {
  yield takeLatest(RedemptionTypes.DELETE_REDEMPTION, deleteRedemption);
}

function* getIrecItemsWatcher() {
  yield takeLatest(RedemptionTypes.GET_IREC_ITEMS, getIrecItems);
}

export default function* rootSaga() {
  yield all([
    fork(getRedemptionsWatcher),
    fork(postRedemptionWatcher),
    fork(deleteRedemptionWatcher),
    fork(getRedemptionByIdWatcher),
    fork(putRedemptionWatcher),
    fork(deleteRedemptionWatcher),
    fork(getIrecItemsWatcher),
  ]);
}
