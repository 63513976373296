import { all, call, put, takeLatest, fork } from 'redux-saga/effects';
import {
  Creators as TechnologyActions,
  Types as TechnologyTypes,
} from 'store/ducks/Tecnology';
import api from '../../util/Api';

function* getTechnology({ payload }) {
  try {
    const { status, data } = yield call(api.get, '/technologies', {
      params: payload,
    });
    if (status === 200) {
      yield put(TechnologyActions.getTecnologyListSuccess(data));
    } else {
      yield put(TechnologyActions.getTecnologyListFail());
    }
  } catch (err) {
    yield put(TechnologyActions.getTecnologyListFail());
  }
}

function* deleteTechnology({ payload }) {
  try {
    const { status } = yield call(api.delete, `/technologies/${payload}`);
    if (status === 200) {
      yield put(TechnologyActions.deleteTechnologySuccess());
    } else {
      yield put(TechnologyActions.deleteTechnologyFail());
    }
  } catch (err) {
    yield put(TechnologyActions.deleteTechnologyFail());
  }
}

function* createTechnology({ payload }) {
  try {
    const { status } = yield call(api.post, '/technologies', payload);
    if (status === 200) {
      yield put(TechnologyActions.createTechnologySuccess());
    } else {
      yield put(TechnologyActions.createTechnologyFail());
    }
  } catch (err) {
    yield put(TechnologyActions.createTechnologyFail());
  }
}

function* getIdTechnology({ payload }) {
  try {
    const { status, data } = yield call(api.get, `/technologies/${payload}`);
    if (status === 200) {
      yield put(TechnologyActions.getIdTechnologySuccess(data));
    } else {
      yield put(TechnologyActions.getIdTechnologyFail());
    }
  } catch (err) {
    yield put(TechnologyActions.getIdTechnologyFail());
  }
}

function* updateTechnology({ payload }) {
  try {
    const { status } = yield call(
      api.put,
      `/technologies/${payload.id}`,
      payload.data
    );
    if (status === 200) {
      yield put(TechnologyActions.updateTechnologySuccess());
    } else {
      yield put(TechnologyActions.updateTechnologyFail());
    }
  } catch (err) {
    yield put(TechnologyActions.updateTechnologyFail());
  }
}

function* getTechnologyWatcher() {
  yield takeLatest(TechnologyTypes.GET_TECNOLOGY_LIST_REQUEST, getTechnology);
}

function* deleteTechnologyWatcher() {
  yield takeLatest(TechnologyTypes.DELETE_TECHNOLOGY_REQUEST, deleteTechnology);
}

function* createTechnologyWatcher() {
  yield takeLatest(TechnologyTypes.CREATE_TECHNOLOGY_REQUEST, createTechnology);
}

function* getIdTechnologyWatcher() {
  yield takeLatest(TechnologyTypes.GET_ID_TECHNOLOGY_REQUEST, getIdTechnology);
}

function* updateTechnologyWatcher() {
  yield takeLatest(TechnologyTypes.UPDATE_TECHNOLOGY_REQUEST, updateTechnology);
}

export default function* rootSaga() {
  yield all([
    fork(getTechnologyWatcher),
    fork(deleteTechnologyWatcher),
    fork(createTechnologyWatcher),
    fork(getIdTechnologyWatcher),
    fork(updateTechnologyWatcher),
  ]);
}
