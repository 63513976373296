import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IntlMessages from 'util/IntlMessages';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Typography } from '@material-ui/core';
import { Creators as RecoverPasswordActions } from '../store/ducks/RecoverPassword';
import CustomSnackbars from '../components/CustomSnackbars';

const schema = yup.object().shape({
  email: yup
    .string()
    .email('Digite um e-mail válido')
    .required('Digite seu e-mail'),
});

const RecoverEmail = props => {
  const dispatch = useDispatch();
  const { sentSuccess, error, loading } = useSelector(
    ({ recoverPassword }) => recoverPassword
  );
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [typeMessage, setTypeMessage] = useState('error');
  const [email, setEmail] = useState();

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = data => {
    setEmail(data.email);
    dispatch(RecoverPasswordActions.sendEmail(data));
  };

  useEffect(() => {
    if (error) {
      setTypeMessage('error');
      setMessage('Erro ao recuperar senha');
      setOpen(true);
    }
  }, [error]);

  useEffect(() => {
    if (sentSuccess) {
      setTypeMessage('success');
      setMessage(`Foi enviado um e-mail para ${email}`);
      setOpen(true);
    }
  }, [sentSuccess]);

  return (
    <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
      <div className="app-login-main-content">
        <div className="app-logo-content d-flex align-items-center justify-content-center">
          <Link className="logo-lg" to="/" title="Jambo">
            <img
              src={require('assets/images/fullLogo.svg')}
              alt="SISREC LEDGER"
              title="SISREC LEDGER"
            />
          </Link>
        </div>

        <div className="app-login-content">
          <div className="app-login-header mb-4">
            <Typography variant="h5">
              <IntlMessages id="appModule.Recover" />
            </Typography>
          </div>

          <div className="app-login-form">
            <form onSubmit={handleSubmit(onSubmit)}>
              <fieldset>
                <TextField
                  label={<IntlMessages id="appModule.email" />}
                  fullWidth
                  defaultValue=""
                  margin="normal"
                  className="mt-1 my-sm-3"
                  name="email"
                  inputRef={register}
                />
                <p style={{ color: 'red' }}>{errors.email?.message}</p>
                <p style={{ color: 'red' }}>{errors.password?.message}</p>
                <div className="mb-3 d-flex align-items-center justify-content-between">
                  <div>
                    <a href="/signin">voltar</a>
                  </div>
                  {loading ? (
                    <CircularProgress size={20} className="ml-4" />
                  ) : (
                    <Button type="submit" variant="contained" color="primary">
                      <IntlMessages id="appModule.RecoverSend" />
                    </Button>
                  )}
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
      <CustomSnackbars
        open={open}
        setOpen={setOpen}
        text={message}
        type={typeMessage}
      />
    </div>
  );
};

export default RecoverEmail;
