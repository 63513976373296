import { all, call, put, takeLatest, fork } from 'redux-saga/effects';
import {
  Creators as TransferActions,
  Types as TransferTypes,
} from 'store/ducks/Transfer';
import api from '../../util/Api';

function* getTransfers({ payload }) {
  try {
    const { status, data } = yield call(api.get, '/transfers', {
      params: payload,
    });
    if (status === 200) {
      yield put(TransferActions.getTransfersSuccess(data));
    }
  } catch (error) {
    yield put(
        TransferActions.getTransfersError('Erro ao listar aposentadorias')
    );
  }
}

function* postTransfer({ payload }) {
  try {
    const { status, data } = yield call(api.post, '/transfers', payload.data);

    if (status === 200) {
      if (data.status === true)
        yield put(TransferActions.postTransferSuccess());
      else
        yield put(TransferActions.postTransferError( data.data ));
    } else {
      yield put(TransferActions.postTransferError("Não foi possível realizar a transferência"));
    }
  } catch (error) {
    console.log(error);
    yield put(TransferActions.postTransferError("Não foi possível realizar a transferência"));
  }
}

function* getTransferById({ payload }) {
  const { id, params, edit } = payload;
  try {
    const { status, data } = yield call(api.get, `/transfers/${id}`, {
      params,
    });
    if (status === 200) {
      yield put(TransferActions.getTransferByIdSuccess(data));
    }
  } catch (error) {
    yield put(
        TransferActions.getTransferByIdError('Aposentadorís não encontrado')
    );
  }
}

function* putTransfer({ payload }) {
  const { id, data: payloadData } = payload;
  try {
    const { status } = yield call(api.put, `/transfers/${id}`, payloadData);
    if (status === 200) {
      yield put(TransferActions.putTransferSuccess());
    }
  } catch (error) {
    yield put(
        TransferActions.putTransferError('Erro ao editar Aposentadoría')
    );
  }
}

function* deleteTransfer({ payload }) {
  try {
    const { status } = yield call(api.delete, `/transfers/${payload}`);
    if (status === 200) {
      yield put(TransferActions.deleteTransferSuccess());
    }
  } catch (error) {
    yield put(
        TransferActions.deleteTransferError('Erro ao excluir Aposentadoría')
    );
  }
}

function* getTransfersWatcher() {
  yield takeLatest(TransferTypes.GET_TRANSFERS, getTransfers);
}

function* postTransferWatcher() {
  yield takeLatest(TransferTypes.POST_TRANSFER, postTransfer);
}

function* getTransferByIdWatcher() {
  yield takeLatest(TransferTypes.GET_TRANSFER_BY_ID, getTransferById);
}

function* putTransferWatcher() {
  yield takeLatest(TransferTypes.PUT_TRANSFER, putTransfer);
}

function* deleteTransferWatcher() {
  yield takeLatest(TransferTypes.DELETE_TRANSFER, deleteTransfer);
}

export default function* rootSaga() {
  yield all([
    fork(getTransfersWatcher),
    fork(postTransferWatcher),
    fork(deleteTransferWatcher),
    fork(getTransferByIdWatcher),
    fork(putTransferWatcher),
    fork(deleteTransferWatcher),
  ]);
}
