export const Types = {
  GET_CEP: 'GET_CEP',
  GET_CEP_SUCCESS: 'GET_CEP_SUCCESS',
  GET_CEP_ERROR: 'GET_CEP_ERROR',
  GET_CEP_RESET: 'GET_CEP_RESET',
};

const INIT_STATE = {
  loading: false,
  error: false,
  data: null,
};

export default function Cep(state = INIT_STATE, action) {
  switch (action.type) {
    case Types.GET_CEP: {
      return {
        ...state,
        loading: true,
        error: false,
        data: null,
      };
    }
    case Types.GET_CEP_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload,
      };
    }
    case Types.GET_CEP_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
        data: null,
      };
    }
    case Types.GET_CEP_RESET: {
      return {
        ...state,
        loading: false,
        error: false,
        data: null,
      };
    }
    default:
      return state;
  }
}

export const Creators = {
  getCep: payload => ({
    type: Types.GET_CEP,
    payload,
  }),
  getCepSuccess: payload => ({
    type: Types.GET_CEP_SUCCESS,
    payload,
  }),
  getCepError: () => ({
    type: Types.GET_CEP_ERROR,
  }),
  getCepReset: () => ({
    type: Types.GET_CEP_RESET,
  }),
};
