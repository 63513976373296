import { all, call, put, takeLatest, fork } from 'redux-saga/effects';
import {
  Creators as ParticipantRegistrantActions,
  Types as ParticipantRegistrantTypes,
} from 'store/ducks/ParticipantRegistrant';
import api from '../../util/Api';

function* getParticipantRegistrantListRequest({ payload }) {
  try {
    const { status, data } = yield call(api.get, `/participant_registrant`, {
      params: payload,
    });
    if (status === 200) {
      yield put(ParticipantRegistrantActions.getParticipantRegistrantListSuccess(data));
    } else {
      yield put(ParticipantRegistrantActions.getParticipantRegistrantListFail());
    }
  } catch (err) {
    yield put(ParticipantRegistrantActions.getParticipantRegistrantListFail());
  }
}

function* deleteParticipantRegistrant({ payload }) {
  try {
    const { status } = yield call(api.post, `/participant_registrant/destroy`, payload);
    if (status === 200) {
      yield put(ParticipantRegistrantActions.deleteParticipantRegistrantSuccess());
    } else {
      yield put(ParticipantRegistrantActions.deleteParticipantRegistrantFail());
    }
  } catch (err) {
    yield put(ParticipantRegistrantActions.deleteParticipantRegistrantFail());
  }
}

function* createParticipantRegistrant({ payload }) {
  try {
    const { status } = yield call(api.post, '/participant_registrant', payload);
    if (status === 200) {
      yield put(ParticipantRegistrantActions.createParticipantRegistrantSuccess());
    } else {
      yield put(ParticipantRegistrantActions.createParticipantRegistrantFail());
    }
  } catch (err) {
    yield put(ParticipantRegistrantActions.createParticipantRegistrantFail());
  }
}

function* updateParticipantRegistrantRequest({ payload }) {
  try {
    const { status } = yield call(api.put, '/participant_registrant', payload);
    if (status === 200) {
      yield put(ParticipantRegistrantActions.updateParticipantRegistrantSuccess());
    } else {
      yield put(ParticipantRegistrantActions.updateParticipantRegistrantFail());
    }
  } catch (err) {
    yield put(ParticipantRegistrantActions.updateParticipantRegistrantFail());
  }
}

function* getParticipantRegistrantListRequestWatcher() {
  yield takeLatest(ParticipantRegistrantTypes.GET_PARTICIPANT_REGISTRANT_LIST_REQUEST, getParticipantRegistrantListRequest);
}

function* deleteParticipantRegistrantWatcher() {
  yield takeLatest(ParticipantRegistrantTypes.DELETE_PARTICIPANT_REGISTRANT_REQUEST, deleteParticipantRegistrant);
}

function* createParticipantRegistrantWatcher() {
  yield takeLatest(ParticipantRegistrantTypes.CREATE_PARTICIPANT_REGISTRANT_REQUEST, createParticipantRegistrant);
}

function* updateParticipantRegistrantRequestWatcher() {
  yield takeLatest(ParticipantRegistrantTypes.UPDATE_PARTICIPANT_REGISTRANT_REQUEST, updateParticipantRegistrantRequest);
}

export default function* rootSaga() {
  yield all([
    fork(getParticipantRegistrantListRequestWatcher),
    fork(deleteParticipantRegistrantWatcher),
    fork(createParticipantRegistrantWatcher),
    fork(updateParticipantRegistrantRequestWatcher)
  ]);
}
