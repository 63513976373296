import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { Creators as FileActions, Types as FileTypes } from 'store/ducks/File';
import api from '../../util/Api';

function* exportFile({ payload }) {
  try {
    const { status, data } = yield call(api.post, '/exports', payload, {
      responseType: 'blob',
    });
    if (status === 200) {
      yield put(FileActions.exportFileSuccess(data));
    } else {
      yield put(FileActions.exportFileError());
    }
  } catch (err) {
    yield put(FileActions.exportFileError());
  }
}

function* importFile({ payload }) {
  try {
    const { status, data } = yield call(
      api.post,
      '/imports/generation',
      payload,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    if (status === 200) {
      yield put(FileActions.importFileSuccess(data));
    } else {
      yield put(FileActions.importFileError());
    }
  } catch (err) {
    yield put(FileActions.importFileError());
  }
}

function* importBeneficiaryConsumptionFile({ payload }) {
  try {
    const { status, data } = yield call(
      api.post,
      '/imports/generationbeneficiaryconsumption',
      payload,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    if (status === 200) {
      yield put(FileActions.importBeneficiaryConsumptionFileSuccess(data));
    } else {
      yield put(FileActions.importBeneficiaryConsumptionFileError());
    }
  } catch (err) {
    yield put(FileActions.importBeneficiaryConsumptionFileError());
  }
}

function* exportFileWatcher() {
  yield takeLatest(FileTypes.EXPORT_FILE_REQUEST, exportFile);
}

function* importBeneficiaryConsumptionFileWatcher() {
  yield takeLatest(FileTypes.IMPORT_BENEFICIARY_CONSUMPTION_FILE_REQUEST, importBeneficiaryConsumptionFile);
}

function* importFileWatcher() {
  yield takeLatest(FileTypes.IMPORT_FILE_REQUEST, importFile);
}

export default function* rootSaga() {
  yield all([
    fork(exportFileWatcher),
    fork(importFileWatcher),
    fork(importBeneficiaryConsumptionFileWatcher)
  ]);
}
