export const Types = {
  GET_TRANSFERS: 'GET_TRANSFERS',
  GET_TRANSFERS_SUCCESS: 'GET_TRANSFERS_SUCCESS',
  GET_TRANSFERS_ERROR: 'GET_TRANSFERS_ERROR',
  POST_TRANSFER: 'POST_TRANSFER',
  POST_TRANSFER_SUCCESS: 'POST_TRANSFER_SUCCESS',
  POST_TRANSFER_ERROR: 'POST_TRANSFER_ERROR',
  GET_TRANSFER_BY_ID: 'GET_TRANSFER_BY_ID',
  GET_TRANSFER_BY_ID_SUCCESS: 'GET_TRANSFER_BY_ID_SUCCESS',
  GET_TRANSFER_BY_ID_ERROR: 'GET_TRANSFER_BY_ID_ERROR',
  PUT_TRANSFER: 'PUT_TRANSFER',
  PUT_TRANSFER_SUCCESS: 'PUT_TRANSFER_SUCCESS',
  PUT_TRANSFER_ERROR: 'PUT_TRANSFER_ERROR',
  DELETE_TRANSFER: 'DELETE_TRANSFER',
  DELETE_TRANSFER_SUCCESS: 'DELETE_TRANSFER_SUCCESS',
  DELETE_TRANSFER_ERROR: 'DELETE_TRANSFER_ERROR',
  APPROVE_TRANSFER: 'APPROVE_TRANSFER',
  APPROVE_TRANSFER_SUCCESS: 'APPROVE_TRANSFER_SUCCESS',
  APPROVE_TRANSFER_ERROR: 'APPROVE_TRANSFER_ERROR',
  APPROVE_TRANSFER_RESET: 'APPROVE_TRANSFER_RESET',
  REFUSE_TRANSFER: 'REFUSE_TRANSFER',
  REFUSE_TRANSFER_SUCCESS: 'REFUSE_TRANSFER_SUCCESS',
  REFUSE_TRANSFER_ERROR: 'REFUSE_TRANSFER_ERROR',
  REFUSE_TRANSFER_RESET: 'REFUSE_TRANSFER_RESET',
  TRANSFER_CLEAN: 'TRANSFER_CLEAN',

};

const INIT_STATE = {
  data: null,
  transfer: null,
  loading: false,
  error: null,
  postSuccess: false,
  deleteSuccess: false,

  approveLoading: false,
  approveSuccess: false,
  approveError: false,

  refuseLoading: false,
  refuseSuccess: false,
  refuseError: false,

  amount: 10,
};

const TRANSFER_ERROR = {
  CEG_NOT_FOUND:
      'CEG não cadastrado na plataforma. Contate o administrador do sistema',
  CEG_HAS_ALREADY_BEEN_USED: 'CEG já cadastrado em outro empreendimento.',
};

export default function Transfer(state = INIT_STATE, action) {
  switch (action.type) {
    case Types.GET_TRANSFERS:
      return {
        ...state,
        data: null,
        loading: true,
        error: null,
      };
    case Types.GET_TRANSFERS_SUCCESS:
      return {
        ...state,
        data: action.payload.data || action.payload,
        amount: action.payload.total,
        loading: false,
        error: null,
      };
    case Types.GET_TRANSFERS_ERROR:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.payload,
      };
    case Types.POST_TRANSFER:
      return {
        ...state,
        postSuccess: false,
        loading: true,
        error: null,
      };
    case Types.POST_TRANSFER_SUCCESS:
      return {
        ...state,
        postSuccess: true,
        loading: false,
        error: null,
      };
    case Types.POST_TRANSFER_ERROR:
      return {
        ...state,
        postSuccess: false,
        loading: false,
        //error: action.payload?.message,
        error: action.payload,
      };
    case Types.GET_TRANSFER_BY_ID:
      return {
        ...state,
        transfer: null,
        loading: true,
        error: false,
      };
    case Types.GET_TRANSFER_BY_ID_SUCCESS:
      return {
        ...state,
        transfer: action.payload,
        loading: false,
        error: false,
      };
    case Types.GET_TRANSFER_BY_ID_ERROR:
      return {
        ...state,
        transfer: null,
        loading: false,
        error: action.payload,
      };
    case Types.PUT_TRANSFER:
      return {
        ...state,
        postSuccess: false,
        loading: true,
        error: null,
      };
    case Types.PUT_TRANSFER_SUCCESS:
      return {
        ...state,
        postSuccess: true,
        loading: false,
        error: null,
      };
    case Types.PUT_TRANSFER_ERROR:
      return {
        ...state,
        postSuccess: false,
        loading: false,
        error: action.payload,
      };
    case Types.DELETE_TRANSFER:
      return {
        ...state,
        deleteSuccess: false,
        loading: true,
        error: null,
      };
    case Types.DELETE_TRANSFER_SUCCESS:
      return {
        ...state,
        deleteSuccess: true,
        loading: false,
        error: null,
      };
    case Types.DELETE_TRANSFER_ERROR:
      return {
        ...state,
        deleteSuccess: false,
        loading: false,
        error: action.payload,
      };
    case Types.TRANSFER_CLEAN:
      return {
        ...state,
        transfer: null,
        postSuccess: false,
        deleteSuccess: false,
        loading: false,
        error: false,
      };

    case Types.APPROVE_TRANSFER:
      return {
        ...state,
        approveLoading: true,
        approveSuccess: false,
        approveError: false,
      };
    case Types.APPROVE_TRANSFER_SUCCESS:
      return {
        ...state,
        approveLoading: false,
        approveSuccess: true,
        approveError: false,
      };
    case Types.APPROVE_TRANSFER_ERROR:
      return {
        ...state,
        approveLoading: false,
        approveSuccess: false,
        approveError: action.payload,
      };
    case Types.APPROVE_TRANSFER_RESET:
      return {
        ...state,
        approveLoading: false,
        approveSuccess: false,
        approveError: false,
      };

    case Types.REFUSE_TRANSFER:
      return {
        ...state,
        refuseLoading: true,
        refuseSuccess: false,
        refuseError: false,
      };
    case Types.REFUSE_TRANSFER_SUCCESS:
      return {
        ...state,
        refuseLoading: false,
        refuseSuccess: true,
        refuseError: false,
      };
    case Types.REFUSE_TRANSFER_ERROR:
      return {
        ...state,
        refuseLoading: false,
        refuseSuccess: false,
        refuseError: true,
      };
    case Types.REFUSE_TRANSFER_RESET:
      return {
        ...state,
        refuseLoading: false,
        refuseSuccess: false,
        refuseError: false,
      };

    default:
      return state;
  }
}

export const Creators = {
  getTransfers: payload => ({
    type: Types.GET_TRANSFERS,
    payload,
  }),
  getTransfersSuccess: payload => ({
    type: Types.GET_TRANSFERS_SUCCESS,
    payload,
  }),
  getTransfersError: payload => ({
    type: Types.GET_TRANSFERS_ERROR,
    payload,
  }),
  postTransfer: payload => ({
    type: Types.POST_TRANSFER,
    payload,
  }),
  postTransferSuccess: payload => ({
    type: Types.POST_TRANSFER_SUCCESS,
    payload,
  }),
  postTransferError: payload => ({
    type: Types.POST_TRANSFER_ERROR,
    payload,
  }),
  getTransferById: payload => ({
    type: Types.GET_TRANSFER_BY_ID,
    payload,
  }),
  getTransferByIdSuccess: payload => ({
    type: Types.GET_TRANSFER_BY_ID_SUCCESS,
    payload,
  }),
  getTransferByIdError: payload => ({
    type: Types.GET_TRANSFER_BY_ID_ERROR,
    payload,
  }),
  putTransfer: payload => ({
    type: Types.PUT_TRANSFER,
    payload,
  }),
  putTransferSuccess: payload => ({
    type: Types.PUT_TRANSFER_SUCCESS,
    payload,
  }),
  putTransferError: payload => ({
    type: Types.PUT_TRANSFER_ERROR,
    payload,
  }),
  deleteTransfer: payload => ({
    type: Types.DELETE_TRANSFER,
    payload,
  }),
  deleteTransferSuccess: payload => ({
    type: Types.DELETE_TRANSFER_SUCCESS,
    payload,
  }),
  deleteTransferError: payload => ({
    type: Types.DELETE_TRANSFER_ERROR,
    payload,
  }),
  TransferClean: () => ({
    type: Types.TRANSFER_CLEAN,
  }),
  approveTransfer: payload => ({
    type: Types.APPROVE_TRANSFER,
    payload,
  }),
  approveTransferSuccess: () => ({
    type: Types.APPROVE_TRANSFER_SUCCESS,
  }),
  approveTransferError: payload => ({
    type: Types.APPROVE_TRANSFER_ERROR,
    payload,
  }),
  approveTransferReset: () => ({
    type: Types.APPROVE_TRANSFER_RESET,
  }),
  refuseTransfer: payload => ({
    type: Types.REFUSE_TRANSFER,
    payload,
  }),
  refuseTransferSuccess: () => ({
    type: Types.REFUSE_TRANSFER_SUCCESS,
  }),
  refuseTransferError: () => ({
    type: Types.REFUSE_TRANSFER_ERROR,
  }),
  refuseTransferReset: () => ({
    type: Types.REFUSE_TRANSFER_RESET,
  }),
};
