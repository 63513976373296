import { all, call, put, takeLatest, fork } from 'redux-saga/effects';
import {
  Creators as RetirementActions,
  Types as RetirementTypes,
} from 'store/ducks/Retirement';
import api from '../../util/Api';
import {Creators as RecActions, Types as RecTypes} from "../ducks/Rec";

function* getRetirements({ payload }) {
  try {
    const { status, data } = yield call(api.get, '/retirements', {
      params: payload,
    });
    if (status === 200) {
      yield put(RetirementActions.getRetirementsSuccess(data));
    }
  } catch (error) {
    yield put(
        RetirementActions.getRetirementsError('Erro ao listar Contas de Aposentadoria')
    );
  }
}

function* postRetirement({ payload }) {
  try {
    const { status, data } = yield call(api.post, '/retirements', payload.data);

    if (status === 200) {
      yield put(RetirementActions.postRetirementSuccess());
    }
  } catch (error) {
    yield put(RetirementActions.postRetirementError(error?.response?.data));
  }
}

function* getRetirementById({ payload }) {
  const { id, params, edit } = payload;
  try {
    const { status, data } = yield call(api.get, `/retirements/${id}`, {
      params,
    });
    if (status === 200) {
      yield put(RetirementActions.getRetirementByIdSuccess(data));
    }
  } catch (error) {
    yield put(
        RetirementActions.getRetirementByIdError('Conta de Aposentadori não encontrado')
    );
  }
}

function* putRetirement({ payload }) {
  const { id, data: payloadData } = payload;
  try {
    const { status } = yield call(api.put, `/retirements/${id}`, payloadData);
    if (status === 200) {
      yield put(RetirementActions.putRetirementSuccess());
    }
  } catch (error) {
    yield put(
        RetirementActions.putRetirementError('Erro ao editar Conta de Aposentadoria')
    );
  }
}

function* deleteRetirement({ payload }) {
  try {
    const { status } = yield call(api.delete, `/retirements/${payload}`);
    if (status === 200) {
      yield put(RetirementActions.deleteRetirementSuccess());
    }
  } catch (error) {
    yield put(
        RetirementActions.deleteRetirementError('Erro ao excluir Conta de Aposentadoria')
    );
  }
}

function* evidentRetirementSync() {
  try {
    const { status } = yield call(api.get, `/retirements/syncevident`);
    if (status === 200) {
      yield put(RetirementActions.evidentRetirementSyncSuccess());
    } else {
      yield put(RetirementActions.evidentRetirementSyncError());
    }
  } catch (err) {
    yield put(RetirementActions.evidentRetirementSyncError());
  }
}

function* getRetirementsWatcher() {
  yield takeLatest(RetirementTypes.GET_RETIREMENTS, getRetirements);
}

function* postRetirementWatcher() {
  yield takeLatest(RetirementTypes.POST_RETIREMENT, postRetirement);
}

function* getRetirementByIdWatcher() {
  yield takeLatest(RetirementTypes.GET_RETIREMENT_BY_ID, getRetirementById);
}

function* putRetirementWatcher() {
  yield takeLatest(RetirementTypes.PUT_RETIREMENT, putRetirement);
}

function* deleteRetirementWatcher() {
  yield takeLatest(RetirementTypes.DELETE_RETIREMENT, deleteRetirement);
}

function* evidentRetirementSyncWatcher() {
  yield takeLatest(RetirementTypes.EVIDENT_RETIREMENT_SYNC, evidentRetirementSync);
}

export default function* rootSaga() {
  yield all([
    fork(getRetirementsWatcher),
    fork(postRetirementWatcher),
    fork(deleteRetirementWatcher),
    fork(getRetirementByIdWatcher),
    fork(putRetirementWatcher),
    fork(deleteRetirementWatcher),
    fork(evidentRetirementSyncWatcher),
    // fork(createRetirementWatcher),
    // fork(getIdRetirementWatcher),
    // fork(updateRetirementWatcher),
  ]);
}
