import { all, call, put, takeLatest, fork } from 'redux-saga/effects';
import {
  Creators as ParticipantEnterpriseActions,
  Types as ParticipantEnterpriseTypes,
} from 'store/ducks/ParticipantEnterprise';
import api from '../../util/Api';

function* getParticipantEnterpriseListRequest({ payload }) {
  try {
    console.log("payload", payload);
    const { status, data } = yield call(api.get, `/participant_business`, {
      params: payload,
    });
    if (status === 200) {
      yield put(ParticipantEnterpriseActions.getParticipantEnterpriseListSuccess(data));
    } else {
      yield put(ParticipantEnterpriseActions.getParticipantEnterpriseListFail());
    }
  } catch (err) {
    yield put(ParticipantEnterpriseActions.getParticipantEnterpriseListFail());
  }
}

function* deleteParticipantEnterprise({ payload }) {
  try {
    const { status } = yield call(api.post, `/participant_business/destroy`, payload);
    if (status === 200) {
      yield put(ParticipantEnterpriseActions.deleteParticipantEnterpriseSuccess());
    } else {
      yield put(ParticipantEnterpriseActions.deleteParticipantEnterpriseFail());
    }
  } catch (err) {
    yield put(ParticipantEnterpriseActions.deleteParticipantEnterpriseFail());
  }
}

function* createParticipantEnterprise({ payload }) {
  try {
    const { status } = yield call(api.post, '/participant_business', payload);
    if (status === 200) {
      yield put(ParticipantEnterpriseActions.createParticipantEnterpriseSuccess());
    } else {
      yield put(ParticipantEnterpriseActions.createParticipantEnterpriseFail());
    }
  } catch (err) {
    yield put(ParticipantEnterpriseActions.createParticipantEnterpriseFail());
  }
}

function* updateParticipantEnterpriseRequest({ payload }) {
  try {
    const { status } = yield call(api.put, '/participant_business', payload);
    if (status === 200) {
      yield put(ParticipantEnterpriseActions.updateParticipantEnterpriseSuccess());
    } else {
      yield put(ParticipantEnterpriseActions.updateParticipantEnterpriseFail());
    }
  } catch (err) {
    yield put(ParticipantEnterpriseActions.updateParticipantEnterpriseFail());
  }
}

function* getParticipantEnterpriseListRequestWatcher() {
  yield takeLatest(ParticipantEnterpriseTypes.GET_PARTICIPANT_ENTERPRISE_LIST_REQUEST, getParticipantEnterpriseListRequest);
}

function* deleteParticipantEnterpriseWatcher() {
  yield takeLatest(ParticipantEnterpriseTypes.DELETE_PARTICIPANT_ENTERPRISE_REQUEST, deleteParticipantEnterprise);
}

function* createParticipantEnterpriseWatcher() {
  yield takeLatest(ParticipantEnterpriseTypes.CREATE_PARTICIPANT_ENTERPRISE_REQUEST, createParticipantEnterprise);
}

function* updateParticipantEnterpriseRequestWatcher() {
  yield takeLatest(ParticipantEnterpriseTypes.UPDATE_PARTICIPANT_ENTERPRISE_REQUEST, updateParticipantEnterpriseRequest);
}

export default function* rootSaga() {
  yield all([
    fork(getParticipantEnterpriseListRequestWatcher),
    fork(deleteParticipantEnterpriseWatcher),
    fork(createParticipantEnterpriseWatcher),
    fork(updateParticipantEnterpriseRequestWatcher)
  ]);
}
