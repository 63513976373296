import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import {
  Types as BeneficiaryTypes,
  Creators as BeneficiaryActions,
} from '../ducks/Beneficiary';
import api from '../../util/Api';

function* getBeneficiaries({ payload }) {
  try {
    const { status, data } = yield call(api.get, '/beneficiaries', {
      params: payload,
    });
    if (status === 200) {
      yield put(BeneficiaryActions.getBeneficiariesSuccess(data));
    }
  } catch (error) {
    yield put(
        BeneficiaryActions.getBeneficiariesError('Erro ao listar Beneficiários')
    );
  }
}

function* postBeneficiary({ payload }) {
  try {
    const { status, data } = yield call(api.post, '/beneficiaries', payload.data);

      if (status === 200) {
        yield put(BeneficiaryActions.postBeneficiarySuccess());
      }
  } catch (error) {
    yield put(BeneficiaryActions.postBeneficiaryError(error?.response?.data));
  }
}

function* getBeneficiaryById({ payload }) {
  const { id, params, edit } = payload;
  try {
    const { status, data } = yield call(api.get, `/beneficiaries/${id}`, {
      params,
    });
    if (status === 200) {
      yield put(BeneficiaryActions.getBeneficiaryByIdSuccess(data));
    }
  } catch (error) {
    yield put(
        BeneficiaryActions.getBeneficiaryByIdError('Beneficiário não encontrado')
    );
  }
}

function* putBeneficiary({ payload }) {
  const { id, data: payloadData } = payload;
  try {
    const { status } = yield call(api.put, `/beneficiaries/${id}`, payloadData);
    if (status === 200) {
        yield put(BeneficiaryActions.putBeneficiarySuccess());
    }
  } catch (error) {
    yield put(
        BeneficiaryActions.putBeneficiaryError('Erro ao editar Beneficiário')
    );
  }
}

function* deleteBeneficiary({ payload }) {
  try {
    const { status } = yield call(api.delete, `/beneficiaries/${payload}`);
    if (status === 200) {
      yield put(BeneficiaryActions.deleteBeneficiarySuccess());
    }
  } catch (error) {
    yield put(
        BeneficiaryActions.deleteBeneficiaryError('Erro ao excluir Beneficiário')
    );
  }
}

function* evidentBeneficiarySync() {
  try {
    const { status } = yield call(api.get, `/beneficiaries/syncevident`);
    if (status === 200) {
      yield put(BeneficiaryActions.evidentBeneficiarySyncSuccess());
    } else {
      yield put(BeneficiaryActions.evidentBeneficiarySyncError());
    }
  } catch (err) {
    yield put(BeneficiaryActions.evidentBeneficiarySyncError());
  }
}

function* getBeneficiariesWatcher() {
  yield takeLatest(BeneficiaryTypes.GET_BENEFICIARIES, getBeneficiaries);
}

function* postBeneficiaryWatcher() {
  yield takeLatest(BeneficiaryTypes.POST_BENEFICIARY, postBeneficiary);
}

function* getBeneficiaryByIdWatcher() {
  yield takeLatest(BeneficiaryTypes.GET_BENEFICIARY_BY_ID, getBeneficiaryById);
}

function* putBeneficiaryWatcher() {
  yield takeLatest(BeneficiaryTypes.PUT_BENEFICIARY, putBeneficiary);
}

function* deleteBeneficiaryWatcher() {
  yield takeLatest(BeneficiaryTypes.DELETE_BENEFICIARY, deleteBeneficiary);
}

function* evidentBeneficiarySyncWatcher() {
  yield takeLatest(BeneficiaryTypes.EVIDENT_BENEFICIARY_SYNC, evidentBeneficiarySync);
}

export default function* rootSaga() {
  yield all([
    fork(getBeneficiariesWatcher),
    fork(postBeneficiaryWatcher),
    fork(getBeneficiaryByIdWatcher),
    fork(putBeneficiaryWatcher),
    fork(deleteBeneficiaryWatcher),
    fork(evidentBeneficiarySyncWatcher),
  ]);
}
