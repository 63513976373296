export const Types = {
  GET_CNPJ: 'GET_CNPJ',
  GET_CNPJ_SUCCESS: 'GET_CNPJ_SUCCESS',
  GET_CNPJ_ERROR: 'GET_CNPJ_ERROR',
  GET_CNPJ_CLEAN: 'GET_CNPJ_CLEAN',
};

const INIT_STATE = {
  cnpjLoading: false,
  cnpjLoadingSuccess: false,
  cnpjError: false,
  cnpjData: null
};

export default function Serpro(state = INIT_STATE, action) {
  switch (action.type) {
    case Types.GET_CNPJ:
      return {
        ...state,
        cnpjLoading: true,
        cnpjLoadingSuccess: false,
        cnpjError: false
      };
    case Types.GET_CNPJ_SUCCESS:
      return {
        ...state,
        cnpjData: action.payload,
        cnpjLoading: false,
        cnpjLoadingSuccess: true,
        cnpjError: false
      };
    case Types.GET_CNPJ_ERROR:
      return {
        ...state,
        cnpjLoading: false,
        cnpjLoadingSuccess: false,
        cnpjError: true
      };
    case Types.GET_CNPJ_CLEAN:
        return {
          ...state,
          cnpjLoading: false,
          cnpjLoadingSuccess: false,
          cnpjData: false,
          cnpjError: false
        };
    default:
      return state;
  }
}

export const Creators = {
  getCnpj: (payload) => ({
    type: Types.GET_CNPJ,
    payload
  }),
  getCnpjSuccess: (payload) => ({
    type: Types.GET_CNPJ_SUCCESS,
    payload,
  }),
  getCnpjError: () => ({
    type: Types.GET_CNPJ_ERROR,
  }),
  getCnpjClean: () => ({
    type: Types.GET_CNPJ_CLEAN,
  }),
};
