export const Types = {
  GET_LIST_TUTORIAL_REQUEST: 'GET_LIST_TUTORIAL_REQUEST',
  GET_LIST_TUTORIAL_SUCCESS: 'GET_LIST_TUTORIAL_SUCCESS',
  GET_LIST_TUTORIAL_FAIL: 'GET_LIST_TUTORIAL_FAIL',

  DELETE_TUTORIAL_REQUEST: 'DELETE_TUTORIAL_REQUEST',
  DELETE_TUTORIAL_SUCCESS: 'DELETE_TUTORIAL_SUCCESS',
  DELETE_TUTORIAL_FAIL: 'DELETE_TUTORIAL_FAIL',
  DELETE_TUTORIAL_CLEAN: 'DELETE_TUTORIAL_CLEAN',

  GET_ID_TUTORIAL_REQUEST: 'GET_ID_TUTORIAL_REQUEST',
  GET_ID_TUTORIAL_SUCCESS: 'GET_ID_TUTORIAL_SUCCESS',
  GET_ID_TUTORIAL_FAIL: 'GET_ID_TUTORIAL_FAIL',

  CREATE_TUTORIAL_REQUEST: 'CREATE_TUTORIAL_REQUEST',
  CREATE_TUTORIAL_SUCCESS: 'CREATE_TUTORIAL_SUCCESS',
  CREATE_TUTORIAL_FAIL: 'CREATE_TUTORIAL_FAIL',
  CREATE_TUTORIAL_CLEAN: 'CREATE_TUTORIAL_CLEAN',

  UPDATE_TUTORIAL_REQUEST: 'UPDATE_TUTORIAL_REQUEST',
  UPDATE_TUTORIAL_SUCCESS: 'UPDATE_TUTORIAL_SUCCESS',
  UPDATE_TUTORIAL_FAIL: 'UPDATE_TUTORIAL_FAIL',
  UPDATE_TUTORIAL_CLEAN: 'UPDATE_TUTORIAL_CLEAN',
};

const INIT_STATE = {
  amount: 10,

  data: null,
  loading: false,
  error: false,

  deleteLoading: false,
  deleteSuccess: false,
  deleteError: false,

  idLoading: false,
  idData: null,
  idError: false,

  createLoading: false,
  createSuccess: false,
  createError: false,

  updateLoading: false,
  updateSuccess: false,
  updateError: false,
};

export default function Tutorial(state = INIT_STATE, action) {
  switch (action.type) {
    case Types.GET_LIST_TUTORIAL_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case Types.GET_LIST_TUTORIAL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload.data || action.payload,
        amount: action.payload.total,
      };
    case Types.GET_LIST_TUTORIAL_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case Types.DELETE_TUTORIAL_REQUEST:
      return {
        ...state,
        deleteLoading: true,
        deleteError: false,
        deleteSuccess: false,
      };
    case Types.DELETE_TUTORIAL_SUCCESS:
      return {
        ...state,
        deleteLoading: false,
        deleteError: false,
        deleteSuccess: true,
      };
    case Types.DELETE_TUTORIAL_FAIL:
      return {
        ...state,
        deleteLoading: false,
        deleteError: true,
        deleteSuccess: false,
      };
    case Types.DELETE_TUTORIAL_CLEAN:
      return {
        ...state,
        deleteLoading: false,
        deleteError: false,
        deleteSuccess: false,
      };

    case Types.GET_ID_TUTORIAL_REQUEST:
      return {
        ...state,
        idLoading: true,
        idError: false,
      };
    case Types.GET_ID_TUTORIAL_SUCCESS:
      return {
        ...state,
        idLoading: false,
        idError: false,
        idData: action.payload,
      };
    case Types.GET_ID_TUTORIAL_FAIL:
      return {
        ...state,
        idLoading: false,
        idError: true,
      };

    case Types.CREATE_TUTORIAL_REQUEST:
      return {
        ...state,
        createLoading: true,
        createError: false,
        createSuccess: false,
      };
    case Types.CREATE_TUTORIAL_SUCCESS:
      return {
        ...state,
        createLoading: false,
        createError: false,
        createSuccess: true,
      };
    case Types.CREATE_TUTORIAL_FAIL:
      return {
        ...state,
        createLoading: false,
        createError: true,
        createSuccess: false,
      };
    case Types.CREATE_TUTORIAL_CLEAN:
      return {
        ...state,
        createLoading: false,
        createError: false,
        createSuccess: false,
      };

    case Types.UPDATE_TUTORIAL_REQUEST:
      return {
        ...state,
        updateLoading: true,
        updateError: false,
        updateSuccess: false,
      };
    case Types.UPDATE_TUTORIAL_SUCCESS:
      return {
        ...state,
        updateLoading: false,
        updateError: false,
        updateSuccess: true,
      };
    case Types.UPDATE_TUTORIAL_FAIL:
      return {
        ...state,
        updateLoading: false,
        updateError: true,
        updateSuccess: false,
      };
    case Types.UPDATE_TUTORIAL_CLEAN:
      return {
        ...state,
        updateLoading: false,
        updateError: false,
        updateSuccess: false,
      };

    default:
      return state;
  }
}

export const Creators = {
  // GET
  getListTutorialRequest: (payload) => ({
    type: Types.GET_LIST_TUTORIAL_REQUEST,
    payload,
  }),
  getListTutorialSuccess: (payload) => ({
    type: Types.GET_LIST_TUTORIAL_SUCCESS,
    payload,
  }),
  getListTutorialFail: () => ({
    type: Types.GET_LIST_TUTORIAL_FAIL,
  }),

  // DELETE
  deleteTutorialRequest: (payload) => ({
    type: Types.DELETE_TUTORIAL_REQUEST,
    payload,
  }),
  deleteTutorialSuccess: () => ({
    type: Types.DELETE_TUTORIAL_SUCCESS,
  }),
  deleteTutorialFail: () => ({
    type: Types.DELETE_TUTORIAL_FAIL,
  }),
  deleteTutorialClean: () => ({
    type: Types.DELETE_TUTORIAL_CLEAN,
  }),

  // ID
  getIdTutorialRequest: (payload) => ({
    type: Types.GET_ID_TUTORIAL_REQUEST,
    payload,
  }),
  getIdTutorialSuccess: (payload) => ({
    type: Types.GET_ID_TUTORIAL_SUCCESS,
    payload,
  }),
  getIdTutorialFail: () => ({
    type: Types.GET_ID_TUTORIAL_FAIL,
  }),

  // CREATE
  createTutorialRequest: (payload) => ({
    type: Types.CREATE_TUTORIAL_REQUEST,
    payload,
  }),
  createTutorialSuccess: () => ({
    type: Types.CREATE_TUTORIAL_SUCCESS,
  }),
  createTutorialFail: () => ({
    type: Types.CREATE_TUTORIAL_FAIL,
  }),
  createTutorialClean: () => ({
    type: Types.CREATE_TUTORIAL_CLEAN,
  }),

  // UPDATE
  updateTutorialRequest: (payload) => ({
    type: Types.UPDATE_TUTORIAL_REQUEST,
    payload,
  }),
  updateTutorialSuccess: (payload) => ({
    type: Types.UPDATE_TUTORIAL_SUCCESS,
    payload,
  }),
  updateTutorialFail: () => ({
    type: Types.UPDATE_TUTORIAL_FAIL,
  }),
  updateTutorialClean: () => ({
    type: Types.UPDATE_TUTORIAL_CLEAN,
  }),
};
