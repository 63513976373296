import { all, call, put, takeLatest, fork } from 'redux-saga/effects';
import {
  Creators as ApiIntegrationActions,
  Types as ApiIntegrationTypes,
} from 'store/ducks/ApiIntegration';
import api from '../../util/Api';
import {Creators as NotaFiscalActions} from "../ducks/NotaFiscal";

function* getList({ payload }) {
  try {
    const { status, data } = yield call(api.get, '/apiintegrations', {
      params: payload,
    });
    if (status === 200) {
      yield put(ApiIntegrationActions.getListApiIntegrationSuccess(data));
    } else {
      yield put(ApiIntegrationActions.getListApiIntegrationFail());
    }
  } catch (err) {
    yield put(ApiIntegrationActions.getListApiIntegrationFail());
  }
}

function* syncApiIntegration({ payload }) {
  try {
    const { status, data } = yield call(
      api.get,
      `/imports/externalImportAll/${payload}`
    );
    if (status === 200) {
      yield put(ApiIntegrationActions.syncApiIntegrationSuccess(data));
    } else {
      yield put(ApiIntegrationActions.syncApiIntegrationFail());
    }
  } catch (err) {
    yield put(ApiIntegrationActions.syncApiIntegrationFail());
  }
}

function* deleteApiIntegration({ payload }) {
  try {
    const { status } = yield call(api.delete, `/apiintegrations/${payload}`);
    if (status === 200) {
      yield put(ApiIntegrationActions.deleteApiIntegrationSuccess());
    } else {
      yield put(ApiIntegrationActions.deleteApiIntegrationFail());
    }
  } catch (err) {
    yield put(ApiIntegrationActions.deleteApiIntegrationFail());
  }
}

function* getIdApiIntegration({ payload }) {
  try {
    const { status, data } = yield call(api.get, `/apiintegrations/${payload}`);
    if (status === 200) {
      yield put(ApiIntegrationActions.getIdApiIntegrationSuccess(data));
    } else {
      yield put(ApiIntegrationActions.getIdApiIntegrationFail());
    }
  } catch (err) {
    yield put(ApiIntegrationActions.getIdApiIntegrationFail());
  }
}

function* createApiIntegration({ payload }) {
  try {
    const { status } = yield call(api.post, '/apiintegrations', payload);
    if (status === 200) {
      yield put(ApiIntegrationActions.createApiIntegrationSuccess());
    } else {
      yield put(ApiIntegrationActions.createApiIntegrationFail());
    }
  } catch (e) {
    yield put(ApiIntegrationActions.createApiIntegrationFail());
  }
}

function* updateApiIntegration({ payload }) {
  const { id, data } = payload;
  try {
    const { status } = yield call(api.put, `/apiintegrations/${id}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (status === 200) {
      yield put(ApiIntegrationActions.updateApiIntegrationSuccess());
    } else {
      yield put(ApiIntegrationActions.updateApiIntegrationFail());
    }
  } catch (e) {
    yield put(ApiIntegrationActions.updateApiIntegrationFail());
  }
}

function* getListWatcher() {
  yield takeLatest(
    ApiIntegrationTypes.GET_LIST_API_INTEGRATION_REQUEST,
    getList
  );
}

function* syncApiIntegrationWatcher() {
  yield takeLatest(
    ApiIntegrationTypes.SYNC_API_INTEGRATION_REQUEST,
    syncApiIntegration
  );
}

function* deleteApiIntegrationWatcher() {
  yield takeLatest(
    ApiIntegrationTypes.DELETE_API_INTEGRATION_REQUEST,
    deleteApiIntegration
  );
}

function* getIdApiIntegrationWatcher() {
  yield takeLatest(
    ApiIntegrationTypes.GET_ID_API_INTEGRATION_REQUEST,
    getIdApiIntegration
  );
}

function* createApiIntegrationWatcher() {
  yield takeLatest(
    ApiIntegrationTypes.CREATE_API_INTEGRATION_REQUEST,
    createApiIntegration
  );
}

function* updateApiIntegrationWatcher() {
  yield takeLatest(
    ApiIntegrationTypes.UPDATE_API_INTEGRATION_REQUEST,
    updateApiIntegration
  );
}

export default function* rootSaga() {
  yield all([
    fork(getListWatcher),
    fork(syncApiIntegrationWatcher),
    fork(deleteApiIntegrationWatcher),
    fork(getIdApiIntegrationWatcher),
    fork(createApiIntegrationWatcher),
    fork(updateApiIntegrationWatcher),
  ]);
}
