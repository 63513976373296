export const Types = {
  GET_PARTICIPANT_REGISTRANT_LIST_REQUEST: 'GET_PARTICIPANT_REGISTRANT_LIST_REQUEST',
  GET_PARTICIPANT_REGISTRANT_LIST_SUCCESS: 'GET_PARTICIPANT_REGISTRANT_LIST_SUCCESS',
  GET_PARTICIPANT_REGISTRANT_LIST_FAIL: 'GET_PARTICIPANT_REGISTRANT_LIST_FAIL',

  UPDATE_PARTICIPANT_REGISTRANT_REQUEST: 'UPDATE_PARTICIPANT_REGISTRANT_REQUEST',
  UPDATE_PARTICIPANT_REGISTRANT_SUCCESS: 'UPDATE_PARTICIPANT_REGISTRANT_SUCCESS',
  UPDATE_PARTICIPANT_REGISTRANT_FAIL: 'UPDATE_PARTICIPANT_REGISTRANT_FAIL',
  UPDATE_PARTICIPANT_REGISTRANT_CLEAN: 'UPDATE_PARTICIPANT_REGISTRANT_CLEAN',

  DELETE_PARTICIPANT_REGISTRANT_REQUEST: 'DELETE_PARTICIPANT_REGISTRANT_REQUEST',
  DELETE_PARTICIPANT_REGISTRANT_SUCCESS: 'DELETE_PARTICIPANT_REGISTRANT_SUCCESS',
  DELETE_PARTICIPANT_REGISTRANT_FAIL: 'DELETE_PARTICIPANT_REGISTRANT_FAIL',
  DELETE_PARTICIPANT_REGISTRANT_CLEAN: 'DELETE_PARTICIPANT_REGISTRANT_CLEAN',

  CREATE_PARTICIPANT_REGISTRANT_REQUEST: 'CREATE_PARTICIPANT_REGISTRANT_REQUEST',
  CREATE_PARTICIPANT_REGISTRANT_SUCCESS: 'CREATE_PARTICIPANT_REGISTRANT_SUCCESS',
  CREATE_PARTICIPANT_REGISTRANT_FAIL: 'CREATE_PARTICIPANT_REGISTRANT_FAIL',
  CREATE_PARTICIPANT_REGISTRANT_CLEAN: 'CREATE_PARTICIPANT_REGISTRANT_CLEAN'
};

const INIT_STATE = {
  loading: false,
  error: false,
  data: [],
  amount: 10,

  deleteLoading: false,
  deleteError: false,
  deleteSuccess: false,

  createLoading: false,
  createError: false,
  createSuccess: false,

  updateLoading: false,
  updateError: false,
  updateSuccess: false,
};

export default function ParticipantRegistrant(state = INIT_STATE, action) {
  switch (action.type) {
    case Types.GET_PARTICIPANT_REGISTRANT_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case Types.GET_PARTICIPANT_REGISTRANT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload.data || action.payload,
        amount: action.payload.total,
      };
    case Types.GET_PARTICIPANT_REGISTRANT_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case Types.DELETE_PARTICIPANT_REGISTRANT_REQUEST:
      return {
        ...state,
        deleteLoading: true,
        deleteError: false,
        deleteSuccess: false,
      };
    case Types.DELETE_PARTICIPANT_REGISTRANT_SUCCESS:
      return {
        ...state,
        deleteLoading: false,
        deleteError: false,
        deleteSuccess: true,
      };
    case Types.DELETE_PARTICIPANT_REGISTRANT_FAIL:
      return {
        ...state,
        deleteLoading: false,
        deleteError: true,
        deleteSuccess: false,
      };
    case Types.DELETE_PARTICIPANT_REGISTRANT_CLEAN:
      return {
        ...state,
        deleteLoading: false,
        deleteError: false,
        deleteSuccess: false,
      };

    case Types.CREATE_PARTICIPANT_REGISTRANT_REQUEST:
      return {
        ...state,
        createLoading: true,
        createError: false,
        createSuccess: false,
      };
    case Types.CREATE_PARTICIPANT_REGISTRANT_SUCCESS:
      return {
        ...state,
        createLoading: false,
        createError: false,
        createSuccess: true,
      };
    case Types.CREATE_PARTICIPANT_REGISTRANT_FAIL:
      return {
        ...state,
        createLoading: false,
        createError: true,
        createSuccess: false,
      };
    case Types.CREATE_PARTICIPANT_REGISTRANT_CLEAN:
      return {
        ...state,
        createLoading: false,
        createError: false,
        createSuccess: false,
      };

    case Types.UPDATE_PARTICIPANT_REGISTRANT_REQUEST:
      return {
        ...state,
        updateLoading: true,
        updateError: false,
        updateSuccess: false,
      };
    case Types.UPDATE_PARTICIPANT_REGISTRANT_SUCCESS:
      return {
        ...state,
        updateLoading: false,
        updateError: false,
        updateSuccess: true,
      };
    case Types.UPDATE_PARTICIPANT_REGISTRANT_FAIL:
      return {
        ...state,
        updateLoading: false,
        updateError: true,
        updateSuccess: false,
      };
    case Types.UPDATE_PARTICIPANT_REGISTRANT_CLEAN:
      return {
        ...state,
        updateLoading: false,
        updateError: false,
        updateSuccess: false,
      };

    default:
      return state;
  }
}

export const Creators = {
  // GET
  getParticipantRegistrantListRequest: payload => ({
    type: Types.GET_PARTICIPANT_REGISTRANT_LIST_REQUEST,
    payload,
  }),
  getParticipantRegistrantListSuccess: payload => ({
    type: Types.GET_PARTICIPANT_REGISTRANT_LIST_SUCCESS,
    payload,
  }),
  getParticipantRegistrantListFail: () => ({
    type: Types.GET_PARTICIPANT_REGISTRANT_LIST_FAIL,
  }),

  // DELETE
  deleteParticipantRegistrantRequest: payload => ({
    type: Types.DELETE_PARTICIPANT_REGISTRANT_REQUEST,
    payload,
  }),
  deleteParticipantRegistrantSuccess: () => ({
    type: Types.DELETE_PARTICIPANT_REGISTRANT_SUCCESS,
  }),
  deleteParticipantRegistrantFail: () => ({
    type: Types.DELETE_PARTICIPANT_REGISTRANT_FAIL,
  }),
  deleteParticipantRegistrantClean: () => ({
    type: Types.DELETE_PARTICIPANT_REGISTRANT_CLEAN,
  }),

  // CREATE
  createParticipantRegistrantRequest: payload => ({
    type: Types.CREATE_PARTICIPANT_REGISTRANT_REQUEST,
    payload,
  }),
  createParticipantRegistrantSuccess: payload => ({
    type: Types.CREATE_PARTICIPANT_REGISTRANT_SUCCESS,
    payload,
  }),
  createParticipantRegistrantFail: () => ({
    type: Types.CREATE_PARTICIPANT_REGISTRANT_FAIL,
  }),
  createParticipantRegistrantClean: () => ({
    type: Types.CREATE_PARTICIPANT_REGISTRANT_CLEAN,
  }),

  // UPDATE
  updateParticipantRegistrantRequest: payload => ({
    type: Types.UPDATE_PARTICIPANT_REGISTRANT_REQUEST,
    payload,
  }),
  updateParticipantRegistrantSuccess: payload => ({
    type: Types.UPDATE_PARTICIPANT_REGISTRANT_SUCCESS,
    payload,
  }),
  updateParticipantRegistrantFail: () => ({
    type: Types.UPDATE_PARTICIPANT_REGISTRANT_FAIL,
  }),
  updateParticipantRegistrantClean: () => ({
    type: Types.UPDATE_PARTICIPANT_REGISTRANT_CLEAN,
  }),
};

