export const Types = {
  GET_RDMS: 'GET_RDMS',
  GET_RDMS_SUCCESS: 'GET_RDMS_SUCCESS',
  GET_RDMS_ERROR: 'GET_RDMS_ERROR',
};

const INIT_STATE = {
  data: null,
  loading: false,
  error: null,
  amount: 10,
};

export default function Rdm(state = INIT_STATE, action) {
  switch (action.type) {
    case Types.GET_RDMS:
      return {
        ...state,
        data: null,
        loading: true,
        error: null,
      };
    case Types.GET_RDMS_SUCCESS:
      return {
        ...state,
        data: action.payload?.data || action.payload,
        amount: action.payload?.total || state.amount,
        loading: false,
        error: null,
      };
    case Types.GET_RDMS_ERROR:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

export const Creators = {
  getRdms: payload => ({
    type: Types.GET_RDMS,
    payload,
  }),
  getRdmsSuccess: payload => ({
    type: Types.GET_RDMS_SUCCESS,
    payload,
  }),
  getRdmsError: payload => ({
    type: Types.GET_RDMS_ERROR,
    payload,
  }),
};
