import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { Creators as CepActions, Types as CepTypes } from 'store/ducks/Cep';
import axios from 'axios';

function* getCep({ payload }) {
  try {
    const { status, data } = yield call(
      axios.get,
      `https://viacep.com.br/ws/${payload}/json/`
    );
    if (status === 200) {
      yield put(CepActions.getCepSuccess(data));
    } else {
      yield put(CepActions.getCepError());
    }
  } catch (err) {
    yield put(CepActions.getCepError());
  }
}

function* getCepWatcher() {
  yield takeLatest(CepTypes.GET_CEP, getCep);
}

export default function* rootSaga() {
  yield all([fork(getCepWatcher)]);
}
