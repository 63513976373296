import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import { Types as UserTypes, Creators as UserActions } from '../ducks/User';
import api from '../../util/Api';

function* getUsers({ payload }) {
  try {
    const { status, data } = yield call(api.get, '/clients', {
      params: payload,
    });
    if (status === 200) {
      yield put(UserActions.getUsersSuccess(data));
    }
  } catch (error) {
    yield put(UserActions.getUsersError('Erro ao listar Usuários'));
  }
}

function* getAllUsers({ payload }) {
  try {
    const { status, data } = yield call(api.get, '/users', {
      params: payload,
    });
    if (status === 200) {
      yield put(UserActions.getAllUsersSuccess(data));
    }
  } catch (error) {
    yield put(UserActions.getAllUsersError('Erro ao listar Usuários'));
  }
}

function* postUser({ payload }) {
  try {
    const { status } = yield call(api.post, '/clients', payload);
    if (status === 200) {
      yield put(UserActions.postUserSuccess());
    }
  } catch (error) {
    if (error.response.data.message === 'Email já cadastrado') {
      yield put(UserActions.postUserError('E-mail já cadastrado'));
    } else {
      yield put(UserActions.postUserError('Erro ao cadastrar usuário'));
    }
  }
}

function* getUserById({ payload }) {
  try {
    const { status, data } = yield call(api.get, `/clients/${payload}`);
    if (status === 200) {
      yield put(UserActions.getUserByIdSuccess(data));
    }
  } catch (error) {
    yield put(UserActions.getUserByIdError('Erro ao listar usuário'));
  }
}

function* putUser({ payload }) {
  const { userId } = payload;
  try {
    const { status } = yield call(api.put, `/clients/${userId}`, payload.data);
    if (status === 200) {
      yield put(UserActions.putUserSuccess());
    }
  } catch (error) {
    yield put(UserActions.putUserError('Erro ao editar usuário'));
  }
}

function* deleteUser({ payload }) {
  try {
    const { status } = yield call(api.delete, `/users/${payload}`);
    if (status === 200) {
      yield put(UserActions.deleteUserSuccess());
    }
  } catch (error) {
    yield put(UserActions.deleteUserError('Erro ao excluir usuário'));
  }
}

function* getUsersWatcher() {
  yield takeLatest(UserTypes.GET_USERS, getUsers);
}
function* getAllUsersWatcher() {
  yield takeLatest(UserTypes.GET_ALL_USERS, getAllUsers);
}
function* postUserWatcher() {
  yield takeLatest(UserTypes.POST_USER, postUser);
}
function* getUserByIdWatcher() {
  yield takeLatest(UserTypes.GET_USER_BY_ID, getUserById);
}
function* putUserWatcher() {
  yield takeLatest(UserTypes.PUT_USER, putUser);
}
function* deleteUserWatcher() {
  yield takeLatest(UserTypes.DELETE_USER, deleteUser);
}
export default function* rootSaga() {
  yield all([
    fork(getUsersWatcher),
    fork(getAllUsersWatcher),
    fork(postUserWatcher),
    fork(getUserByIdWatcher),
    fork(putUserWatcher),
    fork(deleteUserWatcher),
  ]);
}
