import { all, call, put, takeLatest, fork } from 'redux-saga/effects';
import {
  Creators as ConfigActions,
  Types as ConfigTypes,
} from 'store/ducks/Config';
import api from '../../util/Api';

function* getList({ payload }) {
  try {
    const { status, data } = yield call(api.get, '/configs', {
      params: payload,
    });
    if (status === 200) {
      yield put(ConfigActions.getListConfigSuccess(data));
    } else {
      yield put(ConfigActions.getListConfigFail());
    }
  } catch (err) {
    yield put(ConfigActions.getListConfigFail());
  }
}

function* getIdConfig({ payload }) {
  try {
    const { status, data } = yield call(api.get, `/configs/${payload}`);
    if (status === 200) {
      yield put(ConfigActions.getIdConfigSuccess(data));
    } else {
      yield put(ConfigActions.getIdConfigFail());
    }
  } catch (err) {
    yield put(ConfigActions.getIdConfigFail());
  }
}

function* updateConfig({ payload }) {
  const { id, data } = payload;

  try {
    const { status } = yield call(api.put, `/configs/${id}`, data, {
      /*headers: {
        'Content-Type': 'multipart/form-data',
      },*/
    });
    if (status === 200) {
      yield put(ConfigActions.updateConfigSuccess());
    } else {
      yield put(ConfigActions.updateConfigFail());
    }
  } catch (e) {
    yield put(ConfigActions.updateConfigFail());
  }
}

function* getListWatcher() {
  yield takeLatest(ConfigTypes.GET_LIST_CONFIG_REQUEST, getList);
}

function* getIdTutorialWatcher() {
  yield takeLatest(ConfigTypes.GET_ID_CONFIG_REQUEST, getIdConfig);
}

function* updateTutorialWatcher() {
  yield takeLatest(ConfigTypes.UPDATE_CONFIG_REQUEST, updateConfig);
}

export default function* rootSaga() {
  yield all([
    fork(getListWatcher),
    fork(getIdTutorialWatcher),
    fork(updateTutorialWatcher),
  ]);
}
