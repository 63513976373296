export const Types = {
  GET_REGISTRANTS: 'GET_REGISTRANTS',
  GET_REGISTRANTS_SUCCESS: 'GET_REGISTRANTS_SUCCESS',
  GET_REGISTRANTS_ERROR: 'GET_REGISTRANTS_ERROR',
  GET_REGISTRANT_BY_ID: 'GET_REGISTRANT_BY_ID',
  GET_REGISTRANT_BY_ID_SUCCESS: 'GET_REGISTRANT_BY_ID_SUCCESS',
  GET_REGISTRANT_BY_ID_ERROR: 'GET_REGISTRANT_BY_ID_ERROR',
  POST_REGISTRANT: 'POST_REGISTRANT',
  POST_REGISTRANT_SUCCESS: 'POST_REGISTRANT_SUCCESS',
  POST_REGISTRANT_ERROR: 'POST_REGISTRANT_ERROR',
  PUT_REGISTRANT: 'PUT_REGISTRANT',
  PUT_REGISTRANT_SUCCESS: 'PUT_REGISTRANT_SUCCESS',
  PUT_REGISTRANT_ERROR: 'PUT_REGISTRANT_ERROR',
  REGISTRANT_CLEAN: 'REGISTRANT_CLEAN',
  DELETE_REGISTRANT: 'DELETE_REGISTRANT',
  DELETE_REGISTRANT_SUCCESS: 'DELETE_REGISTRANT_SUCCESS',
  DELETE_REGISTRANT_ERROR: 'DELETE_REGISTRANT_ERROR',
};

const INIT_STATE = {
  data: [],
  registrant: null,
  loading: false,
  error: null,
  postSuccess: false,
  deleteSuccess: false,

  amount: 10,
};

export default function Registrant(state = INIT_STATE, action) {
  switch (action.type) {
    case Types.GET_REGISTRANTS:
      return {
        ...state,
        data: [],
        loading: true,
        error: null,
      };
    case Types.GET_REGISTRANTS_SUCCESS:
      return {
        ...state,
        data: action.payload.data || action.payload,
        amount: action.payload.total,
        loading: false,
        error: null,
      };
    case Types.GET_REGISTRANTS_ERROR:
      return {
        ...state,
        data: [],
        loading: false,
        error: action.payload,
      };
    case Types.GET_REGISTRANT_BY_ID:
      return {
        ...state,
        registrant: null,
        loading: true,
        error: null,
      };
    case Types.GET_REGISTRANT_BY_ID_SUCCESS:
      return {
        ...state,
        registrant: action.payload,
        loading: false,
        error: null,
      };
    case Types.GET_REGISTRANT_BY_ID_ERROR:
      return {
        ...state,
        registrant: null,
        loading: false,
        error: action.payload,
      };
    case Types.POST_REGISTRANT:
      return {
        ...state,
        postSuccess: false,
        loading: true,
        error: null,
      };
    case Types.POST_REGISTRANT_SUCCESS:
      return {
        ...state,
        postSuccess: true,
        loading: false,
        error: null,
      };
    case Types.POST_REGISTRANT_ERROR:
      return {
        ...state,
        postSuccess: false,
        loading: false,
        error: action.payload,
      };
    case Types.PUT_REGISTRANT:
      return {
        ...state,
        postSuccess: false,
        loading: true,
        error: null,
      };
    case Types.PUT_REGISTRANT_SUCCESS:
      return {
        ...state,
        postSuccess: true,
        loading: false,
        error: null,
      };
    case Types.PUT_REGISTRANT_ERROR:
      return {
        ...state,
        postSuccess: false,
        loading: false,
        error: action.payload,
      };
    case Types.REGISTRANT_CLEAN:
      return {
        ...state,
        postSuccess: false,
        deleteSuccess: false,
        loading: false,
        error: false,
      };
    case Types.DELETE_REGISTRANT:
      return {
        ...state,
        deleteSuccess: false,
        loading: true,
        error: false,
      };
    case Types.DELETE_REGISTRANT_SUCCESS:
      return {
        ...state,
        deleteSuccess: true,
        loading: false,
        error: false,
      };
    case Types.DELETE_REGISTRANT_ERROR:
      return {
        ...state,
        deleteSuccess: false,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

export const Creators = {
  getRegistrants: (payload) => ({
    type: Types.GET_REGISTRANTS,
    payload,
  }),
  getRegistrantsSuccess: (payload) => ({
    type: Types.GET_REGISTRANTS_SUCCESS,
    payload,
  }),
  getRegistrantsError: (payload) => ({
    type: Types.GET_REGISTRANTS_ERROR,
    payload,
  }),
  getRegistrantById: (payload) => ({
    type: Types.GET_REGISTRANT_BY_ID,
    payload,
  }),
  getRegistrantByIdSuccess: (payload) => ({
    type: Types.GET_REGISTRANT_BY_ID_SUCCESS,
    payload,
  }),
  getRegistrantByIdError: (payload) => ({
    type: Types.GET_REGISTRANT_BY_ID_ERROR,
    payload,
  }),
  postRegistrant: (payload) => ({
    type: Types.POST_REGISTRANT,
    payload,
  }),
  postRegistrantSuccess: (payload) => ({
    type: Types.POST_REGISTRANT_SUCCESS,
    payload,
  }),
  postRegistrantError: (payload) => ({
    type: Types.POST_REGISTRANT_ERROR,
    payload,
  }),
  putRegistrant: (payload) => ({
    type: Types.PUT_REGISTRANT,
    payload,
  }),
  putRegistrantSuccess: (payload) => ({
    type: Types.PUT_REGISTRANT_SUCCESS,
    payload,
  }),
  putRegistrantError: (payload) => ({
    type: Types.PUT_REGISTRANT_ERROR,
    payload,
  }),
  registrantClean: () => ({
    type: Types.REGISTRANT_CLEAN,
  }),
  deleteRegistrant: (payload) => ({
    type: Types.DELETE_REGISTRANT,
    payload,
  }),
  deleteRegistrantSuccess: (payload) => ({
    type: Types.DELETE_REGISTRANT_SUCCESS,
    payload,
  }),
  deleteRegistrantError: (payload) => ({
    type: Types.DELETE_REGISTRANT_ERROR,
    payload,
  }),
};
