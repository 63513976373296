import { all, call, put, takeLatest, fork } from 'redux-saga/effects';
import {
  Creators as RedemptionAutomationActions,
  Types as RedemptionAutomationTypes,
} from 'store/ducks/RedemptionAutomation';
import api from '../../util/Api';

function* getRedemptionsAutomations({ payload }) {
  try {
    const { status, data } = yield call(api.get, '/redemptionsautomations', {
      params: payload,
    });
    if (status === 200) {
      yield put(RedemptionAutomationActions.getRedemptionsAutomationsSuccess(data));
    }
  } catch (error) {
    yield put(
      RedemptionAutomationActions.getRedemptionsAutomationsError('Erro ao listar aposentadorias')
    );
  }
}

function* postRedemptionAutomation({ payload }) {
  try {
    const { status, data } = yield call(api.post, '/redemptionsautomations', payload.data);

    if (status === 200) {
      yield put(RedemptionAutomationActions.postRedemptionAutomationSuccess());
    }
  } catch (error) {
    yield put(RedemptionAutomationActions.postRedemptionAutomationError(error?.response?.data));
  }
}

function* getRedemptionAutomationById({ payload }) {
  const { id, params, edit } = payload;
  try {
    const { status, data } = yield call(api.get, `/redemptionsautomations/${id}`, {
      params,
    });
    if (status === 200) {
      yield put(RedemptionAutomationActions.getRedemptionAutomationByIdSuccess(data));
    }
  } catch (error) {
    yield put(
      RedemptionAutomationActions.getRedemptionAutomationByIdError('Automação não encontrado')
    );
  }
}

function* putRedemptioAutomation({ payload }) {
  const { id, data: payloadData } = payload;
  try {
    const { status } = yield call(api.put, `/redemptionsautomations/${id}`, payloadData);
    if (status === 200) {
      yield put(RedemptionAutomationActions.putRedemptionAutomationSuccess());
    }
  } catch (error) {
    yield put(
      RedemptionAutomationActions.putRedemptionAutomationError('Erro ao editar Automação')
    );
  }
}

function* deleteRedemptionAutomation({ payload }) {
  try {
    const { status } = yield call(api.delete, `/redemptions/${payload}`);
    if (status === 200) {
      yield put(RedemptionAutomationActions.deleteRedemptionAutomation());
    }
  } catch (error) {
    yield put(
      RedemptionAutomationActions.deleteRedemptionAutomationError('Erro ao excluir Automação')
    );
  }
}

function* startRedemptionAutomation({ payload }) {
  const { id } = payload;

  try {
    const { status, data } = yield call(api.get, `/redemptionsautomations/startautomation/${id}`);
    if (status === 200) {
      yield put(RedemptionAutomationActions.startRedemptionAutomationSuccess(data));
    }
  } catch (error) {
    yield put(
      RedemptionAutomationActions.startRedemptionAutomationError(error?.response?.data)
    );
  }
}

function* getRedemptionsAutomationsWatcher() {
  yield takeLatest(RedemptionAutomationTypes.GET_REDEMPTIONS_AUTOMATIONS, getRedemptionsAutomations);
}

function* postRedemptionAutomationWatcher() {
  yield takeLatest(RedemptionAutomationTypes.POST_REDEMPTION_AUTOMATION, postRedemptionAutomation);
}

function* getRedemptionAutomationByIdWatcher() {
  yield takeLatest(RedemptionAutomationTypes.GET_REDEMPTION_AUTOMATION_BY_ID, getRedemptionAutomationById);
}

function* putRedemptionAutomationWatcher() {
  yield takeLatest(RedemptionAutomationTypes.PUT_REDEMPTION_AUTOMATION, putRedemptioAutomation);
}

function* deleteRedemptionAutomationWatcher() {
  yield takeLatest(RedemptionAutomationTypes.DELETE_REDEMPTION_AUTOMATION, deleteRedemptionAutomation);
}

function* startRedemptionAutomationWatcher() {
  yield takeLatest(RedemptionAutomationTypes.START_REDEMPTION_AUTOMATION, startRedemptionAutomation);
}

export default function* rootSaga() {
  yield all([
    fork(getRedemptionsAutomationsWatcher),
    fork(postRedemptionAutomationWatcher),
    fork(getRedemptionAutomationByIdWatcher),
    fork(putRedemptionAutomationWatcher),
    fork(deleteRedemptionAutomationWatcher),
    fork(startRedemptionAutomationWatcher)
  ]);
}
