export const Types = {
  GET_COUNTRY_LIST_REQUEST: 'GET_COUNTRY_LIST_REQUEST',
  GET_COUNTRY_LIST_SUCCESS: 'GET_COUNTRY_LIST_SUCCESS',
  GET_COUNTRY_LIST_FAIL: 'GET_COUNTRY_LIST_FAIL',

  DELETE_COUNTRY_REQUEST: 'DELETE_COUNTRY_REQUEST',
  DELETE_COUNTRY_SUCCESS: 'DELETE_COUNTRY_SUCCESS',
  DELETE_COUNTRY_FAIL: 'DELETE_COUNTRY_FAIL',
  DELETE_COUNTRY_CLEAN: 'DELETE_COUNTRY_CLEAN',

  CREATE_COUNTRY_REQUEST: 'CREATE_COUNTRY_REQUEST',
  CREATE_COUNTRY_SUCCESS: 'CREATE_COUNTRY_SUCCESS',
  CREATE_COUNTRY_FAIL: 'CREATE_COUNTRY_FAIL',
  CREATE_COUNTRY_CLEAN: 'CREATE_COUNTRY_CLEAN',

  GET_ID_COUNTRY_REQUEST: 'GET_ID_COUNTRY_REQUEST',
  GET_ID_COUNTRY_SUCCESS: 'GET_ID_COUNTRY_SUCCESS',
  GET_ID_COUNTRY_FAIL: 'GET_ID_COUNTRY_FAIL',
  GET_ID_COUNTRY_CLEAN: 'GET_ID_COUNTRY_CLEAN',

  UPDATE_COUNTRY_REQUEST: 'UPDATE_COUNTRY_REQUEST',
  UPDATE_COUNTRY_SUCCESS: 'UPDATE_COUNTRY_SUCCESS',
  UPDATE_COUNTRY_FAIL: 'UPDATE_COUNTRY_FAIL',
  UPDATE_COUNTRY_CLEAN: 'UPDATE_COUNTRY_CLEAN',
};

const INIT_STATE = {
  loading: false,
  error: false,
  data: [],
  amount: 10,

  deleteLoading: false,
  deleteError: false,
  deleteSuccess: false,

  createLoading: false,
  createError: false,
  createSuccess: false,

  idLoading: false,
  idError: false,
  idData: null,

  updateLoading: false,
  updateError: false,
  updateSuccess: false,
};

export default function Country(state = INIT_STATE, action) {
  switch (action.type) {
    case Types.GET_COUNTRY_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case Types.GET_COUNTRY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload.data || action.payload,
        amount: action.payload.total,
      };
    case Types.GET_COUNTRY_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case Types.DELETE_COUNTRY_REQUEST:
      return {
        ...state,
        deleteLoading: true,
        deleteError: false,
        deleteSuccess: false,
      };
    case Types.DELETE_COUNTRY_SUCCESS:
      return {
        ...state,
        deleteLoading: false,
        deleteError: false,
        deleteSuccess: true,
      };
    case Types.DELETE_COUNTRY_FAIL:
      return {
        ...state,
        deleteLoading: false,
        deleteError: true,
        deleteSuccess: false,
      };
    case Types.DELETE_COUNTRY_CLEAN:
      return {
        ...state,
        deleteLoading: false,
        deleteError: false,
        deleteSuccess: false,
      };

    case Types.CREATE_COUNTRY_REQUEST:
      return {
        ...state,
        createLoading: true,
        createError: false,
        createSuccess: false,
      };
    case Types.CREATE_COUNTRY_SUCCESS:
      return {
        ...state,
        createLoading: false,
        createError: false,
        createSuccess: true,
      };
    case Types.CREATE_COUNTRY_FAIL:
      return {
        ...state,
        createLoading: false,
        createError: true,
        createSuccess: false,
      };
    case Types.CREATE_COUNTRY_CLEAN:
      return {
        ...state,
        createLoading: false,
        createError: false,
        createSuccess: false,
      };

    case Types.GET_ID_COUNTRY_REQUEST:
      return {
        ...state,
        idLoading: true,
        idError: false,
      };
    case Types.GET_ID_COUNTRY_SUCCESS:
      return {
        ...state,
        idLoading: false,
        idError: false,
        idData: action.payload,
      };
    case Types.GET_ID_COUNTRY_FAIL:
      return {
        ...state,
        idLoading: false,
        idError: true,
      };
    case Types.GET_ID_COUNTRY_CLEAN:
      return {
        ...state,
        idLoading: false,
        idError: false,
        idData: null,
      };

    case Types.UPDATE_COUNTRY_REQUEST:
      return {
        ...state,
        updateLoading: true,
        updateError: false,
        updateSuccess: false,
      };
    case Types.UPDATE_COUNTRY_SUCCESS:
      return {
        ...state,
        updateLoading: false,
        updateError: false,
        updateSuccess: true,
      };
    case Types.UPDATE_COUNTRY_FAIL:
      return {
        ...state,
        updateLoading: false,
        updateError: true,
        updateSuccess: false,
      };
    case Types.UPDATE_COUNTRY_CLEAN:
      return {
        ...state,
        updateLoading: false,
        updateError: false,
        updateSuccess: false,
      };

    default:
      return state;
  }
}

export const Creators = {
  // GET
  getCountryListRequest: payload => ({
    type: Types.GET_COUNTRY_LIST_REQUEST,
    payload,
  }),
  getCountryListSuccess: payload => ({
    type: Types.GET_COUNTRY_LIST_SUCCESS,
    payload,
  }),
  getCountryListFail: () => ({
    type: Types.GET_COUNTRY_LIST_FAIL,
  }),

  // DELETE
  deleteCountryRequest: payload => ({
    type: Types.DELETE_COUNTRY_REQUEST,
    payload,
  }),
  deleteCountrySuccess: () => ({
    type: Types.DELETE_COUNTRY_SUCCESS,
  }),
  deleteCountryFail: () => ({
    type: Types.DELETE_COUNTRY_FAIL,
  }),
  deleteCountryClean: () => ({
    type: Types.DELETE_COUNTRY_CLEAN,
  }),

  // CREATE
  createCountryRequest: payload => ({
    type: Types.CREATE_COUNTRY_REQUEST,
    payload,
  }),
  createCountrySuccess: payload => ({
    type: Types.CREATE_COUNTRY_SUCCESS,
    payload,
  }),
  createCountryFail: () => ({
    type: Types.CREATE_COUNTRY_FAIL,
  }),
  createCountryClean: () => ({
    type: Types.CREATE_COUNTRY_CLEAN,
  }),

  // GET ID
  getIdCountryRequest: payload => ({
    type: Types.GET_ID_COUNTRY_REQUEST,
    payload,
  }),
  getIdCountrySuccess: payload => ({
    type: Types.GET_ID_COUNTRY_SUCCESS,
    payload,
  }),
  getIdCountryFail: () => ({
    type: Types.GET_ID_COUNTRY_FAIL,
  }),
  getIdCountryClean: () => ({
    type: Types.GET_ID_COUNTRY_CLEAN,
  }),

  // UPDATE
  updateCountryRequest: payload => ({
    type: Types.UPDATE_COUNTRY_REQUEST,
    payload,
  }),
  updateCountrySuccess: payload => ({
    type: Types.UPDATE_COUNTRY_SUCCESS,
    payload,
  }),
  updateCountryFail: () => ({
    type: Types.UPDATE_COUNTRY_FAIL,
  }),
  updateCountryClean: () => ({
    type: Types.UPDATE_COUNTRY_CLEAN,
  }),
};
