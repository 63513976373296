export const Types = {
  GET_PARTICIPANTS: 'GET_PARTICIPANTS',
  GET_PARTICIPANTS_SUCCESS: 'GET_PARTICIPANTS_SUCCESS',
  GET_PARTICIPANTS_ERROR: 'GET_PARTICIPANTS_ERROR',
  POST_PARTICIPANT: 'POST_PARTICIPANT',
  POST_PARTICIPANT_SUCCESS: 'POST_PARTICIPANT_SUCCESS',
  POST_PARTICIPANT_ERROR: 'POST_PARTICIPANT_ERROR',
  GET_PARTICIPANT_BY_ID: 'GET_PARTICIPANT_BY_ID',
  GET_PARTICIPANT_BY_ID_SUCCESS: 'GET_PARTICIPANT_BY_ID_SUCCESS',
  GET_PARTICIPANT_BY_ID_ERROR: 'GET_PARTICIPANT_BY_ID_ERROR',
  PUT_PARTICIPANT: 'PUT_PARTICIPANT',
  PUT_PARTICIPANT_SUCCESS: 'PUT_PARTICIPANT_SUCCESS',
  PUT_PARTICIPANT_ERROR: 'PUT_PARTICIPANT_ERROR',
  DELETE_PARTICIPANT: 'DELETE_PARTICIPANT',
  DELETE_PARTICIPANT_SUCCESS: 'DELETE_PARTICIPANT_SUCCESS',
  DELETE_PARTICIPANT_ERROR: 'DELETE_PARTICIPANT_ERROR',
  APPROVE_PARTICIPANT: 'APPROVE_PARTICIPANT',
  APPROVE_PARTICIPANT_SUCCESS: 'APPROVE_PARTICIPANT_SUCCESS',
  APPROVE_PARTICIPANT_ERROR: 'APPROVE_PARTICIPANT_ERROR',
  APPROVE_PARTICIPANT_RESET: 'APPROVE_PARTICIPANT_RESET',
  REFUSE_PARTICIPANT: 'REFUSE_PARTICIPANT',
  REFUSE_PARTICIPANT_SUCCESS: 'REFUSE_PARTICIPANT_SUCCESS',
  REFUSE_PARTICIPANT_ERROR: 'REFUSE_PARTICIPANT_ERROR',
  REFUSE_PARTICIPANT_RESET: 'REFUSE_PARTICIPANT_RESET',
  PARTICIPANT_CLEAN: 'PARTICIPANT_CLEAN',
};

const INIT_STATE = {
  data: null,
  participant: null,
  loading: false,
  error: null,
  postSuccess: false,
  deleteSuccess: false,

  approveLoading: false,
  approveSuccess: false,
  approveError: false,

  refuseLoading: false,
  refuseSuccess: false,
  refuseError: false,

  amount: 10,
};

const BENEFICIARY_ERROR = {
  CEG_NOT_FOUND:
    'CEG não cadastrado na plataforma. Contate o administrador do sistema',
  CEG_HAS_ALREADY_BEEN_USED: 'CEG já cadastrado em outro empreendimento.',
};

export default function Participant(state = INIT_STATE, action) {
  switch (action.type) {
    case Types.GET_PARTICIPANTS:
      return {
        ...state,
        data: null,
        loading: true,
        error: null,
      };
    case Types.GET_PARTICIPANTS_SUCCESS:
      return {
        ...state,
        data: action.payload.data || action.payload,
        amount: action.payload.total,
        loading: false,
        error: null,
      };
    case Types.GET_PARTICIPANTS_ERROR:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.payload,
      };
    case Types.POST_PARTICIPANT:
      return {
        ...state,
        postSuccess: false,
        loading: true,
        error: null,
      };
    case Types.POST_PARTICIPANT_SUCCESS:
      return {
        ...state,
        postSuccess: true,
        loading: false,
        error: null,
      };
    case Types.POST_PARTICIPANT_ERROR:
      return {
        ...state,
        postSuccess: false,
        loading: false,
        error:
          BENEFICIARY_ERROR?.[action.payload?.message] ||
          action.payload?.message,
      };
    case Types.GET_PARTICIPANT_BY_ID:
      return {
        ...state,
        participant: null,
        loading: true,
        error: false,
      };
    case Types.GET_PARTICIPANT_BY_ID_SUCCESS:
      return {
        ...state,
        participant: action.payload,
        loading: false,
        error: false,
      };
    case Types.GET_PARTICIPANT_BY_ID_ERROR:
      return {
        ...state,
        participant: null,
        loading: false,
        error: action.payload,
      };
    case Types.PUT_PARTICIPANT:
      return {
        ...state,
        postSuccess: false,
        loading: true,
        error: null,
      };
    case Types.PUT_PARTICIPANT_SUCCESS:
      return {
        ...state,
        postSuccess: true,
        loading: false,
        error: null,
      };
    case Types.PUT_PARTICIPANT_ERROR:
      return {
        ...state,
        postSuccess: false,
        loading: false,
        error: action.payload,
      };
    case Types.DELETE_PARTICIPANT:
      return {
        ...state,
        deleteSuccess: false,
        loading: true,
        error: null,
      };
    case Types.DELETE_PARTICIPANT_SUCCESS:
      return {
        ...state,
        deleteSuccess: true,
        loading: false,
        error: null,
      };
    case Types.DELETE_PARTICIPANT_ERROR:
      return {
        ...state,
        deleteSuccess: false,
        loading: false,
        error: action.payload,
      };
    case Types.PARTICIPANT_CLEAN:
      return {
        ...state,
        participant: null,
        postSuccess: false,
        deleteSuccess: false,
        loading: false,
        error: false,
      };

    case Types.APPROVE_PARTICIPANT:
      return {
        ...state,
        approveLoading: true,
        approveSuccess: false,
        approveError: false,
      };
    case Types.APPROVE_PARTICIPANT_SUCCESS:
      return {
        ...state,
        approveLoading: false,
        approveSuccess: true,
        approveError: false,
      };
    case Types.APPROVE_PARTICIPANT_ERROR:
      return {
        ...state,
        approveLoading: false,
        approveSuccess: false,
        approveError: action.payload,
      };
    case Types.APPROVE_PARTICIPANT_RESET:
      return {
        ...state,
        approveLoading: false,
        approveSuccess: false,
        approveError: false,
      };

    case Types.REFUSE_PARTICIPANT:
      return {
        ...state,
        refuseLoading: true,
        refuseSuccess: false,
        refuseError: false,
      };
    case Types.REFUSE_PARTICIPANT_SUCCESS:
      return {
        ...state,
        refuseLoading: false,
        refuseSuccess: true,
        refuseError: false,
      };
    case Types.REFUSE_PARTICIPANT_ERROR:
      return {
        ...state,
        refuseLoading: false,
        refuseSuccess: false,
        refuseError: true,
      };
    case Types.REFUSE_PARTICIPANT_RESET:
      return {
        ...state,
        refuseLoading: false,
        refuseSuccess: false,
        refuseError: false,
      };
    default:
      return state;
  }
}

export const Creators = {
  getParticipants: payload => ({
    type: Types.GET_PARTICIPANTS,
    payload,
  }),
  getParticipantsSuccess: payload => ({
    type: Types.GET_PARTICIPANTS_SUCCESS,
    payload,
  }),
  getParticipantsError: payload => ({
    type: Types.GET_PARTICIPANTS_ERROR,
    payload,
  }),
  postParticipant: payload => ({
    type: Types.POST_PARTICIPANT,
    payload,
  }),
  postParticipantSuccess: payload => ({
    type: Types.POST_PARTICIPANT_SUCCESS,
    payload,
  }),
  postParticipantError: payload => ({
    type: Types.POST_PARTICIPANT_ERROR,
    payload,
  }),
  getParticipantById: payload => ({
    type: Types.GET_PARTICIPANT_BY_ID,
    payload,
  }),
  getParticipantByIdSuccess: payload => ({
    type: Types.GET_PARTICIPANT_BY_ID_SUCCESS,
    payload,
  }),
  getParticipantByIdError: payload => ({
    type: Types.GET_PARTICIPANT_BY_ID_ERROR,
    payload,
  }),
  putParticipant: payload => ({
    type: Types.PUT_PARTICIPANT,
    payload,
  }),
  putParticipantSuccess: payload => ({
    type: Types.PUT_PARTICIPANT_SUCCESS,
    payload,
  }),
  putParticipantError: payload => ({
    type: Types.PUT_PARTICIPANT_ERROR,
    payload,
  }),
  deleteParticipant: payload => ({
    type: Types.DELETE_PARTICIPANT,
    payload,
  }),
  deleteParticipantSuccess: payload => ({
    type: Types.DELETE_PARTICIPANT_SUCCESS,
    payload,
  }),
  deleteParticipantError: payload => ({
    type: Types.DELETE_PARTICIPANT_ERROR,
    payload,
  }),
  participantClean: () => ({
    type: Types.PARTICIPANT_CLEAN,
  }),
  approveParticipant: payload => ({
    type: Types.APPROVE_PARTICIPANT,
    payload,
  }),
  approveParticipantSuccess: () => ({
    type: Types.APPROVE_PARTICIPANT_SUCCESS,
  }),
  approveParticipantError: payload => ({
    type: Types.APPROVE_PARTICIPANT_ERROR,
    payload,
  }),
  approveParticipantReset: () => ({
    type: Types.APPROVE_PARTICIPANT_RESET,
  }),
  refuseParticipant: payload => ({
    type: Types.REFUSE_PARTICIPANT,
    payload,
  }),
  refuseParticipantSuccess: () => ({
    type: Types.REFUSE_PARTICIPANT_SUCCESS,
  }),
  refuseParticipantError: () => ({
    type: Types.REFUSE_PARTICIPANT_ERROR,
  }),
  refuseParticipantReset: () => ({
    type: Types.REFUSE_PARTICIPANT_RESET,
  }),
};

// export const Types = {
//   PARTICIPANT_CLEAN: 'PARTICIPANT_CLEAN',
//   POST_PARTICIPANT: 'POST_PARTICIPANT',
//
//   GET_LIST_PARTICIPANT_REQUEST: 'GET_LIST_PARTICIPANT_REQUEST',
//   GET_LIST_PARTICIPANT_SUCCESS: 'GET_LIST_PARTICIPANT_SUCCESS',
//   GET_LIST_PARTICIPANT_FAIL: 'GET_LIST_PARTICIPANT_FAIL',
//
//   DELETE_PARTICIPANT_REQUEST: 'DELETE_PARTICIPANT_REQUEST',
//   DELETE_PARTICIPANT_SUCCESS: 'DELETE_PARTICIPANT_SUCCESS',
//   DELETE_PARTICIPANT_FAIL: 'DELETE_PARTICIPANT_FAIL',
//   DELETE_PARTICIPANT_CLEAN: 'DELETE_PARTICIPANT_CLEAN',
//
//   GET_ID_PARTICIPANT_REQUEST: 'GET_ID_PARTICIPANT_REQUEST',
//   GET_ID_PARTICIPANT_SUCCESS: 'GET_ID_PARTICIPANT_SUCCESS',
//   GET_ID_PARTICIPANT_FAIL: 'GET_ID_PARTICIPANT_FAIL',
//
//   CREATE_PARTICIPANT_REQUEST: 'CREATE_PARTICIPANT_REQUEST',
//   CREATE_PARTICIPANT_SUCCESS: 'CREATE_PARTICIPANT_SUCCESS',
//   CREATE_PARTICIPANT_FAIL: 'CREATE_PARTICIPANT_FAIL',
//   CREATE_PARTICIPANT_CLEAN: 'CREATE_PARTICIPANT_CLEAN',
//
//   UPDATE_PARTICIPANT_REQUEST: 'UPDATE_PARTICIPANT_REQUEST',
//   UPDATE_PARTICIPANT_SUCCESS: 'UPDATE_PARTICIPANT_SUCCESS',
//   UPDATE_PARTICIPANT_FAIL: 'UPDATE_PARTICIPANT_FAIL',
//   UPDATE_PARTICIPANT_CLEAN: 'UPDATE_PARTICIPANT_CLEAN',
// };
//
// const INIT_STATE = {
//   amount: 10,
//
//   data: null,
//   loading: false,
//   error: false,
//
//   deleteLoading: false,
//   deleteSuccess: false,
//   deleteError: false,
//
//   idLoading: false,
//   idData: null,
//   idError: false,
//
//   createLoading: false,
//   createSuccess: false,
//   createError: false,
//
//   updateLoading: false,
//   updateSuccess: false,
//   updateError: false,
// };
//
// export default function Participant(state = INIT_STATE, action) {
//   switch (action.type) {
//     case Types.PARTICIPANT_CLEAN:
//       return {
//         ...state,
//         participant: null,
//         postSuccess: false,
//         deleteSuccess: false,
//         loading: false,
//         error: false,
//       };
//
//     case Types.POST_PARTICIPANT:
//       return {
//         ...state,
//         postSuccess: false,
//         loading: true,
//         error: null,
//       };
//
//     case Types.GET_LIST_PARTICIPANT_REQUEST:
//       return {
//         ...state,
//         loading: true,
//         error: false,
//       };
//     case Types.GET_LIST_PARTICIPANT_SUCCESS:
//       return {
//         ...state,
//         loading: false,
//         error: false,
//         data: action.payload.data || action.payload,
//         amount: action.payload.total,
//       };
//     case Types.GET_LIST_PARTICIPANT_FAIL:
//       return {
//         ...state,
//         loading: false,
//         error: true,
//       };
//
//     case Types.DELETE_PARTICIPANT_REQUEST:
//       return {
//         ...state,
//         deleteLoading: true,
//         deleteError: false,
//         deleteSuccess: false,
//       };
//     case Types.DELETE_PARTICIPANT_SUCCESS:
//       return {
//         ...state,
//         deleteLoading: false,
//         deleteError: false,
//         deleteSuccess: true,
//       };
//     case Types.DELETE_PARTICIPANT_FAIL:
//       return {
//         ...state,
//         deleteLoading: false,
//         deleteError: true,
//         deleteSuccess: false,
//       };
//     case Types.DELETE_PARTICIPANT_CLEAN:
//       return {
//         ...state,
//         deleteLoading: false,
//         deleteError: false,
//         deleteSuccess: false,
//       };
//
//     case Types.GET_ID_PARTICIPANT_REQUEST:
//       return {
//         ...state,
//         idLoading: true,
//         idError: false,
//       };
//     case Types.GET_ID_PARTICIPANT_SUCCESS:
//       return {
//         ...state,
//         idLoading: false,
//         idError: false,
//         idData: action.payload,
//       };
//     case Types.GET_ID_PARTICIPANT_FAIL:
//       return {
//         ...state,
//         idLoading: false,
//         idError: true,
//       };
//
//     case Types.CREATE_PARTICIPANT_REQUEST:
//       return {
//         ...state,
//         createLoading: true,
//         createError: false,
//         createSuccess: false,
//       };
//     case Types.CREATE_PARTICIPANT_SUCCESS:
//       return {
//         ...state,
//         createLoading: false,
//         createError: false,
//         createSuccess: true,
//       };
//     case Types.CREATE_PARTICIPANT_FAIL:
//       return {
//         ...state,
//         createLoading: false,
//         createError: true,
//         createSuccess: false,
//       };
//     case Types.CREATE_PARTICIPANT_CLEAN:
//       return {
//         ...state,
//         createLoading: false,
//         createError: false,
//         createSuccess: false,
//       };
//
//     case Types.UPDATE_PARTICIPANT_REQUEST:
//       return {
//         ...state,
//         updateLoading: true,
//         updateError: false,
//         updateSuccess: false,
//       };
//     case Types.UPDATE_PARTICIPANT_SUCCESS:
//       return {
//         ...state,
//         updateLoading: false,
//         updateError: false,
//         updateSuccess: true,
//       };
//     case Types.UPDATE_PARTICIPANT_FAIL:
//       return {
//         ...state,
//         updateLoading: false,
//         updateError: true,
//         updateSuccess: false,
//       };
//     case Types.UPDATE_PARTICIPANT_CLEAN:
//       return {
//         ...state,
//         updateLoading: false,
//         updateError: false,
//         updateSuccess: false,
//       };
//
//     default:
//       return state;
//   }
// }
//
// export const Creators = {
//   ParticipantClean: () => ({
//     type: Types.PARTICIPANT_CLEAN,
//   }),
//
//   postParticipant: (payload) => ({
//     type: Types.POST_PARTICIPANT,
//     payload,
//   }),
//
//   // GET
//   getListParticipantRequest: payload => ({
//     type: Types.GET_LIST_PARTICIPANT_REQUEST,
//     payload,
//   }),
//   getListParticipantSuccess: payload => ({
//     type: Types.GET_LIST_PARTICIPANT_SUCCESS,
//     payload,
//   }),
//   getListParticipantFail: () => ({
//     type: Types.GET_LIST_PARTICIPANT_FAIL,
//   }),
//
//   // DELETE
//   deleteParticipantRequest: payload => ({
//     type: Types.DELETE_PARTICIPANT_REQUEST,
//     payload,
//   }),
//   deleteParticipantSuccess: () => ({
//     type: Types.DELETE_PARTICIPANT_SUCCESS,
//   }),
//   deleteParticipantFail: () => ({
//     type: Types.DELETE_PARTICIPANT_FAIL,
//   }),
//   deleteParticipantClean: () => ({
//     type: Types.DELETE_PARTICIPANT_CLEAN,
//   }),
//
//   // ID
//   getIdParticipantRequest: payload => ({
//     type: Types.GET_ID_PARTICIPANT_REQUEST,
//     payload,
//   }),
//   getIdParticipantSuccess: payload => ({
//     type: Types.GET_ID_PARTICIPANT_SUCCESS,
//     payload,
//   }),
//   getIdParticipantFail: () => ({
//     type: Types.GET_ID_PARTICIPANT_FAIL,
//   }),
//
//   // CREATE
//   createParticipantRequest: payload => ({
//     type: Types.CREATE_PARTICIPANT_REQUEST,
//     payload,
//   }),
//   createParticipantSuccess: () => ({
//     type: Types.CREATE_PARTICIPANT_SUCCESS,
//   }),
//   createParticipantFail: () => ({
//     type: Types.CREATE_PARTICIPANT_FAIL,
//   }),
//   createParticipantClean: () => ({
//     type: Types.CREATE_PARTICIPANT_CLEAN,
//   }),
//
//   // UPDATE
//   updateParticipantRequest: payload => ({
//     type: Types.UPDATE_PARTICIPANT_REQUEST,
//     payload,
//   }),
//   updateParticipantSuccess: payload => ({
//     type: Types.UPDATE_PARTICIPANT_SUCCESS,
//     payload,
//   }),
//   updateParticipantFail: () => ({
//     type: Types.UPDATE_PARTICIPANT_FAIL,
//   }),
//   updateParticipantClean: () => ({
//     type: Types.UPDATE_PARTICIPANT_CLEAN,
//   }),
// };
