import axios from 'axios';
import http from 'http';
import https from 'https';
import { getToken } from './index';

const httpAgent = new http.Agent({ keepAlive: false });
const httpsAgent = new https.Agent({ keepAlive: false });

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  httpAgent,
  httpsAgent,
});

api.interceptors.request.use(async config => {
  const cfg = config;
  const token = await getToken();
  cfg.headers['Accept-Language'] = 'pt';
  if (token) {
    cfg.headers.Authorization = `Bearer ${token}`;
  }
  return cfg;
});

api.interceptors.response.use(
  response => response,
  error => {
    if (error.response.data.errors) {
      console.log('');
    }
    if (error.config && error.response && error.response.status === 401) {
      console.log('');
    }

    return Promise.reject(error);
  }
);

export default api;
