export const Types = {
  GET_ORGANIZATIONS: 'GET_ORGANIZATIONS',
  GET_ORGANIZATIONS_SUCCESS: 'GET_ORGANIZATIONS_SUCCESS',
  GET_ORGANIZATIONS_ERROR: 'GET_ORGANIZATIONS_ERROR',
};

const INIT_STATE = {
  organizationsLoading: false,
  organizationsLoadingSuccess: false,
  organizations: [],
};

export default function Evident(state = INIT_STATE, action) {
  switch (action.type) {
    case Types.GET_ORGANIZATIONS:
      return {
        ...state,
        organizationsLoading: true,
        organizationsLoadingSuccess: false,
      };
    case Types.GET_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        organizations: action.payload,
        organizationsLoading: false,
        organizationsLoadingSuccess: true,
      };
    case Types.GET_ORGANIZATIONS_ERROR:
      return {
        ...state,
        organizationsLoading: false,
        organizationsLoadingSuccess: false,
      };
    default:
      return state;
  }
}

export const Creators = {
  getOrganizations: () => ({
    type: Types.GET_ORGANIZATIONS,
  }),
  getOrganizationsSuccess: (payload) => ({
    type: Types.GET_ORGANIZATIONS_SUCCESS,
    payload,
  }),
  getOrganizationsError: () => ({
    type: Types.GET_ORGANIZATIONS_ERROR,
  }),
};
