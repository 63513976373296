import { all, call, put, takeLatest, fork } from 'redux-saga/effects';
import { Creators as RdmsActions, Types as RdmsTypes } from 'store/ducks/Rdm';
import api from '../../util/Api';

function* getRdms({ payload }) {
  try {
    const { status, data } = yield call(api.get, '/rdms', {
      params: payload,
    });
    if (status === 200) {
      yield put(RdmsActions.getRdmsSuccess(data));
    } else {
      yield put(RdmsActions.getRdmsError());
    }
  } catch (err) {
    yield put(RdmsActions.getRdmsError());
  }
}

function* getRecsWatcher() {
  yield takeLatest(RdmsTypes.GET_RDMS, getRdms);
}

export default function* rootSaga() {
  yield all([fork(getRecsWatcher)]);
}
