import { all, call, put, takeLatest, fork } from 'redux-saga/effects';
import {
  Creators as CountryActions,
  Types as CountryTypes,
} from 'store/ducks/Country';
import api from '../../util/Api';

function* getCountry({ payload }) {
  try {
    const { status, data } = yield call(api.get, `/countries`, {
      params: payload,
    });
    if (status === 200) {
      yield put(CountryActions.getCountryListSuccess(data));
    } else {
      yield put(CountryActions.getCountryListFail());
    }
  } catch (err) {
    yield put(CountryActions.getCountryListFail());
  }
}

function* deleteCountry({ payload }) {
  try {
    const { status } = yield call(api.delete, `/countries/${payload}`);
    if (status === 200) {
      yield put(CountryActions.deleteCountrySuccess());
    } else {
      yield put(CountryActions.deleteCountryFail());
    }
  } catch (err) {
    yield put(CountryActions.deleteCountryFail());
  }
}

function* createCountry({ payload }) {
  try {
    const { status } = yield call(api.post, '/countries', payload);
    if (status === 200) {
      yield put(CountryActions.createCountrySuccess());
    } else {
      yield put(CountryActions.createCountryFail());
    }
  } catch (err) {
    yield put(CountryActions.createCountryFail());
  }
}

function* getIdCountry({ payload }) {
  try {
    const { status, data } = yield call(api.get, `/countries/${payload}`);
    if (status === 200) {
      yield put(CountryActions.getIdCountrySuccess(data));
    } else {
      yield put(CountryActions.getIdCountryFail());
    }
  } catch (err) {
    yield put(CountryActions.getIdCountryFail());
  }
}

function* updateCountry({ payload }) {
  try {
    const { status } = yield call(
      api.put,
      `countries/${payload.id}`,
      payload.data
    );
    if (status === 200) {
      yield put(CountryActions.updateCountrySuccess());
    } else {
      yield put(CountryActions.updateCountryFail());
    }
  } catch (err) {
    yield put(CountryActions.updateCountryFail());
  }
}

function* getCountryWatcher() {
  yield takeLatest(CountryTypes.GET_COUNTRY_LIST_REQUEST, getCountry);
}

function* deleteCountryWatcher() {
  yield takeLatest(CountryTypes.DELETE_COUNTRY_REQUEST, deleteCountry);
}

function* createCountryWatcher() {
  yield takeLatest(CountryTypes.CREATE_COUNTRY_REQUEST, createCountry);
}

function* getIdCountryWatcher() {
  yield takeLatest(CountryTypes.GET_ID_COUNTRY_REQUEST, getIdCountry);
}

function* updateCountryWatcher() {
  yield takeLatest(CountryTypes.UPDATE_COUNTRY_REQUEST, updateCountry);
}

export default function* rootSaga() {
  yield all([
    fork(getCountryWatcher),
    fork(deleteCountryWatcher),
    fork(createCountryWatcher),
    fork(getIdCountryWatcher),
    fork(updateCountryWatcher),
  ]);
}
