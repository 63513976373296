
import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import {
  Types as ParticipantTypes,
  Creators as ParticipantActions,
} from '../ducks/Participant';
import api from '../../util/Api';

function* getParticipants({ payload }) {
  try {
    const { status, data } = yield call(api.get, '/participants', {
      params: payload,
    });
    if (status === 200) {
      yield put(ParticipantActions.getParticipantsSuccess(data));
    }
  } catch (error) {
    yield put(
      ParticipantActions.getParticipantsError('Erro ao listar Empreendimentos')
    );
  }
}

function* postParticipant({ payload }) {
  try {
    const { status, data } = yield call(api.post, '/participants', payload.data);
    if (status === 200) {
      yield put(ParticipantActions.postParticipantSuccess());
    }
  } catch (error) {
    yield put(ParticipantActions.postParticipantError(error?.response?.data));
  }
}

function* getParticipantById({ payload }) {
  const { id, params, edit } = payload;
  try {
    const { status, data } = yield call(api.get, `/participants/${id}`, {
      params,
    });
    if (status === 200) {
      // if (edit && data?.approved !== 'APROVADO') {
      //   yield put(
      //       ParticipantActions.getParticipantByIdError(
      //           'Empreendimento não encontrado'
      //       )
      //   );
      // }
      yield put(ParticipantActions.getParticipantByIdSuccess(data));
    }
  } catch (error) {
    yield put(
      ParticipantActions.getParticipantByIdError('Participante não encontrado')
    );
  }
}

function* putParticipant({ payload }) {
  const { id, data: playloadData } = payload;
  try {
    const { status } = yield call(api.put, `/participants/${id}`, playloadData);
    if (status === 200) {
      yield put(ParticipantActions.putParticipantSuccess());
    }
  } catch (error) {
    yield put(
      ParticipantActions.putParticipantError('Erro ao editar Participante')
    );
  }
}

function* deleteParticipant({ payload }) {
  try {
    const { status } = yield call(api.delete, `/participants/${payload}`);
    if (status === 200) {
      yield put(ParticipantActions.deleteParticipantSuccess());
    }
  } catch (error) {
    yield put(
      ParticipantActions.deleteParticipantError('Erro ao excluir Participante')
    );
  }
}

function* approveParticipant({ payload }) {
  try {
    const { status } = yield call(api.post, `/participants/approve/${payload}`);
    if (status === 200) {
      yield put(ParticipantActions.approveParticipantSuccess());
    }
  } catch (error) {
    yield put(
      ParticipantActions.approveParticipantError(error?.response?.data?.message)
    );
  }
}

function* refuseParticipant({ payload }) {
  const { id, data } = payload;
  try {
    const { status } = yield call(api.post, `/participants/refuse/${id}`, data);
    if (status === 200) {
      yield put(ParticipantActions.refuseParticipantSuccess());
    }
  } catch (error) {
    yield put(ParticipantActions.refuseParticipantError());
  }
}

function* getParticipantsWatcher() {
  yield takeLatest(ParticipantTypes.GET_PARTICIPANTS, getParticipants);
}

function* postParticipantWatcher() {
  yield takeLatest(ParticipantTypes.POST_PARTICIPANT, postParticipant);
}

function* getParticipantByIdWatcher() {
  yield takeLatest(ParticipantTypes.GET_PARTICIPANT_BY_ID, getParticipantById);
}

function* putParticipantWatcher() {
  yield takeLatest(ParticipantTypes.PUT_PARTICIPANT, putParticipant);
}

function* deleteParticipantWatcher() {
  yield takeLatest(ParticipantTypes.DELETE_PARTICIPANT, deleteParticipant);
}

function* approveParticipantWatcher() {
  yield takeLatest(ParticipantTypes.APPROVE_PARTICIPANT, approveParticipant);
}

function* refuseParticipantWatcher() {
  yield takeLatest(ParticipantTypes.REFUSE_PARTICIPANT, refuseParticipant);
}

export default function* rootSaga() {
  yield all([
    fork(getParticipantsWatcher),
    fork(postParticipantWatcher),
    fork(getParticipantByIdWatcher),
    fork(putParticipantWatcher),
    fork(deleteParticipantWatcher),
    fork(approveParticipantWatcher),
    fork(refuseParticipantWatcher),
  ]);
}

// import { all, call, put, takeLatest, fork } from 'redux-saga/effects';
// import {
//   Creators as ParticipantActions,
//   Types as ParticipantTypes,
// } from 'store/ducks/Participant';
// import api from '../../util/Api';
// import {Creators as RegistrantActions} from "../ducks/Registrant";
//
// function* getList({ payload }) {
//   try {
//     const { status, data } = yield call(api.get, '/participants', {
//       params: payload,
//     });
//     if (status === 200) {
//       yield put(ParticipantActions.getListParticipantSuccess(data));
//     } else {
//       yield put(ParticipantActions.getListParticipantFail());
//     }
//   } catch (err) {
//     yield put(ParticipantActions.getListParticipantFail());
//   }
// }
//
// function* deleteParticipant({ payload }) {
//   try {
//     const { status } = yield call(api.delete, `/participants/${payload}`);
//     if (status === 200) {
//       yield put(ParticipantActions.deleteParticipantSuccess());
//     } else {
//       yield put(ParticipantActions.deleteParticipantFail());
//     }
//   } catch (err) {
//     yield put(ParticipantActions.deleteParticipantFail());
//   }
// }
//
// function* getIdParticipant({ payload }) {
//   try {
//     const { status, data } = yield call(api.get, `/participants/${payload}`);
//     if (status === 200) {
//       yield put(ParticipantActions.getIdParticipantSuccess(data));
//     } else {
//       yield put(ParticipantActions.getIdParticipantFail());
//     }
//   } catch (err) {
//     yield put(ParticipantActions.getIdParticipantFail());
//   }
// }
//
// function* createParticipantRequest({ payload }) {
//   try {
//     const { status } = yield call(api.post, '/participants', payload);
//     if (status === 200) {
//       yield put(ParticipantActions.createParticipantSuccess());
//     } else {
//       yield put(ParticipantActions.createParticipantFail());
//     }
//   } catch (e) {
//     yield put(ParticipantActions.createParticipantFail());
//   }
// }
//
// function* postParticipant({ payload }) {
//   try {
//     const { status } = yield call(api.post, '/participants', payload);
//     if (status === 200) {
//       yield put(ParticipantActions.createParticipantSuccess());
//     }
//   } catch (error) {
//     console.log(error.response.data.message);
//     if (error.response.data.message === 'ER_DUP_ENTRY') {
//       yield put(ParticipantActions.createParticipantFail('E-mail já cadastrado'));
//     } else {
//       yield put(
//           ParticipantActions.createParticipantFail('Erro ao cadastrar registrante')
//       );
//     }
//   }
// }
//
// function* updateParticipant({ payload }) {
//   try {
//     const { status } = yield call(
//       api.put,
//       `/participants/${payload.id}`,
//       payload.data
//     );
//     if (status === 200) {
//       yield put(ParticipantActions.updateParticipantSuccess());
//     } else {
//       yield put(ParticipantActions.updateParticipantFail());
//     }
//   } catch (e) {
//     yield put(ParticipantActions.updateParticipantFail());
//   }
// }
//
// function* getListWatcher() {
//   yield takeLatest(ParticipantTypes.GET_LIST_PARTICIPANT_REQUEST, getList);
// }
//
// function* deleteParticipantWatcher() {
//   yield takeLatest(
//     ParticipantTypes.DELETE_PARTICIPANT_REQUEST,
//     deleteParticipant
//   );
// }
//
// function* getIdParticipantWatcher() {
//   yield takeLatest(
//     ParticipantTypes.GET_ID_PARTICIPANT_REQUEST,
//     getIdParticipant
//   );
// }
//
// function* createParticipantWatcher() {
//   yield takeLatest(
//     ParticipantTypes.CREATE_PARTICIPANT_REQUEST,
//     createParticipantRequest
//   );
// }
//
// function* updateParticipantWatcher() {
//   yield takeLatest(
//     ParticipantTypes.UPDATE_PARTICIPANT_REQUEST,
//     updateParticipant
//   );
// }
//
// export default function* rootSaga() {
//   yield all([
//     fork(getListWatcher),
//     fork(deleteParticipantWatcher),
//     fork(getIdParticipantWatcher),
//     fork(createParticipantWatcher),
//     fork(updateParticipantWatcher),
//   ]);
// }
