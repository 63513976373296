export const Types = {
  GET_REDEMPTIONS_AUTOMATIONS: 'GET_REDEMPTIONS_AUTOMATIONS',
  GET_REDEMPTIONS_AUTOMATIONS_SUCCESS: 'GET_REDEMPTIONS_AUTOMATIONS_SUCCESS',
  GET_REDEMPTIONS_AUTOMATIONS_ERROR: 'GET_REDEMPTIONS_AUTOMATIONS_ERROR',
  POST_REDEMPTION_AUTOMATION: 'POST_REDEMPTION_AUTOMATION',
  POST_REDEMPTION_AUTOMATION_SUCCESS: 'POST_REDEMPTION_AUTOMATION_SUCCESS',
  POST_REDEMPTION_AUTOMATION_ERROR: 'POST_REDEMPTION_AUTOMATION_ERROR',
  GET_REDEMPTION_AUTOMATION_BY_ID: 'GET_REDEMPTION_AUTOMATION_BY_ID',
  GET_REDEMPTION_AUTOMATION_BY_ID_SUCCESS: 'GET_REDEMPTION_AUTOMATION_BY_ID_SUCCESS',
  GET_REDEMPTION_AUTOMATION_BY_ID_ERROR: 'GET_REDEMPTION_AUTOMATION_BY_ID_ERROR',
  PUT_REDEMPTION_AUTOMATION: 'PUT_REDEMPTION_AUTOMATION',
  PUT_REDEMPTION_AUTOMATION_SUCCESS: 'PUT_REDEMPTION_AUTOMATION_SUCCESS',
  PUT_REDEMPTION_AUTOMATION_ERROR: 'PUT_REDEMPTION_AUTOMATION_ERROR',
  DELETE_REDEMPTION_AUTOMATION: 'DELETE_REDEMPTION_AUTOMATION',
  DELETE_REDEMPTION_AUTOMATION_SUCCESS: 'DELETE_REDEMPTION_AUTOMATION_SUCCESS',
  DELETE_REDEMPTION_AUTOMATION_ERROR: 'DELETE_REDEMPTION_AUTOMATION_ERROR',
  APPROVE_REDEMPTION_AUTOMATION: 'APPROVE_REDEMPTION_AUTOMATION',
  APPROVE_REDEMPTION_AUTOMATION_SUCCESS: 'APPROVE_REDEMPTION_AUTOMATION_SUCCESS',
  APPROVE_REDEMPTION_AUTOMATION_ERROR: 'APPROVE_REDEMPTION_AUTOMATION_ERROR',
  APPROVE_REDEMPTION_AUTOMATION_RESET: 'APPROVE_REDEMPTION_AUTOMATION_RESET',
  START_REDEMPTION_AUTOMATION: 'START_REDEMPTION_AUTOMATION',
  START_REDEMPTION_AUTOMATION_SUCCESS: 'START_REDEMPTION_AUTOMATION_SUCCESS',
  START_REDEMPTION_AUTOMATION_ERROR: 'START_REDEMPTION_AUTOMATION_ERROR',
  START_REDEMPTION_AUTOMATION_RESET: 'START_REDEMPTION_AUTOMATION_RESET',
  REDEMPTION_AUTOMATION_CLEAN: 'REDEMPTION_AUTOMATION_CLEAN',
};

const INIT_STATE = {
  data: null,
  redemptionAutomation: null,
  loading: false,
  error: null,
  postSuccess: false,
  deleteSuccess: false,

  approveLoading: false,
  approveSuccess: false,
  approveError: false,

  startLoading: false,
  startSuccess: false,
  startError: false,

  itemsLoading: false,
  itemsSuccess: false,
  itemsError: false,

  amount: 10,
};

const REDEMPTION_ERROR = {
  CEG_NOT_FOUND:
    'CEG não cadastrado na plataforma. Contate o administrador do sistema',
  CEG_HAS_ALREADY_BEEN_USED: 'CEG já cadastrado em outro empreendimento.',
};

export default function RedemptionAutomation(state = INIT_STATE, action) {
  switch (action.type) {
    case Types.GET_REDEMPTIONS_AUTOMATIONS:
      return {
        ...state,
        data: null,
        loading: true,
        error: null,
      };
    case Types.GET_REDEMPTIONS_AUTOMATIONS_SUCCESS:
      return {
        ...state,
        data: action.payload.data || action.payload,
        amount: action.payload.total,
        loading: false,
        error: null,
      };
    case Types.GET_REDEMPTIONS_AUTOMATIONS_ERROR:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.payload,
      };
    case Types.POST_REDEMPTION_AUTOMATION:
      return {
        ...state,
        postSuccess: false,
        loading: true,
        error: null,
      };
    case Types.POST_REDEMPTION_AUTOMATION_SUCCESS:
      return {
        ...state,
        postSuccess: true,
        loading: false,
        error: null,
      };
    case Types.POST_REDEMPTION_AUTOMATION_ERROR:
      return {
        ...state,
        postSuccess: false,
        loading: false,
        error:
          REDEMPTION_ERROR?.[action.payload?.message] ||
          action.payload?.message,
      };
    case Types.GET_REDEMPTION_AUTOMATION_BY_ID:
      return {
        ...state,
        redemptionAutomation: null,
        loading: true,
        error: false,
      };
    case Types.GET_REDEMPTION_AUTOMATION_BY_ID_SUCCESS:
      return {
        ...state,
        redemptionAutomation: action.payload,
        loading: false,
        error: false,
      };
    case Types.GET_REDEMPTION_AUTOMATION_BY_ID_ERROR:
      return {
        ...state,
        redemptionAutomation: null,
        loading: false,
        error: action.payload,
      };
    case Types.PUT_REDEMPTION_AUTOMATION:
      return {
        ...state,
        postSuccess: false,
        loading: true,
        error: null,
      };
    case Types.PUT_REDEMPTION_AUTOMATION_SUCCESS:
      return {
        ...state,
        postSuccess: true,
        loading: false,
        error: null,
      };
    case Types.PUT_REDEMPTION_AUTOMATION_ERROR:
      return {
        ...state,
        postSuccess: false,
        loading: false,
        error: action.payload,
      };
    case Types.DELETE_REDEMPTION_AUTOMATION:
      return {
        ...state,
        deleteSuccess: false,
        loading: true,
        error: null,
      };
    case Types.DELETE_REDEMPTION_AUTOMATION_SUCCESS:
      return {
        ...state,
        deleteSuccess: true,
        loading: false,
        error: null,
      };
    case Types.DELETE_REDEMPTION_AUTOMATION_ERROR:
      return {
        ...state,
        deleteSuccess: false,
        loading: false,
        error: action.payload,
      };
    case Types.REDEMPTION_AUTOMATION_CLEAN:
      return {
        ...state,
        redemptionAutomation: null,
        postSuccess: false,
        deleteSuccess: false,
        loading: false,
        error: false,
      };

    case Types.APPROVE_REDEMPTION_AUTOMATION:
      return {
        ...state,
        approveLoading: true,
        approveSuccess: false,
        approveError: false,
      };
    case Types.APPROVE_REDEMPTION_AUTOMATION_SUCCESS:
      return {
        ...state,
        approveLoading: false,
        approveSuccess: true,
        approveError: false,
      };
    case Types.APPROVE_REDEMPTION_AUTOMATION_ERROR:
      return {
        ...state,
        approveLoading: false,
        approveSuccess: false,
        approveError: action.payload,
      };
    case Types.APPROVE_REDEMPTION_AUTOMATION_RESET:
      return {
        ...state,
        approveLoading: false,
        approveSuccess: false,
        approveError: false,
      };

    case Types.START_REDEMPTION_AUTOMATION:
      return {
        ...state,
        startLoading: true,
        startSuccess: false,
        startError: false,
      };
    case Types.START_REDEMPTION_AUTOMATION_SUCCESS:
      return {
        ...state,
        startLoading: false,
        startSuccess: true,
        startError: false,
      };
    case Types.START_REDEMPTION_AUTOMATION_ERROR:
      return {
        ...state,
        startLoading: false,
        startSuccess: false,
        startError: action.payload,
      };
    case Types.START_REDEMPTION_AUTOMATION_RESET:
      return {
        ...state,
        startLoading: false,
        startSuccess: false,
        startError: false,
      };

    default:
      return state;
  }
}

export const Creators = {
  getRedemptionsAutomations: payload => ({
    type: Types.GET_REDEMPTIONS_AUTOMATIONS,
    payload,
  }),
  getRedemptionsAutomationsSuccess: payload => ({
    type: Types.GET_REDEMPTIONS_AUTOMATIONS_SUCCESS,
    payload,
  }),
  getRedemptionsAutomationsError: payload => ({
    type: Types.GET_REDEMPTIONS_AUTOMATIONS_ERROR,
    payload,
  }),
  postRedemptionAutomation: payload => ({
    type: Types.POST_REDEMPTION_AUTOMATION,
    payload,
  }),
  postRedemptionAutomationSuccess: payload => ({
    type: Types.POST_REDEMPTION_AUTOMATION_SUCCESS,
    payload,
  }),
  postRedemptionAutomationError: payload => ({
    type: Types.POST_REDEMPTION_AUTOMATION_ERROR,
    payload,
  }),
  getRedemptionAutomationById: payload => ({
    type: Types.GET_REDEMPTION_AUTOMATION_BY_ID,
    payload,
  }),
  getRedemptionAutomationByIdSuccess: payload => ({
    type: Types.GET_REDEMPTION_AUTOMATION_BY_ID_SUCCESS,
    payload,
  }),
  getRedemptionAutomationByIdError: payload => ({
    type: Types.GET_REDEMPTION_AUTOMATION_BY_ID_ERROR,
    payload,
  }),
  putRedemptionAutomation: payload => ({
    type: Types.PUT_REDEMPTION_AUTOMATION,
    payload,
  }),
  putRedemptionAutomationSuccess: payload => ({
    type: Types.PUT_REDEMPTION_AUTOMATION_SUCCESS,
    payload,
  }),
  putRedemptionAutomationError: payload => ({
    type: Types.PUT_REDEMPTION_AUTOMATION_ERROR,
    payload,
  }),
  deleteRedemptionAutomation: payload => ({
    type: Types.DELETE_REDEMPTION_AUTOMATION,
    payload,
  }),
  deleteRedemptionAutomationSuccess: payload => ({
    type: Types.DELETE_REDEMPTION_AUTOMATION_SUCCESS,
    payload,
  }),
  deleteRedemptionAutomationError: payload => ({
    type: Types.DELETE_REDEMPTION_AUTOMATION_ERROR,
    payload,
  }),
  RedemptionAutomationClean: () => ({
    type: Types.REDEMPTION_AUTOMATION_CLEAN,
  }),
  approveRedemptionAutomation: payload => ({
    type: Types.APPROVE_REDEMPTION_AUTOMATION,
    payload,
  }),
  approveRedemptionAutomationSuccess: () => ({
    type: Types.APPROVE_REDEMPTION_AUTOMATION_SUCCESS,
  }),
  approveRedemptionAutomationError: payload => ({
    type: Types.APPROVE_REDEMPTION_AUTOMATION_ERROR,
    payload,
  }),
  approveRedemptionAutomationReset: () => ({
    type: Types.APPROVE_REDEMPTION_AUTOMATION_RESET,
  }),

  startRedemptionAutomation: payload => ({
    type: Types.START_REDEMPTION_AUTOMATION,
    payload,
  }),
  startRedemptionAutomationSuccess: () => ({
    type: Types.START_REDEMPTION_AUTOMATION_SUCCESS,
  }),
  startRedemptionAutomationError: payload => ({
    type: Types.START_REDEMPTION_AUTOMATION_ERROR,
    payload,
  }),
  startRedemptionAutomationReset: () => ({
    type: Types.START_REDEMPTION_AUTOMATION_RESET,
  }),
};

